import * as React from 'react';
import {
    Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row
} from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { LibrarySession } from 'src/models/AppSession';
import { Resource } from 'src/models/Resource';
import { Wire } from 'src/network/Wire';
import { Html } from 'src/ui/foundation/WebHelpers';
import { FormState } from 'src/utilities/FormState';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import { Validator } from 'src/utilities/Validator';

import ms_signin_light_en_ca from '../../../src/assets/img/ms_signin_light_en_ca.svg';
import ms_signin_light_fr_ca from '../../../src/assets/img/ms_signin_light_fr_ca.svg';
import { SSOHookMiddleWare } from '../../SSOHookMiddleWare';
import { Image } from '../foundation/Assets';
import { Icon, Loading, Switch } from '../foundation/Controls';
import { SSOSignInButton } from '../foundation/Controls/SSOSignInButton';
import * as Messages from '../foundation/Messages';
import { LibraryContext } from '../state/Contextes';

const InlineSVG: any = require("svg-inline-react").default;

interface ILoginViewProps {
  registrationLink?: string;
  forgotPasswordLink?: string;
}

interface ILoginViewState {
  allowSkip: boolean;
  skipIsTokened: boolean;
  form: FormState;
  loading: boolean;
}

export class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {
  context: LibrarySession;
  static contextType = LibraryContext;
  loginResource: Resource;
  constructor(props: any) {
    super(props);
    this.submit = this.submit.bind(this);
    this.ssoLogin = this.ssoLogin.bind(this);
    this.submitAnonymous = this.submitAnonymous.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.tokenSet = this.tokenSet.bind(this);
    let form = new FormState({
      username: "",
      password: "",
      rememberMe: true,
    });
    this.state = {
      form,
      loading: false,
      allowSkip: false,
      skipIsTokened: false,
    };
  }

  handleInput(event: any) {
    this.setState({
      form: this.state.form.change(event.target),
    });
  }

  loading(isLoading: boolean) {
    this.setState({
      loading: isLoading,
    });
  }
  async ssoLogin(username = "", password = "") {
    this.loading(true);
    let result = await this.context.ssoLogin(username, password, true, window.innerWidth, window.innerHeight, false);
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.LoginView.LABEL_LOGINSUCCESS);
    } else {
      Messages.Notify.error(result.errors[0].Message);
      this.loading(false);
    }
  }

  async submitAnonymous(event: any) {
    event.preventDefault();
    if (!this.state.allowSkip) {
      Messages.Notify.error("Offline is not enabled in this library.");
      return;
    }
    let form = this.state.form;
    this.loading(true);
    let result = await this.context.login("", "", true, window.innerWidth, window.innerHeight, true);
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.LoginView.LABEL_LOGINSUCCESS);
      form.values.password = "";
      this.setState({
        form: form,
      });
    } else {
      this.loading(false);
      Messages.Dialog.alert(`${result.errors[0].Message}`);
    }
  }

  async submit(event: any) {
    event.preventDefault();
    let form = this.state.form;
    if (form.formValid()) {
      this.loading(true);
      let result = await this.context.login(form.values.username, form.values.password, form.values.rememberMe, window.innerWidth, window.innerHeight, false);
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.LoginView.LABEL_LOGINSUCCESS);
        form.values.password = "";
        this.setState({
          form: form,
        });
      } else {
        this.loading(false);
        Messages.Dialog.alert(`${result.errors[0].Message}`);
      }
    } else {
      this.setState({
        form: form,
      });
    }
  }

  componentDidMount() {
    this.context.libraryLoading.stepLoading.on("login", () => this.loading(true));
    this.context.libraryLoading.stepLoaded.on("login", () => this.loading(false));
    let form = new FormState({
      username: "",
      password: "",
      rememberMe: true,
    });
    form.addValidator("username", Validator.notNullOrWhitespace, this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PROXY_USERNAME_PROMPT);
    form.addValidator("password", Validator.notNullOrWhitespace, this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PASSWORD_PROMPT);
    this.context.loginTokenSet.on(this.tokenSet);
    this.loginResource = new Resource(Wire.shield(this.context.wire), -1);
    this.tokenSet();
  }
  componentWillUnmount() {
    this.context.libraryLoading.stepLoaded.off("login");
    this.context.loginTokenSet.off(this.tokenSet);
  }

  private tokenSet() {
    let allowSkip =
      this.context.config.IsAnonymousEnabled &&
      (!this.context.config.AnonymousRequiresToken ||
        (!Convert.isEmptyOrSpaces(this.context.externalLinkingCurrentToken) && this.context.config.AnonymousRequiresToken));
    let skipIsTokened = !Convert.isEmptyOrSpaces(this.context.externalLinkingCurrentToken) && this.context.config.AnonymousRequiresToken;
    this.setState({ allowSkip: allowSkip, skipIsTokened: skipIsTokened });
  }

  render() {
    let form = this.state.form;
    let loginPlaceholder = this.context.config.InstanceInternationalizations[this.context.localization.currentCulture - 1].LoginCustomPlaceholder;
    if (!loginPlaceholder || loginPlaceholder.length === 0) {
      loginPlaceholder = this.context.localization.currentLocale.LoginView.LABEL_EMAIL;
    }
    let usernameType = "text" as any;
    if (this.context.config.IsLoginEmailBased) {
      usernameType = "email" as any;
    }

    let SSOButton = null;
    if (this.context.config.IsMicrosoftSsoEnabled === true && ResourcePlatform.SupportsSso()) {
      SSOButton =
        this.context.localization.currentCulture === Languages.French ? (
          <div onClick={() => this.ssoLogin()} className="ssoLoginButton">
            <InlineSVG className="ssoLoginButtonImage" src={ms_signin_light_fr_ca} />
          </div>
        ) : (
          <div onClick={() => this.ssoLogin()} className="ssoLoginButton">
            <InlineSVG className="ssoLoginButtonImage" src={ms_signin_light_en_ca} />
          </div>
        );
      if (ResourcePlatform.UsesWebSso()) {
        SSOButton =
          this.context.localization.currentCulture === Languages.French ? (
            <InlineSVG className="ssoLoginButtonImage" src={ms_signin_light_fr_ca} />
          ) : (
            <InlineSVG className="ssoLoginButtonImage" src={ms_signin_light_en_ca} />
          );
        SSOButton = (
          <div>
            <SSOSignInButton>{SSOButton}</SSOSignInButton>
            <SSOHookMiddleWare loginNeeded={this.ssoLogin} />
          </div>
        );
      }
    }
    return (
      <Loading isLoading={this.state.loading} className="loginview p-3">
        <img style={{ width: "100%", maxHeight: "300px" }} src={"data:image/png;base64," + this.context.config.LoginImage} />
        <Form className="p-2" onChange={this.handleInput} onSubmit={this.submit}>
          <FormGroup>
            <InputGroup>
                <InputGroupText>
                  <Icon src={<Image.card />} />
                </InputGroupText>
              <Input
                type={usernameType}
                name="username"
                value={form.values.username}
                invalid={form.invalid("username")}
                onChange={this.handleInput}
                placeholder={loginPlaceholder}
              />
              <FormFeedback tooltip={true}>{Html.unstyledList(form.getErrors("username"))}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
                <InputGroupText>
                  <Icon src={<Image.lock />} />
                </InputGroupText>
              <Input
                type="password"
                name="password"
                value={form.values.password}
                invalid={form.invalid("password")}
                onChange={this.handleInput}
                placeholder={this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PASSWORD}
              />
              <FormFeedback tooltip={true}>{Html.unstyledList(form.getErrors("password"))}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <Row form={form.values.rememberMe}>
            <Col xs={6}>
              {this.context.config.IsRememberMeAllowed && (
                <FormGroup>
                  <Label>{this.context.localization.currentLocale.LoginView.LABEL_LOGIN_REMEMBER_USER}</Label>
                  <Switch name="rememberMe" on={form.values.rememberMe} onChange={this.handleInput} className="ml-3" />
                </FormGroup>
              )}
            </Col>
            <Col xs={6}>
              <FormGroup className="text-right">
                {this.props.forgotPasswordLink && this.props.forgotPasswordLink.length > 0 && (
                  <Button
                    onClick={() => {
                      this.loginResource.openExternalWebsite(this.props.forgotPasswordLink!);
                    }}
                    color="link"
                  >
                    {this.context.localization.currentLocale.LoginView.LABEL_FORGOTPASSWORD}
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                    <Button color="primary" outline block>
                        {this.context.localization.currentLocale.LoginView.LABEL_LOGIN}
                    </Button>
                    {SSOButton}
          </FormGroup>
          <FormGroup className="flavourTextContainer">
            {this.context.config.InstanceInternationalizations[this.context.localization.currentCulture - 1].LoginFlavourText && (
              <span className="loginFlavourText">
                {this.context.config.InstanceInternationalizations[this.context.localization.currentCulture - 1].LoginFlavourText}
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <div className="text-center">
              {!this.context.config.HideRegistrationLink && this.props.registrationLink && this.props.registrationLink.length > 0 && (
                <Button
                  onClick={() => {
                    this.loginResource.openExternalWebsite(this.props.registrationLink!);
                  }}
                  color="link"
                >
                  {this.context.localization.currentLocale.LoginView.LABEL_LOGIN_SIGNUP}
                </Button>
              )}
              {!this.context.config.HideRegistrationLink && this.props.registrationLink && this.props.registrationLink.length > 0 && this.state.allowSkip && (
                <span>or</span>
              )}
              {this.state.allowSkip && !this.state.skipIsTokened && (
                <Button onClick={this.submitAnonymous} color="link">
                  {this.context.localization.currentLocale.LoginView.LABEL_LOGIN_SKIP_BUTTON}
                </Button>
              )}
              {this.state.allowSkip && this.state.skipIsTokened && (
                <Button onClick={this.submitAnonymous} color="link">
                  {this.context.localization.currentLocale.LoginView.LABEL_USE_LOGIN_TOKEN_ANONYMOUSLY}
                </Button>
              )}
            </div>
          </FormGroup>
        </Form>
      </Loading>
    );
  }
}
