import classnames from 'classnames';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import {
    Button, Card, CardBody, CardSubtitle, CardText, CardTitle, DropdownItem, DropdownMenu, Spinner,
    Table
} from 'reactstrap';
import { Log } from 'src/Logger';
import { FontSizes } from 'src/models/AppSession';
import { Book } from 'src/models/Book';
import {
    ContentNote, ContentPopupModel, ContentSegment, NavigationRequest
} from 'src/models/Content';
import { MigratingBook, Migration, MigrationConflict } from 'src/models/Migration';
import { Annotation, AnnotationType, Favourite } from 'src/models/UserContent';
import { FormState } from 'src/utilities/FormState';
import * as _ from 'underscore';

import { IEmptyValue } from '../state/Generics';
import { Image } from './Assets';
import { StandaloneCogniflowContainer } from './StandaloneCogniflow';

export interface IHtmlElementProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  title?: string;
}
export interface IIconProps extends IHtmlElementProps {
  src: React.ReactNode;
}
export class Icon extends React.Component<IIconProps, unknown> {
  render() {
    return (
      <span id={this.props.id} className={classnames("icon", this.props.className)} title={this.props.title} style={this.props.style}>
        {this.props.src}
      </span>
    );
  }
}

export interface IActionIconProps extends IIconProps {
  onClick: (event: any) => any;
  enabled?: boolean;
  active?: boolean;
}

export class ActionIcon extends React.Component<IActionIconProps, unknown> {
  static defaultProps = {
    enabled: true,
  };
  constructor(props: any) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(event: any) {
    if (this.isEnabled()) {
      this.props.onClick(event);
    }
  }

  isEnabled(): boolean {
    return this.props.enabled !== undefined ? this.props.enabled : true;
  }

  isActive(): boolean {
    return this.props.active !== undefined ? this.props.active : false;
  }

  render() {
    return (
      <a
        id={this.props.id}
        className={classnames("icon", "action-icon", this.props.className, {
          active: this.isActive(),
          disabled: !this.isEnabled(),
        })}
        onClick={this.clickHandler}
      >
        {this.props.src}
      </a>
    );
  }
}

export class CircleActionIcon extends React.Component<IActionIconProps, unknown> {
  render() {
    return (
      <Button className="btn-round" color="primary" outline onClick={this.props.onClick}>
        <Icon {...this.props} />
      </Button>
    );
  }
}

export class FabButton extends React.Component<IActionIconProps, unknown> {
  render() {
    return (
      <Button className="position-absolute btn-fab" color="primary" onClick={this.props.onClick}>
        <Icon className="w-100 h-100" {...this.props} />
      </Button>
    );
  }
}

interface IVerticalNavBarProps {
  level: string;
  className?: string;
}

export class VerticalNavBar extends React.Component<IVerticalNavBarProps, IEmptyValue> {
  render() {
    return (
      <div
        className={classnames(`vertical-navbar justify-content-between ${this.props.level}-navbar`, this.props.className)}
        style={
          {
            // backgroundColor: this.props.color,
          }
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export interface IItemCardProps extends IHtmlElementProps {
  children: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    description: React.ReactNode;
  };
  onClick: () => any;
  showMore: boolean | undefined;
  selected?: boolean;
  onShowMoreClick: () => any | undefined;
}
export class ItemCard extends React.Component<IItemCardProps> {
  render() {
    let desc: any = "";
    if (typeof this.props.children.description === "object") {
      desc = this.props.children.description;
    } else if (this.props.children.description) {
      desc = <CardText>{this.props.children.description}</CardText>;
    }

    let selected: any = "";
    if (this.props.selected === true) {
      selected = " selectedAnnotation";
    }

    return (
      <Card className={classnames("m-1 bg-light", this.props.className, selected)} style={this.props.style} id={this.props.id}>
        <CardBody className="p-0 d-flex">
          <div className="p-3 flex-fill clickable" tabIndex={0} onClick={this.props.onClick}>
            <CardTitle>{this.props.children.title ? this.props.children.title : "\u00A0"}</CardTitle>
            <CardSubtitle>{this.props.children.subtitle ? this.props.children.subtitle : ""}</CardSubtitle>
            {desc}
          </div>
          {this.props.showMore && (
            <div
              className="p-3 border-left d-flex align-items-center justify-content-middle flex-shrink-0 clickable"
              tabIndex={0}
              onClick={this.props.onShowMoreClick}
            >
              <Icon src={<Image.showmore />} />
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

export const FormValue = (props: any) => <div className="form-text-value">{props.children}</div>;

export interface ISortDropdownMenuProps extends IHtmlElementProps {
  initial: any;
  initialReversed?: boolean;
  onSortSelected: (value: any, reversed: boolean) => any;
}

export interface ISortDropdownMenuState {}

export class SortDropdownMenu extends React.Component<ISortDropdownMenuProps, ISortDropdownMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.onItemClicked = this.onItemClicked.bind(this);
  }

  onItemClicked(value: any, reversed: boolean) {
    if (this.props.initial === value) {
      reversed = !reversed;
    } else {
      reversed = false;
    }
    this.props.onSortSelected(value, reversed);

    this.setState({});
  }

  render() {
    return (
      <DropdownMenu id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {React.Children.map(this.props.children, (child: React.ReactElement<ISortDropdownItemProps>) => {
          let clone = React.cloneElement(child, {
            key: child.props.value,
            reversed: this.props.initialReversed ? this.props.initialReversed : false,
            onClick: this.onItemClicked,
            active: child.props.value === this.props.initial,
          });
          return clone;
        })}
      </DropdownMenu>
    );
  }
}

export interface ISortDropdownItemProps extends IHtmlElementProps {
  value: any;
  reversed?: boolean;
  onClick?: (value: any, reversed: boolean) => any;
  active?: boolean;
}

export class SortDropdownItem extends React.Component<ISortDropdownItemProps, unknown> {
  constructor(props: any) {
    super(props);
    this.onDropdownItemClick = this.onDropdownItemClick.bind(this);
  }

  onDropdownItemClick() {
    const reversed = this.props.reversed || false;
    if (this.props.onClick) {
      this.props.onClick(this.props.value, reversed);
    }
  }

  render() {
    const active = this.props.active || false;
    let icon: React.ReactNode = null;
    if (this.props.active) {
      if (this.props.reversed) {
        icon = <Image.sortdown />;
      } else {
        icon = <Image.sortup />;
      }
    }

    return (
      <DropdownItem active={active} onClick={this.onDropdownItemClick} id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        <div className="d-flex justify-content-between">
          <div>{this.props.children}</div>{" "}
          <div className="ml-2">
            <Icon src={icon} />
          </div>
        </div>
      </DropdownItem>
    );
  }
}

export interface ISelectDropdownMenuProps extends IHtmlElementProps {
  initial: any;
  onSelected: (value: any) => any;
}

export interface ISelectDropdownMenuState {
  current: any;
}

export class SelectDropdownMenu extends React.Component<ISelectDropdownMenuProps, ISelectDropdownMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: this.props.initial,
    };
    this.onItemClicked = this.onItemClicked.bind(this);
  }

  componentDidUpdate(prevProps: ISelectDropdownMenuProps) {
    if (prevProps.initial !== this.state.current) {
      this.setState({
        current: this.props.initial,
      });
    }
  }

  onItemClicked(value: any) {
    this.props.onSelected(value);

    this.setState({
      current: value,
    });
  }

  render() {
    return (
      <DropdownMenu id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {React.Children.map(this.props.children, (child: React.ReactElement<ISelectDropdownItemProps>) => {
          let clone = React.cloneElement(child, {
            key: child.props.value,
            onClick: this.onItemClicked,
            active: child.props.value === this.state.current,
          });
          return clone;
        })}
      </DropdownMenu>
    );
  }
}

export interface ISelectDropdownItemProps extends IHtmlElementProps {
  value: any;
  onClick?: (value: any) => any;
  active?: boolean;
}

export class SelectDropdownItem extends React.Component<ISelectDropdownItemProps, unknown> {
  constructor(props: any) {
    super(props);
    this.onDropdownItemClick = this.onDropdownItemClick.bind(this);
  }

  onDropdownItemClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }

  render() {
    const active = this.props.active || false;
    let icon: React.ReactNode = null;
    if (this.props.active) {
      icon = <Image.radio_on />;
    } else {
      icon = <Image.radio_off />;
    }
    return (
      <DropdownItem active={active} onClick={this.onDropdownItemClick} id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        <div className="d-flex justify-content-start">
          <div className="mr-2">
            <Icon src={icon} />
          </div>
          <div>{this.props.children}</div>{" "}
        </div>
      </DropdownItem>
    );
  }
}

export interface IExpanderProps {
  isOpen: boolean;
  [key: string]: any;
}
export class Expander extends React.Component<IExpanderProps, unknown> {
  render() {
    const openClass = this.props.isOpen ? "expander-open" : "";
    return (
      <div className="expander">
        <div className={`expander-collider ${openClass}`}>
          <div className={classnames("expander-body", this.props.className)} {..._.omit(this.props, "className", "isOpen")}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export interface IExpanderCloseProps {
  onClick: (e: React.MouseEvent) => any;
  isOpen: boolean;
}
export interface IExpanderCloseState {
  isOpen: boolean;
}
export class ExpanderClose extends React.Component<IExpanderCloseProps, IExpanderCloseState> {
  constructor(props: IExpanderCloseProps) {
    super(props);
    this.state = { isOpen: props.isOpen };
    this.onCLickExecutor = this.onCLickExecutor.bind(this);
  }

  onCLickExecutor(e: React.MouseEvent) {
    this.props.onClick(e);
    this.setState({ isOpen: !this.state.isOpen });
  }
  componentDidUpdate() {
    if (this.props.isOpen !== this.state.isOpen) {
      // Props override state
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  render() {
    return (
      <Button aria-label="Collapse" onClick={this.onCLickExecutor} className="p-3 expanderCollapse">
        <span aria-hidden>{this.state.isOpen ? "–" : "+"}</span>
      </Button>
    );
  }
}

interface IFilteringMenuProps extends IHtmlElementProps {
  onFilterChanged: (filter: { [key: string]: any }) => any;
}

interface IFilteringMenuState {
  form: FormState;
}

interface IFilteringItemProps extends IHtmlElementProps {
  value: string | number;
}

export class FilteringMenu extends React.Component<IFilteringMenuProps, IFilteringMenuState> {
  constructor(props: any) {
    super(props);
    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.state = { form: new FormState({}) };
  }

  componentDidUpdate() {
    const previous = _.clone(this.state.form.values);
    React.Children.map(this.props.children, (child: React.ReactElement<IFilteringItemProps>) => {
      let filterKey = child.props.value;
      this.state.form.update({
        [filterKey]: this.state.form.values[filterKey] === undefined ? true : this.state.form.values[filterKey],
      });
    });

    if (!_.isEqual(previous, this.state.form.values)) {
      this.setState({
        form: this.state.form,
      });
      this.props.onFilterChanged(this.state.form.values);
    }
  }

  onOptionChanged(input: HTMLInputElement) {
    if (input) {
      this.setState({
        form: this.state.form.change(input),
      });
      this.props.onFilterChanged(this.state.form.values);
    }
  }

  render() {
    let content = (
      <Table id={this.props.id} className={classnames("mb-0", this.props.className)} style={this.props.style}>
        <tbody>
          {React.Children.map(this.props.children, (child: React.ReactElement<IFilteringItemProps>) => (
            <FilteringItemWrapper
              checkState={this.state.form.values[child.props.value] === true ? CheckboxState.checked : CheckboxState.unchecked}
              element={child}
              onOptionChanged={this.onOptionChanged}
            />
          ))}
        </tbody>
      </Table>
    );
    return <div className="scrollingFilterContainer">{content}</div>;
  }
}
interface IFilteringItemWrapperProps {
  onOptionChanged: (event: HTMLInputElement) => void;
  element: JSX.Element;
  checkState: CheckboxState;
}
class FilteringItemWrapper extends React.Component<IFilteringItemWrapperProps, unknown> {
  rowRef = React.createRef<HTMLTableRowElement>();
  constructor(props: IFilteringItemWrapperProps) {
    super(props);
    this.innerOptionChanged = this.innerOptionChanged.bind(this);
    this.innerWrapperClick = this.innerWrapperClick.bind(this);
  }
  innerOptionChanged(ev: React.MouseEvent) {
    let input = this.rowRef.current!.querySelector("input");
    if (input) {
      ev.stopPropagation();
      this.props.onOptionChanged(input);
    }
  }
  innerWrapperClick(ev: React.MouseEvent) {
    let input = this.rowRef.current!.querySelector("input");
    if (input) {
      ev.stopPropagation();
      input.checked = !input.checked;
      this.props.onOptionChanged(input);
    }
  }
  render() {
    return (
      <tr ref={this.rowRef}>
        <td>
          <Checkbox name={this.props.element.props.value.toString()} state={this.props.checkState} onChange={this.innerOptionChanged} />
        </td>
        <td onClick={this.innerWrapperClick} style={{ width: "100%" }}>
          {this.props.element}
        </td>
      </tr>
    );
  }
}

export class FilteringItem extends React.Component<IFilteringItemProps, unknown> {
  render() {
    return (
      <div id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export enum CheckboxState {
  checked,
  unchecked,
  indeterminate,
}
export interface ICheckboxProps extends IHtmlElementProps {
  state: CheckboxState;
  onChange: (event: any) => any;
  name?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  labelBefore?: boolean;
}

export interface ICheckboxState {
  state: CheckboxState;
}

export class Checkbox extends React.Component<ICheckboxProps, ICheckboxState> {
  private inputRef = React.createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = { state: CheckboxState.unchecked };
  }

  componentDidMount() {
    this.setCheckboxState();
  }

  componentDidUpdate(prevProps: ICheckboxProps) {
    if (prevProps.state !== this.props.state) {
      this.setCheckboxState();
    }
  }

  private setCheckboxState() {
    this.setState({
      state: this.props.state,
    });
    if (this.inputRef.current) {
      this.inputRef.current.indeterminate = this.props.state === CheckboxState.indeterminate;
    }
  }

  render() {
    return (
      <label className={classnames("custom-checkbox", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.props.labelBefore && this.props.label !== undefined && <label>{this.props.label}</label>}
        <input
          ref={this.inputRef}
          type="checkbox"
          checked={this.state.state === CheckboxState.checked}
          onChange={this.props.onChange}
          name={this.props.name}
          disabled={this.props.disabled}
        />
        <span className="custom-checkbox-state" tabIndex={0} />
        {!this.props.labelBefore && this.props.label !== undefined && <label>{this.props.label}</label>}
      </label>
    );
  }
}

export interface ISwitchProps extends IHtmlElementProps {
  on: boolean;
  onChange: (event: any) => any;
  name?: string;
  disabled?: boolean;
}

export class Switch extends React.Component<ISwitchProps, unknown> {
  render() {
    return (
      <Checkbox
        onChange={this.props.onChange}
        name={this.props.name}
        disabled={this.props.disabled}
        className={classnames("switch", this.props.className)}
        style={this.props.style}
        id={this.props.id}
        state={this.props.on ? CheckboxState.checked : CheckboxState.unchecked}
      />
    );
  }
}

export interface ILoadingProps extends IHtmlElementProps {
  isLoading: boolean;
  status?: string | React.ReactNode;
  theme?: "opaque" | "translucent";
}
export class Loading extends React.Component<ILoadingProps, unknown> {
  render() {
    return (
      <div className={classnames("loading-modal-context", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.props.isLoading && (
          <div
            className={classnames("loading-modal", {
              "loading-modal-opaque": this.props.theme === "opaque",
            })}
          >
            <div className="loading-modal-content">
              {this.props.theme === "opaque" && (
                <Spinner
                  color="dark"
                  className="loading-modal-spinner"
                  style={{
                    opacity: 0.6,
                  }}                  
                />
              )}
              {this.props.theme !== "opaque" && <Spinner color="light" className="loading-modal-spinner" />}
              {this.props.status}
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

interface IStaticModalProps extends IHtmlElementProps {
  backdrop?: boolean;
  centered?: boolean;
  visible: boolean;
}
export class StaticModal extends React.Component<IStaticModalProps, unknown> {
  render() {
    let visibility = this.props.visible ? "" : " modalHide";

    return (
      <div className={"static-modal-container" + visibility}>
        <div className={classnames("modal", this.props.className)} tabIndex={-1} role="dialog" id={this.props.id} style={this.props.style}>
          <div
            className={classnames("modal-dialog", {
              "modal-dialog-centered": this.props.centered,
            })}
            role="document"
          >
            <div className="modal-content">{this.props.children}</div>
          </div>
        </div>
        {this.props.backdrop === true && <div className="modal-backdrop static-modal-backdrop" />}
      </div>
    );
  }
}

interface IDrawerContainerProps extends IHtmlElementProps {
  direction?: "left" | "top";
}

interface IDrawerContainerState {}
export class DrawerContainer extends React.Component<IDrawerContainerProps, IDrawerContainerState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div
        className={classnames("drawer-container", this.props.className, {
          "drawer-container-to-top": this.props.direction === "top",
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

interface IDrawerProps extends IHtmlElementProps {
  isOpen: boolean;
  backdrop?: boolean;
  onBackdropClicked?: () => any;
}

interface IDrawerState extends IHtmlElementProps {}

export class Drawer extends React.Component<IDrawerProps, IDrawerState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={classnames("drawer", this.props.className, {
            "drawer-show": this.props.isOpen,
          })}
        >
          <div className="drawer-content">{this.props.children}</div>
        </div>
        {!(this.props.backdrop === false) && <div className="drawer-backdrop" onClick={this.props.onBackdropClicked} />}
      </React.Fragment>
    );
  }
}

interface ISeparatorProps {
  className?: string;
}

export class Separator extends React.Component<ISeparatorProps, unknown> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <div className={classnames("w-100 my-2 separator", this.props.className)} />;
  }
}

interface IAnnotationTypeProps {
  color: string;
  name: string;
  tokenClassname?: string;
}

export class AnnotationTypeComponent extends React.PureComponent<IAnnotationTypeProps, unknown> {
  render() {
    let classes = "colour-token";
    if (this.props.tokenClassname) {
      classes += " " + this.props.tokenClassname;
    }
    return (
      <div className="annotation-type d-flex align-items-center">
        <div className={classes} style={{ backgroundColor: this.props.color }} />
        <span>{this.props.name}</span>
      </div>
    );
  }
}

export class PreviewHandler {
  context: Book | Migration;
  originalModel: Annotation | Favourite | undefined;
  cogniflow: StandaloneCogniflowContainer;
  contentDocument: Document;
  documentLoaded: boolean;
  migratingBook?: MigratingBook;
  apiServer: {
    triggerNavigation: () => void;
    triggerSegmentAdded: (segment: any) => void;
    launch: () => void;
    isInitialized: () => boolean;
  };
  api: any;
  initialPopup: ContentSegment | undefined;
  initialPopupParent: ContentSegment | undefined;
  constructor(
    cogniflow: StandaloneCogniflowContainer,
    content: Document,
    context: Book | Migration,
    originalModel: Annotation | Favourite | undefined,
    migratingBook?: MigratingBook
  ) {
    this.cogniflow = cogniflow;
    this.contentDocument = content;
    this.documentLoaded = false;
    this.originalModel = originalModel;
    this.migratingBook = migratingBook;
    this.showPopup = this.showPopup.bind(this);
    this.updateInstance = this.updateInstance.bind(this);
    this.registerEvents = this.registerEvents.bind(this);
    this.onLinkClicked = this.onLinkClicked.bind(this);
    this.processContentLink = this.processContentLink.bind(this);
    this.registerEvents();
    this.context = context;
  }
  updateInstance(
    cogniflow: StandaloneCogniflowContainer,
    content: Document,
    initialPopup: ContentSegment | undefined,
    initialPopupParent: ContentSegment | undefined,
    context: Book | Migration,
    newModel?: Annotation | Favourite | undefined,
    migratingBook?: MigratingBook
  ) {
    this.cogniflow = cogniflow;
    this.contentDocument = content;
    this.documentLoaded = false;
    this.registerEvents();
    this.context = context;
    this.initialPopup = initialPopup;
    this.initialPopupParent = initialPopupParent;
    this.migratingBook = migratingBook;
    if (newModel) {
      this.originalModel = newModel;
    }
  }
  public showPopup(popup: ContentSegment) {
    this.cogniflow.addAddonElement({ index: this.cogniflow.state.addonElements.length, popupSegment: popup } as ContentPopupModel);
  }
  public buildPopup(popup: ContentPopupModel): JSX.Element {
    let segment = this.cogniflow.generateSegment(popup.popupSegment);
    let popupCount = this.cogniflow.state.addonElements.length - 1;
    return (
      <ContentPopup
        isTop={popup.index === popupCount}
        popIndex={popupCount}
        key={popupCount}
        onClose={this.cogniflow.removeAddonElement}
        addonIndex={popupCount}
        segment={segment!}
        currentFontsize={this.getFontsizeClass()}
      />
    );
  }
  public adjustFontSize() {
    if (!this.context.hasOwnProperty("appSettings")) {
      return;
    }
    const { ReaderFontSize } = (this.context as Book).appSettings.get();
    let currentFontSizeClass = "";
    switch (ReaderFontSize) {
      case FontSizes.Smallest:
        currentFontSizeClass = "font-smallest";
        break;
      case FontSizes.Smaller:
        currentFontSizeClass = "font-smaller";
        break;
      case FontSizes.Normal:
        currentFontSizeClass = "font-medium";
        break;
      case FontSizes.Larger:
        currentFontSizeClass = "font-larger";
        break;
      case FontSizes.Largest:
        currentFontSizeClass = "font-largest";
        break;
    }

    const body = this.contentDocument.querySelector(".cogniflow-segments");
    if (body) {
      body.className = "";
      body.classList.add(...[currentFontSizeClass, "cogniflow-segments", "html", "body"]);
    }
  }
  private getFontsizeClass(): string {
    if (!this.context.hasOwnProperty("appSettings")) {
      return "font-medium";
    }
    switch ((this.context as Book).appSettings.get().ReaderFontSize) {
      case FontSizes.Smallest:
        return "font-smallest";
      case FontSizes.Smaller:
        return "font-smaller";
      case FontSizes.Normal:
        return "font-medium";
      case FontSizes.Larger:
        return "font-larger";
      case FontSizes.Largest:
        return "font-largest";
    }
    return "";
  }
  private registerEvents() {
    this.contentDocument.removeEventListener("click", this.onLinkClicked);
    this.contentDocument.addEventListener("click", this.onLinkClicked);
    this.apiServer = (this.contentDocument as any).ContentAPIServer;
    this.api = (this.contentDocument as any).ContentAPI;
    this.documentLoaded = true;
  }
  private onLinkClicked(e: MouseEvent) {
    if (!e.target) {
      return;
    }
    let target = (e.target as HTMLElement).closest("a[href]");
    if (target) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      let hrefValue: string = target.getAttribute("href")!.trim();
      if (hrefValue.indexOf("http") === 0 || hrefValue.indexOf("https") === 0) {
        if (this.migratingBook !== undefined) {
          (this.context as Migration).openExternalWebsite(hrefValue, this.migratingBook);
        } else {
          (this.context as Book).openExternalWebsite(hrefValue, hrefValue);
        }
      } else {
        this.processContentLink(hrefValue);
      }
    }
  }
  public async processContentLink(href: string) {
    let lastPart = href.substring(href.lastIndexOf("/") + 1);
    const splitter = lastPart.indexOf("?");
    if (splitter <= -1) {
      return;
    }
    let action = lastPart.substring(0, splitter);
    let param = lastPart.substring(splitter + 1);
    let headerId: number | undefined;
    switch (action) {
      // Allow popups within previews.
      case "POP":
      case "NAV":
        headerId = parseInt(param);
        if (_.isFinite(headerId)) {
          NavigationRequest.toHeader(headerId);
        }
        let highlight = false;
        if (this.initialPopup && this.initialPopup.HeadId === headerId) {
          highlight = true;
        }

        let result;
        if (this.migratingBook !== undefined) {
          result = await (this.context as Migration).fetchPopup({
            HeadId: headerId,
            MigratingBook: this.migratingBook,
            MigrationSet: (this.context as Migration).migrationSet,
          });
          if (!result.data) {
            return;
          }
        } else {
          result = await (this.context as Book).getContentSegments({ DocIds: [headerId], ShouldHighlight: highlight });
        }
        if (!Array.isArray(result.data) && result.data.SpineId === 0) {
          this.showPopup(result.data);
        } else if (Array.isArray(result.data) && result.data[0].SpineId === 0) {
          this.showPopup(result.data[0]);
        }
        break;
      // Allow resources within previews.
      case "RES":
        if (this.migratingBook !== undefined) {
          (this.context as Migration).requestResource(param, this.migratingBook);
        } else {
          (this.context as Book).requestResource(param);
        }
        break;
    }
  }
  public scrollToPreview() {
    let anchor = this.contentDocument.querySelectorAll("div[data-containing='OneNOT']")[0] as HTMLElement;
    if ((this.originalModel as Favourite).DocumentId) {
      anchor = this.contentDocument.querySelectorAll("div[data-containing='FavNOT']")[0] as HTMLElement;
    }

    let possibleHighlight = this.contentDocument.querySelectorAll("span[data-anno]")[0] as HTMLElement;
    if ((possibleHighlight && anchor && possibleHighlight.offsetTop < anchor.offsetTop) || (!anchor && possibleHighlight)) {
      anchor = possibleHighlight;
    }

    if (anchor) {
      this.cogniflow.scrollingRef.current!.scrollTop = anchor.getBoundingClientRect().top;
    }
    if (this.initialPopup) {
      if (this.initialPopupParent) {
        let newAnchor = this.contentDocument.querySelectorAll("div[data-cogniflow--spine='" + this.initialPopupParent.SpineId + "']")[0];
        if (newAnchor) {
          newAnchor.scrollIntoView();
        }
      }
      this.showPopup(this.initialPopup);
    }
  }
  public scrollToConflict(conflict: MigrationConflict, isAnnotation: boolean) {
    if (this.initialPopup) {
      let anchor;
      if (this.migratingBook === MigratingBook.New) {
        anchor = this.cogniflow.getSegmentByMain((this.cogniflow.getNodeBySecondary(conflict.ToAnchor) as ContentSegment).SpineId)!;
      } else {
        anchor = this.cogniflow.getSegmentByMain((this.cogniflow.getNodeBySecondary(conflict.FromAnchor) as ContentSegment).SpineId)!;
      }
      if (anchor) {
        anchor.scrollIntoView(true);
      }
      anchor = this.contentDocument.querySelectorAll("div[data-containing='FavNOT']")[0];
      if (anchor) {
        anchor.scrollIntoView(true);
      }
      this.showPopup(this.initialPopup);
    } else if (isAnnotation) {
      let noteBlock = this.contentDocument.querySelectorAll("div[data-containing='OneNOT']")[0];
      let highlight = this.contentDocument.querySelectorAll("span[data-anno]")[0];
      if (noteBlock) {
        noteBlock.scrollIntoView(true);
      } else if (highlight) {
        highlight.scrollIntoView(true);
      }
      let view = this.contentDocument.querySelectorAll(".BookView")[0];
      view.scrollTop = view.scrollTop - 30;
    } else {
      let someSpan = this.contentDocument.querySelectorAll("div[data-containing='FavNOT']")[0];
      if (someSpan) {
        someSpan.scrollIntoView(true);
        let view = this.contentDocument.querySelectorAll(".BookView")[0];
        view.scrollTop = view.scrollTop - 30;
      }
    }
  }
}

interface IConcreteContentSegmentProps {
  item: ContentSegment;
  originalModel?: Annotation | Favourite;
  classes: string;
  head: number;
  headAttr: string;
  spine: number;
  spineAttr: string;
  segmentAdded: (segment: HTMLElement) => void;
  highlights?: Annotation[];
  relatedTypes?: AnnotationType[];
  commentCount?: number;
}
interface IConcreteContentSegmentState {
  noteIcons: JSX.Element[];
  rawIcons: string;
  faveCount: number;
  annoCount: number;
  rawComment: string;
}
/**
 * The content segment component. **WARNING: THIS COMPONENT IS NOT TO BE RENDERED OUTSIDE AN IFRAME. IT USES UNSAFE INNERHTML FUNCTIONS TO SET CONTENT.**
 */
export class ConcreteContentSegment extends React.PureComponent<IConcreteContentSegmentProps, IConcreteContentSegmentState> {
  private segRef = React.createRef<HTMLDivElement>();
  constructor(props: IConcreteContentSegmentProps) {
    super(props);
    this.state = { noteIcons: [], annoCount: props.item.Annotations.length, faveCount: props.item.Favourites.length, rawIcons: "", rawComment: "" };
  }
  componentDidUpdate() {
    if (this.segRef && this.segRef.current !== null) {
      this.applyNotes(this.segRef.current, this.props.item);
      this.applyComments();
    }
  }
  componentDidMount() {
    if (this.segRef && this.segRef.current !== null) {
      this.applyNotes(this.segRef.current, this.props.item);
      this.props.segmentAdded(this.segRef.current);
      this.applyComments();
    }
  }
  applyComments() {
    let stringRep = "";
    if (this.props.commentCount && this.props.commentCount > 0) {
      stringRep = renderToString(
        <div className="comments-icon">
          <Image.comments />
        </div>
      );
    }
    if (this.state.rawComment !== stringRep) {
      this.setState({ rawComment: stringRep });
    }
  }
  private calculateOffsetRatio(segmentHeight: number, yHeight: number, yOffset: number) {
    if (yHeight !== 0) {
      return Math.floor((yOffset / (yHeight === 0 ? 1 : yHeight)) * segmentHeight);
    } else {
      return 0;
    }
  }
  private findAnnotationAnchor(parent: HTMLDivElement, id: number): HTMLElement | undefined {
    let annotations = parent.querySelectorAll("span[data-anno]")!;
    for (let i = 0; i < annotations.length; i++) {
      let ids = annotations[i].getAttribute("data-anno")!.split(",");
      if (ids.indexOf(id.toString()) !== -1) {
        return annotations[i] as HTMLElement;
      }
    }
    return undefined;
  }
  private applyNotes(segment: HTMLDivElement, node: ContentSegment) {
    let finals = [];
    let finalStrings = "";
    const iconHeight = 30;
    try {
      const contentHeight = segment.getBoundingClientRect().height || 0;
      const numberOfIcons = Math.max(1, Math.floor(contentHeight / iconHeight));

      const centeringGap = Math.floor((contentHeight - numberOfIcons * iconHeight) / 2);
      let notes = new Array<INote>();

      for (let element = 0; element < numberOfIcons; element++) {
        let current = {
          isHit: false,
          favourites: new Array<number>(),
          annotations: new Array<number>(),
          icon: null,
        };
        notes.push(current);
      }

      if (!this.props.originalModel || (this.props.originalModel as Favourite).hasOwnProperty("DocumentId")) {
        for (let fav of node.Favourites) {
          if (!this.props.originalModel || (this.props.originalModel as Favourite).Id === fav.Id) {
            let intermdiary = this.calculateOffsetRatio(contentHeight, fav.YDocHeight, fav.YOffset);
            let position = Math.round((intermdiary - centeringGap) / iconHeight);
            position = Math.min(position, notes.length - 1);
            position = Math.max(position, 0);
            notes[position].favourites.push(fav.Id);
          }
        }
      }
      let annotationLoop = this.props.highlights;
      if (!annotationLoop && this.props.originalModel && (this.props.originalModel as Annotation).hasOwnProperty("AnnotationTypeId")) {
        let annotations = this.props.item.Annotations.filter(
          (x) => x.Id === this.props.originalModel!.Id && this.props.item.SpineId === (this.props.originalModel as Annotation)!.Ranges[0].Spine
        );
        // Case for previews
        if (annotations.length > 0 && (this.props.originalModel as Annotation).Id === annotations[0].Id) {
          this.generateNoteIcon(segment, annotations, centeringGap, iconHeight, notes);
        }
      } else if (annotationLoop && !this.props.originalModel) {
        // Case for contentview
        for (let annotation of annotationLoop) {
          let annotations = this.props.item.Annotations.filter((x) => x.Id === annotation.Id);
          if (this.props.item.SpineId === annotation.Ranges[0].Spine && annotations && annotations.length > 0) {
            this.generateNoteIcon(segment, annotations, centeringGap, iconHeight, notes);
          }
        }
      }

      let count = 0;
      for (let currentNote of notes) {
        let attr = { "data-containing": "NONE" };
        let atribValue = currentNote.favourites.length === 1 ? "Fav" : "";
        atribValue = currentNote.favourites.length > 1 ? "ManyFav" : atribValue;
        atribValue += currentNote.annotations.length === 1 ? "One" : "";
        atribValue += currentNote.annotations.length > 1 ? "Many" : "";
        atribValue += currentNote.isHit ? "HIT" : "NOT";

        if (atribValue === "NOT") {
          atribValue = "NONE";
        }

        if (currentNote.favourites.length) {
          attr["data-fav"] = currentNote.favourites.join(",");
        }
        if (currentNote.annotations.length) {
          attr["data-anno"] = currentNote.annotations.join(",");
        }
        attr["data-containing"] = atribValue;
        let style = {} as React.CSSProperties;

        let top = centeringGap + iconHeight * count;
        if (top < 0) {
          top = 0;
        }

        style.position = "absolute";
        style.left = "-" + (iconHeight + 5) + "px";
        style.width = iconHeight + "px";
        style.height = iconHeight + "px";
        style.top = top + "px";
        style.padding = "2px";
        let img;
        switch (atribValue) {
          case "OneNOT":
            img = <Image.notesingleoff />;
            break;
          case "ManyNOT":
            img = <Image.notemultioff />;
            break;
          case "OneHIT":
            img = <Image.notesingleon />;
            break;
          case "ManyHIT":
            img = <Image.notemultion />;
            break;
          case "FavOneNOT":
          case "ManyFavOneNOT":
            img = <Image.favsingleoff />;
            break;
          case "FavManyNOT":
          case "ManyFavManyNOT":
            img = <Image.favmultioff />;
            break;
          case "FavOneHIT":
          case "ManyFavOneHIT":
            img = <Image.favsingleon />;
            break;
          case "FavManyHIT":
          case "ManyFavManyHIT":
            img = <Image.favmultion />;
            break;
          case "FavNOT":
            img = <Image.fav />;
            break;
          case "ManyFavHIT": // Multiple overlapping faves have the same icon as the single on atm...
            img = <Image.fav />;
            break;
          case "ManyFavNOT": // Multiple overlapping faves have the same icon as the single on atm...
            img = <Image.fav />;
            break;
        }
        let stringRep = "";
        if (img) {
          style.cursor = "pointer";
          stringRep = renderToString(
            <div className="mark-icon" style={style} {...attr} key={count}>
              {img}
            </div>
          );
        }

        finalStrings += stringRep;
        finals.push(<div className="mark-icon" style={style} {...attr} key={count} />);
        count++;
      }
    } catch (e) {
      Log.error("Failed note application", e);
    }
    try {
      // Handle overlap highlighting
      let overlaps = Array.from(this.segRef.current!.querySelectorAll("span.ANN_OVERLAP"));
      if (overlaps.length > 0 && this.props.highlights && this.props.relatedTypes) {
        let sortedAnnos = this.props.highlights.sort((x1, x2) => {
          if (x1.LastUpdate > x2.LastUpdate) {
            return -1;
          }
          if (x1.LastUpdate < x2.LastUpdate) {
            return 1;
          }
          return 0;
        });
        overlaps.forEach((overlap) => {
          try {
            let redefOverlap = overlap as HTMLElement;
            let annos = redefOverlap.dataset["anno"]!.split(",").map(Number);
            let presentAnnos = sortedAnnos.filter((y) => annos.indexOf(y.Id) > -1);
            if (annos.length === 2) {
              redefOverlap.style.setProperty(
                "background",
                `linear-gradient(180deg, ${this.findRelatedTypeColor(presentAnnos[1].AnnotationTypeId)} 20%, ${this.findRelatedTypeColor(
                  presentAnnos[0].AnnotationTypeId
                )} 30%)`,
                "important"
              );
            } else {
              redefOverlap.style.setProperty(
                "background",
                `linear-gradient(180deg, ${this.findRelatedTypeColor(presentAnnos[2].AnnotationTypeId)} 15%, ${this.findRelatedTypeColor(
                  presentAnnos[1].AnnotationTypeId
                )} 40%, ${this.findRelatedTypeColor(presentAnnos[0].AnnotationTypeId)} 50%)`,
                "important"
              );
            }
            if (presentAnnos.length <= 3) {
              redefOverlap.style.borderTop = "";
              redefOverlap.style.lineHeight = "1.5";
            } else {
              redefOverlap.style.borderTop = "black double 3px";
              redefOverlap.style.lineHeight = "1.7";
              redefOverlap.title = presentAnnos.length - 3 + " more items.";
            }
            redefOverlap.style.backgroundColor = "";
          } catch (e) {
            Log.error("Failed annotation overlap inner", e);
          }
        });
      } else if (overlaps.length > 0) {
        overlaps.forEach((overlap) => {
          let redefOverlap = overlap as HTMLElement;
          redefOverlap.style.borderTop = "black double 3px";
          redefOverlap.style.lineHeight = "1.7";
        });
      }
    } catch (e) {
      Log.error("Failed annotation overlap", e);
    }

    // Only set state when the icon info has changed. Otherwise we could end up in a rerendering loop.
    if (this.state.rawIcons !== finalStrings || this.state.annoCount !== node.Annotations.length || this.state.faveCount !== node.Favourites.length) {
      this.setState({ noteIcons: finals, rawIcons: finalStrings, annoCount: node.Annotations.length, faveCount: node.Favourites.length });
    }
  }
  private generateNoteIcon(segment: HTMLDivElement, annotations: ContentNote[], centeringGap: number, iconHeight: number, notes: INote[]) {
    try {
      let anchorRange: HTMLElement | undefined = this.findAnnotationAnchor(segment, annotations[0].Id);
      if (anchorRange !== undefined) {
        let currentHeight = 0;
        let yOffset = anchorRange.getBoundingClientRect().top + currentHeight - segment.getBoundingClientRect().top;
        let position = Math.round((yOffset - centeringGap) / iconHeight);
        position = Math.min(position, notes.length - 1);
        position = Math.max(position, 0);
        notes[position].isHit = notes[position].isHit || annotations[0].IsHit;
        notes[position].annotations.push(annotations[0].Id);
      }
    } catch (e) {
      Log.error("Failed to generate note icon", e);
    }
  }

  private findRelatedTypeColor(annoId: number): string {
    if (!this.props.relatedTypes) {
      return "#000000";
    }
    for (let i = 0; i < this.props.relatedTypes.length; i += 1) {
      if (this.props.relatedTypes[i].Id === annoId) {
        return this.props.relatedTypes[i].Color;
      }
    }
    return "#000000";
  }
  render() {
    let attrs = {};
    attrs[this.props.headAttr] = this.props.head;
    attrs[this.props.spineAttr] = this.props.spine;
    return (
      <div
        {...attrs}
        className={this.props.classes}
        ref={this.segRef}
        dangerouslySetInnerHTML={{ __html: this.props.item.Content + this.state.rawIcons + this.state.rawComment }}
        key="source"
      />
    );
  }
}
interface INote {
  isHit: boolean;
  favourites: number[];
  annotations: number[];
  icon: JSX.Element | null;
}
interface IContentPopupProps {
  segment: JSX.Element;
  popIndex: number;
  addonIndex: number;
  isTop: boolean;
  currentFontsize: string;
  onClose: (index: number) => void;
}
interface IContentPopupState {}
export class ContentPopup extends React.PureComponent<IContentPopupProps, IContentPopupState> {
  contentClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }
  render() {
    if (!this.props.isTop) {
      return "";
    }
    return (
      <div
        className="api-modal-wrapper api-modal-backdrop"
        style={{ zIndex: 8 + this.props.popIndex }}
        onClick={() => this.props.onClose(this.props.addonIndex)}
      >
        <div className={"ContentPopup " + this.props.currentFontsize}>
          <div style={{ zIndex: 8 + this.props.popIndex + 1 }} onClick={this.contentClick} className="api-modal">
            <div style={{ zIndex: 8 + this.props.popIndex + 2 }} className="api-modal-content">
              {this.props.segment}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
