import classnames from 'classnames';
import * as React from 'react';
import { ITable } from 'src/collections/Observable';
import { ExtendedHeader } from 'src/models/Content';
import { Books, IFrameUtils } from 'src/utilities/Helpers';

export interface IScrubberProps {
  sortedValues: number[];
  initialValue: number;
  iframeBody: HTMLElement;
  currentTocResults?: ITable<ExtendedHeader>;
  onChange?: (previousValue: number, value: number) => void;
  onScroll?: (previousValue: number, value: number) => void;
  onHandlePressed?: (value: number) => void;
}

interface IScrubberState {
  isListeningOnEvents: boolean;
  handlePosY: number; // In pixels starting from the top
  isHandlePressed: boolean;
}

export class Scrubber extends React.Component<IScrubberProps, IScrubberState> {
  private lastMousePositionY = 0;
  private pressedInitialScrollValue = 0;
  private scrollValue = 0;
  private pressedYDiffFromPosY = 0;
  private handleHeight = 30;
  private hasSetHandleToInitialValue = false;

  private scrubberRef = React.createRef<HTMLDivElement>();

  constructor(props: IScrubberProps) {
    super(props);

    this.listenOnEvents = this.listenOnEvents.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onHandlePressed = this.onHandlePressed.bind(this);
    this.onBarPressed = this.onBarPressed.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.updateHandlePosition = this.updateHandlePosition.bind(this);
    this.getScrollValueFromPos = this.getScrollValueFromPos.bind(this);
    this.getTop = this.getTop.bind(this);
    this.getBottom = this.getBottom.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.getScrollableHeight = this.getScrollableHeight.bind(this);
    this.scrollToSpine = this.scrollToSpine.bind(this);

    this.state = {
      isListeningOnEvents: false,
      handlePosY: 0,
      isHandlePressed: false,
    };
  }

  componentDidMount() {
    if (this.props.initialValue && this.props.sortedValues.length > 0) {
      this.scrollToSpine(this.props.initialValue);
      this.hasSetHandleToInitialValue = true;
    }
  }

  componentDidUpdate() {
    if (!this.state.isListeningOnEvents && this.props.iframeBody) {
      this.listenOnEvents();
      this.setState({ isListeningOnEvents: true });
    }

    if (!this.hasSetHandleToInitialValue && this.props.initialValue && this.props.sortedValues.length > 0) {
      this.scrollToSpine(this.props.initialValue);
      this.hasSetHandleToInitialValue = true;
    }
  }

  componentWillUnmount() {
    IFrameUtils.removeGlobalEventListener("mousemove", this.props.iframeBody, this.onMouseMove);
    IFrameUtils.removeGlobalEventListener("touchmove", this.props.iframeBody, this.onTouchMove);
    IFrameUtils.removeGlobalEventListener("mouseup", this.props.iframeBody, this.onMouseUp);
    IFrameUtils.removeGlobalEventListener("touchend", this.props.iframeBody, this.onMouseUp);
  }

  private listenOnEvents() {
    IFrameUtils.addGlobalEventListener("mousemove", this.props.iframeBody, this.onMouseMove);
    IFrameUtils.addGlobalEventListener("touchmove", this.props.iframeBody, this.onTouchMove);
    IFrameUtils.addGlobalEventListener("mouseup", this.props.iframeBody, this.onMouseUp);
    IFrameUtils.addGlobalEventListener("touchend", this.props.iframeBody, this.onMouseUp);
  }

  private onMouseMove(event: MouseEvent) {
    this.lastMousePositionY = event.clientY - this.getTop();

    if (this.state.isHandlePressed) {
      this.updateHandlePosition();
    }
  }

  private onTouchMove(event: TouchEvent) {
    this.lastMousePositionY = event.touches.item(0)!.clientY - this.getTop();

    if (this.state.isHandlePressed) {
      this.updateHandlePosition();
    }
  }

  private onHandlePressed(posY: number) {
    this.setState({ isHandlePressed: true });
    this.pressedInitialScrollValue = this.getScrollValueFromPos(this.state.handlePosY);
    const currentPressedPosition = posY - this.getTop();
    // Maintaining the space between the cursor and the handle.
    this.pressedYDiffFromPosY = currentPressedPosition - this.state.handlePosY;

    if (this.props.onHandlePressed) {
      this.props.onHandlePressed(this.scrollValue);
    }
  }

  private onBarPressed() {
    // Using the same movement handling than when the handle is pressed
    // to move the handle.
    this.setState({ isHandlePressed: true });
    this.pressedInitialScrollValue = this.getScrollValueFromPos(this.state.handlePosY);
    // Centering the bar on the cursor.
    this.pressedYDiffFromPosY = this.handleHeight / 2;
    this.updateHandlePosition();
  }

  private onMouseUp() {
    if (this.state.isHandlePressed) {
      this.setState({ isHandlePressed: false });

      if (this.props.onChange && this.pressedInitialScrollValue !== this.scrollValue) {
        this.props.onChange(this.pressedInitialScrollValue, this.scrollValue);
      }
    }
  }

  /**
   * Changes the position of the scrubber handle.
   */
  private updateHandlePosition() {
    let newHandlePosition = Math.round(this.lastMousePositionY - this.pressedYDiffFromPosY);

    if (newHandlePosition < 0) {
      newHandlePosition = 0;
    } else if (newHandlePosition + this.handleHeight > this.getHeight()) {
      newHandlePosition = this.getHeight() - this.handleHeight;
    }

    const scrollValue = this.getScrollValueFromPos(newHandlePosition);
    // Don't dispatch a change event if the value remains the same.
    if (this.props.onScroll && this.scrollValue !== scrollValue) {
      this.setState({ handlePosY: newHandlePosition });
      this.scrollValue = scrollValue;
      this.props.onScroll(this.pressedInitialScrollValue, scrollValue);
    }
  }

  private getScrollValueFromPos(posY: number): number {
    const { sortedValues } = this.props;

    if (sortedValues.length === 0) {
      return 0;
    }

    const min = sortedValues[0];
    const max = sortedValues[sortedValues.length - 1];
    const scaleHeight = max - min;
    const value = Math.round(min + (posY * scaleHeight) / this.getScrollableHeight());

    return Books.findCorrespondingHeader(sortedValues, value);
  }

  private getTop() {
    if (!this.scrubberRef.current) {
      return 0;
    }

    return this.scrubberRef.current.getBoundingClientRect().top;
  }

  private getBottom() {
    if (!this.scrubberRef.current) {
      return 0;
    }

    return this.scrubberRef.current.getBoundingClientRect().bottom;
  }

  private getHeight() {
    if (!this.scrubberRef.current) {
      return 0;
    }

    return this.scrubberRef.current.getBoundingClientRect().height;
  }

  private getScrollableHeight() {
    return this.getHeight() - this.handleHeight;
  }

  /**
   * Moves the handle to the given spine, without triggering any navigation events.
   *
   * @param spine The spine id to navigate to.
   */
  scrollToSpine(spine: number) {
    const { sortedValues } = this.props;

    if (sortedValues.length === 0) {
      return;
    }

    /* The given spine can be a content segment spine instead of an header spine. */
    const headerSpine = Books.findCorrespondingHeader(sortedValues, spine);

    const min = sortedValues[0];
    const max = sortedValues[sortedValues.length - 1];
    const scaleHeight = max - min;

    const newPosY = Math.round(((headerSpine - min) * this.getScrollableHeight()) / scaleHeight);

    this.scrollValue = headerSpine;
    this.setState({ handlePosY: newPosY });
  }

  render() {
    const searchResultsTopOffset = this.handleHeight / 2 - 1; // -1 to center the results with the handle.

    return (
      <div className="scrubber" ref={this.scrubberRef} onMouseDown={this.onBarPressed} onTouchStart={this.onBarPressed}>
        <div className="relativeInnerblock">
          {this.props.currentTocResults && (
            <ScrubberSearchResults
              scrollableHeight={this.getScrollableHeight()}
              sortedValues={this.props.sortedValues}
              searchResultsRows={this.props.currentTocResults.rows()}
              topOffset={searchResultsTopOffset}
            />
          )}
          <ScrubberHandle height={this.handleHeight} posY={this.state.handlePosY} isActive={this.state.isHandlePressed} onPressed={this.onHandlePressed} />
        </div>
      </div>
    );
  }
}
interface IScrubberHandleProps {
  height: number; // In pixels.
  posY: number; // In pixels, starting from the top
  isActive: boolean;
  onPressed: (clientY: number) => void; // Uses the clientY to always send same mesure even on iframes.
}

interface IScrubberHandleState { }

class ScrubberHandle extends React.Component<IScrubberHandleProps, IScrubberHandleState> {
  static defaultProps = {
    height: 30
  };

  constructor(props: IScrubberHandleProps) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onPressed = this.onPressed.bind(this);
  }

  private onMouseDown(event: React.MouseEvent) {
    event.stopPropagation();
    this.props.onPressed(event.clientY);
  }

  private onTouchStart(event: React.TouchEvent) {
    event.stopPropagation();
    this.props.onPressed(event.touches.item(0)!.clientY);
  }

  private onPressed(clientY: number) {
    this.props.onPressed(clientY);
  }

  render() {
    const { isActive, height, posY } = this.props;
    const activeClass = isActive ? "active" : "";
    return (
      <div
        className={classnames("scrubber-handle d-flex flex-column justify-content-center", activeClass)}
        style={{ height: height, top: posY }}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}

      >
        <div className="scrubber-handle-tick w-100" />
      </div>
    );
  }
}

interface IScrubberSearchResultsProps {
  scrollableHeight: number;
  sortedValues: number[];
  topOffset: number;
  searchResultsRows: ExtendedHeader[];
}

interface IScrubberSearchResultsState { }

/**
 * This class extends React.PureComponent instead of React.Component to prevent useless rerendering
 * of hundreds of search results when a big search query is active and the user scrolls the scrubber handler.
 * Instead of rerendering anytime the parent component rerenders, a PureComponent checks if his own props and state
 * has changed (using shallow comparison).
 */
class ScrubberSearchResults extends React.PureComponent<IScrubberSearchResultsProps, IScrubberSearchResultsState> {
  /**
   * A set used to flag all pixels (Y axis) that are already marked as hit.
   * This is to optimize the case where the user makes a search like "the", where
   * 11 000 hits can occur in a range of ~400 pixels.
   */
  private alreadyMarkedHits = new Set<number>();

  constructor(props: IScrubberSearchResultsProps) {
    super(props);
    this.renderSearchHit = this.renderSearchHit.bind(this);
    this.state = { searchResultRows: [] };
  }

  private renderSearchHit(searchHit: ExtendedHeader, min: number, scaleHeight: number) {
    // Don't show headers that are not directly hit (ie. don't mark all parent nodes as hit).
    if (!searchHit.IsHit) {
      return null;
    }

    const posY = Math.round(((searchHit.SpineId - min) * this.props.scrollableHeight) / scaleHeight);

    if (this.alreadyMarkedHits.has(posY)) {
      // Don't show the hit div if there is already another one at the same position.
      return null;
    } else {
      this.alreadyMarkedHits.add(posY);
      return <div key={searchHit.Id} className="w-50 bg-red-900 scrubber-search-hit" style={{ top: posY }} />;
    }
  }

  render() {
    const { sortedValues } = this.props;

    if (!sortedValues || sortedValues.length === 0 || this.props.searchResultsRows.length === 0) {
      return null;
    }

    this.alreadyMarkedHits.clear();
    const min = sortedValues[0];
    const max = sortedValues[sortedValues.length - 1];
    const scaleHeight = max - min;

    return (
      <div className="w-100 h-100 d-flex flex-column align-items-center position-relative" style={{ top: this.props.topOffset }}>
        {this.props.searchResultsRows.map(searchHit => this.renderSearchHit(searchHit, min, scaleHeight))}
      </div>
    );
  }
}
