import * as React from 'react';
const InlineSVG:any = require("svg-inline-react").default;

import annotations from 'src/assets/img/icons/annotations.svg';
import annotationTypes from 'src/assets/img/icons/annotationTypes.svg';
import arrowback from 'src/assets/img/icons/arrow-back.svg';
import arrownext from 'src/assets/img/icons/arrow-next.svg';
import arrowprev from 'src/assets/img/icons/arrow-prev.svg';
import attachment from 'src/assets/img/icons/attachment.svg';
import book from 'src/assets/img/icons/book.svg';
import bookmark from 'src/assets/img/icons/bookmark.svg';
import bulletin from 'src/assets/img/icons/bulletin.svg';
import cancel_search from 'src/assets/img/icons/cancel_search.svg';
import card from 'src/assets/img/icons/card.svg';
import cart from 'src/assets/img/icons/cart.svg';
import close from 'src/assets/img/icons/close.svg';
import comments from 'src/assets/img/icons/comments.svg';
import community from 'src/assets/img/icons/community.svg';
import copy from 'src/assets/img/icons/copy.svg';
import deleteResolved from 'src/assets/img/icons/deleteResolved.svg';
import delete_item from 'src/assets/img/icons/delete_item.svg';
import download from 'src/assets/img/icons/download.svg';
import empty from 'src/assets/img/icons/empty.svg';
import favmultioff from 'src/assets/img/icons/fav-multi-off.svg';
import favmultion from 'src/assets/img/icons/fav-multi-on.svg';
import favsingleoff from 'src/assets/img/icons/fav-single-off.svg';
import favsingleon from 'src/assets/img/icons/fav-single-on.svg';
import fav from 'src/assets/img/icons/fav.svg';
import favourite from 'src/assets/img/icons/favourite.svg';
import feedback from 'src/assets/img/icons/feedback.svg';
import filteroff from 'src/assets/img/icons/filter-off.svg';
import filteron from 'src/assets/img/icons/filter-on.svg';
import filter from 'src/assets/img/icons/filter.svg';
import folder from 'src/assets/img/icons/folder.svg';
import hamburger from 'src/assets/img/icons/hamburger.svg';
import help from 'src/assets/img/icons/help.svg';
import history from 'src/assets/img/icons/history.svg';
import hitnext from 'src/assets/img/icons/hit-next.svg';
import hitprev from 'src/assets/img/icons/hit-prev.svg';
import hit_next from 'src/assets/img/icons/hit_next.svg';
import hit_previous from 'src/assets/img/icons/hit_previous.svg';
import info from 'src/assets/img/icons/info.svg';
import link from 'src/assets/img/icons/link.svg';
import lock from 'src/assets/img/icons/lock.svg';
import media from 'src/assets/img/icons/media.svg';
import menu from 'src/assets/img/icons/menu.svg';
import next from 'src/assets/img/icons/next.svg';
import notemultioff from 'src/assets/img/icons/note-multi-off.svg';
import notemultion from 'src/assets/img/icons/note-multi-on.svg';
import noteon from 'src/assets/img/icons/note-on.svg';
import notesingleoff from 'src/assets/img/icons/note-single-off.svg';
import notesingleon from 'src/assets/img/icons/note-single-on.svg';
import note from 'src/assets/img/icons/note.svg';
import off from 'src/assets/img/icons/off.svg';
import pause from 'src/assets/img/icons/pause.svg';
import play from 'src/assets/img/icons/play.svg';
import plus from 'src/assets/img/icons/plus.svg';
import prev from 'src/assets/img/icons/prev.svg';
import print from 'src/assets/img/icons/print.svg';
import radio_off from 'src/assets/img/icons/radio_off.svg';
import radio_on from 'src/assets/img/icons/radio_on.svg';
import rank from 'src/assets/img/icons/rank.svg';
import reading from 'src/assets/img/icons/reading.svg';
import refresh from 'src/assets/img/icons/refresh.svg';
import save from 'src/assets/img/icons/save.svg';
import scrubber from 'src/assets/img/icons/scrubber.svg';
import search from 'src/assets/img/icons/search.svg';
import searchResult from 'src/assets/img/icons/searchResult.svg';
import select2 from 'src/assets/img/icons/select2.svg';
import settings from 'src/assets/img/icons/settings.svg';
import showmore from 'src/assets/img/icons/show-more.svg';
import sortdown from 'src/assets/img/icons/sort-down.svg';
import sortup from 'src/assets/img/icons/sort-up.svg';
import sort from 'src/assets/img/icons/sort.svg';
import stack_cancel from 'src/assets/img/icons/stack_cancel.svg';
import star_empty from 'src/assets/img/icons/star_empty.svg';
import star_full from 'src/assets/img/icons/star_full.svg';
import table from 'src/assets/img/icons/table.svg';
import tag from 'src/assets/img/icons/tag.svg';
import target from 'src/assets/img/icons/target.svg';
import tip from 'src/assets/img/icons/tip.svg';
import toc from 'src/assets/img/icons/toc.svg';
import TOCbroom from 'src/assets/img/icons/TOCbroom.svg';
import TOCcheckboxchecked from 'src/assets/img/icons/TOCcheckboxchecked.svg';
import TOCcheckboxpartial from 'src/assets/img/icons/TOCcheckboxpartial.svg';
import TOCcheckboxunchecked from 'src/assets/img/icons/TOCcheckboxunchecked.svg';
import TOCcollapse from 'src/assets/img/icons/TOCcollapse.svg';
import TOCdash from 'src/assets/img/icons/TOCdash.svg';
import TOCExpand from 'src/assets/img/icons/TOCExpand.svg';
import TOClinked from 'src/assets/img/icons/TOClinked.svg';
import TOCmasterchecked from 'src/assets/img/icons/TOCmasterchecked.svg';
import TOCmasterunchecked from 'src/assets/img/icons/TOCmasterunchecked.svg';
import TOCminus from 'src/assets/img/icons/TOCminus.svg';
import TOCplus from 'src/assets/img/icons/TOCplus.svg';
import TOCunlinked from 'src/assets/img/icons/TOCunlinked.svg';
import user from 'src/assets/img/icons/user.svg';
import versions from 'src/assets/img/icons/versions.svg';
import warning from 'src/assets/img/icons/warning.svg';
import warningResolved from 'src/assets/img/icons/warningResolved.svg';
import www from 'src/assets/img/icons/www.svg';
export class Image {

static annotations = (props:any) => {
	return (
		<InlineSVG src={annotations} />
	)
};
static annotationTypes = (props:any) => {
	return (
		<InlineSVG src={annotationTypes} />
	)
};
static arrowback = (props:any) => {
	return (
		<InlineSVG src={arrowback} />
	)
};
static arrownext = (props:any) => {
	return (
		<InlineSVG src={arrownext} />
	)
};
static arrowprev = (props:any) => {
	return (
		<InlineSVG src={arrowprev} />
	)
};
static attachment = (props:any) => {
	return (
		<InlineSVG src={attachment} />
	)
};
static book = (props:any) => {
	return (
		<InlineSVG src={book} />
	)
};
static bookmark = (props:any) => {
	return (
		<InlineSVG src={bookmark} />
	)
};
static bulletin = (props:any) => {
	return (
		<InlineSVG src={bulletin} />
	)
};
static cancel_search = (props:any) => {
	return (
		<InlineSVG src={cancel_search} />
	)
};
static card = (props:any) => {
	return (
		<InlineSVG src={card} />
	)
};
static cart = (props:any) => {
	return (
		<InlineSVG src={cart} />
	)
};
static close = (props:any) => {
	return (
		<InlineSVG src={close} />
	)
};
static comments = (props:any) => {
	return (
		<InlineSVG src={comments} />
	)
};
static community = (props:any) => {
	return (
		<InlineSVG src={community} />
	)
};
static copy = (props:any) => {
	return (
		<InlineSVG src={copy} />
	)
};
static deleteResolved = (props:any) => {
	return (
		<InlineSVG src={deleteResolved} />
	)
};
static delete_item = (props:any) => {
	return (
		<InlineSVG src={delete_item} />
	)
};
static download = (props:any) => {
	return (
		<InlineSVG src={download} />
	)
};
static empty = (props:any) => {
	return (
		<InlineSVG src={empty} />
	)
};
static favmultioff = (props:any) => {
	return (
		<InlineSVG src={favmultioff} />
	)
};
static favmultion = (props:any) => {
	return (
		<InlineSVG src={favmultion} />
	)
};
static favsingleoff = (props:any) => {
	return (
		<InlineSVG src={favsingleoff} />
	)
};
static favsingleon = (props:any) => {
	return (
		<InlineSVG src={favsingleon} />
	)
};
static fav = (props:any) => {
	return (
		<InlineSVG src={fav} />
	)
};
static favourite = (props:any) => {
	return (
		<InlineSVG src={favourite} />
	)
};
static feedback = (props:any) => {
	return (
		<InlineSVG src={feedback} />
	)
};
static filteroff = (props:any) => {
	return (
		<InlineSVG src={filteroff} />
	)
};
static filteron = (props:any) => {
	return (
		<InlineSVG src={filteron} />
	)
};
static filter = (props:any) => {
	return (
		<InlineSVG src={filter} />
	)
};
static folder = (props:any) => {
	return (
		<InlineSVG src={folder} />
	)
};
static hamburger = (props:any) => {
	return (
		<InlineSVG src={hamburger} />
	)
};
static help = (props:any) => {
	return (
		<InlineSVG src={help} />
	)
};
static history = (props:any) => {
	return (
		<InlineSVG src={history} />
	)
};
static hitnext = (props:any) => {
	return (
		<InlineSVG src={hitnext} />
	)
};
static hitprev = (props:any) => {
	return (
		<InlineSVG src={hitprev} />
	)
};
static hit_next = (props:any) => {
	return (
		<InlineSVG src={hit_next} />
	)
};
static hit_previous = (props:any) => {
	return (
		<InlineSVG src={hit_previous} />
	)
};
static info = (props:any) => {
	return (
		<InlineSVG src={info} />
	)
};
static link = (props:any) => {
	return (
		<InlineSVG src={link} />
	)
};
static lock = (props:any) => {
	return (
		<InlineSVG src={lock} />
	)
};
static media = (props:any) => {
	return (
		<InlineSVG src={media} />
	)
};
static menu = (props:any) => {
	return (
		<InlineSVG src={menu} />
	)
};
static next = (props:any) => {
	return (
		<InlineSVG src={next} />
	)
};
static notemultioff = (props:any) => {
	return (
		<InlineSVG src={notemultioff} />
	)
};
static notemultion = (props:any) => {
	return (
		<InlineSVG src={notemultion} />
	)
};
static noteon = (props:any) => {
	return (
		<InlineSVG src={noteon} />
	)
};
static notesingleoff = (props:any) => {
	return (
		<InlineSVG src={notesingleoff} />
	)
};
static notesingleon = (props:any) => {
	return (
		<InlineSVG src={notesingleon} />
	)
};
static note = (props:any) => {
	return (
		<InlineSVG src={note} />
	)
};
static off = (props:any) => {
	return (
		<InlineSVG src={off} />
	)
};
static pause = (props:any) => {
	return (
		<InlineSVG src={pause} />
	)
};
static play = (props:any) => {
	return (
		<InlineSVG src={play} />
	)
};
static plus = (props:any) => {
	return (
		<InlineSVG src={plus} />
	)
};
static prev = (props:any) => {
	return (
		<InlineSVG src={prev} />
	)
};
static print = (props:any) => {
	return (
		<InlineSVG src={print} />
	)
};
static radio_off = (props:any) => {
	return (
		<InlineSVG src={radio_off} />
	)
};
static radio_on = (props:any) => {
	return (
		<InlineSVG src={radio_on} />
	)
};
static rank = (props:any) => {
	return (
		<InlineSVG src={rank} />
	)
};
static reading = (props:any) => {
	return (
		<InlineSVG src={reading} />
	)
};
static refresh = (props:any) => {
	return (
		<InlineSVG src={refresh} />
	)
};
static save = (props:any) => {
	return (
		<InlineSVG src={save} />
	)
};
static scrubber = (props:any) => {
	return (
		<InlineSVG src={scrubber} />
	)
};
static search = (props:any) => {
	return (
		<InlineSVG src={search} />
	)
};
static searchResult = (props:any) => {
	return (
		<InlineSVG src={searchResult} />
	)
};
static select2 = (props:any) => {
	return (
		<InlineSVG src={select2} />
	)
};
static settings = (props:any) => {
	return (
		<InlineSVG src={settings} />
	)
};
static showmore = (props:any) => {
	return (
		<InlineSVG src={showmore} />
	)
};
static sortdown = (props:any) => {
	return (
		<InlineSVG src={sortdown} />
	)
};
static sortup = (props:any) => {
	return (
		<InlineSVG src={sortup} />
	)
};
static sort = (props:any) => {
	return (
		<InlineSVG src={sort} />
	)
};
static stack_cancel = (props:any) => {
	return (
		<InlineSVG src={stack_cancel} />
	)
};
static star_empty = (props:any) => {
	return (
		<InlineSVG src={star_empty} />
	)
};
static star_full = (props:any) => {
	return (
		<InlineSVG src={star_full} />
	)
};
static table = (props:any) => {
	return (
		<InlineSVG src={table} />
	)
};
static tag = (props:any) => {
	return (
		<InlineSVG src={tag} />
	)
};
static target = (props:any) => {
	return (
		<InlineSVG src={target} />
	)
};
static tip = (props:any) => {
	return (
		<InlineSVG src={tip} />
	)
};
static toc = (props:any) => {
	return (
		<InlineSVG src={toc} />
	)
};
static TOCbroom = (props:any) => {
	return (
		<InlineSVG src={TOCbroom} />
	)
};
static TOCcheckboxchecked = (props:any) => {
	return (
		<InlineSVG src={TOCcheckboxchecked} />
	)
};
static TOCcheckboxpartial = (props:any) => {
	return (
		<InlineSVG src={TOCcheckboxpartial} />
	)
};
static TOCcheckboxunchecked = (props:any) => {
	return (
		<InlineSVG src={TOCcheckboxunchecked} />
	)
};
static TOCcollapse = (props:any) => {
	return (
		<InlineSVG src={TOCcollapse} />
	)
};
static TOCdash = (props:any) => {
	return (
		<InlineSVG src={TOCdash} />
	)
};
static TOCExpand = (props:any) => {
	return (
		<InlineSVG src={TOCExpand} />
	)
};
static TOClinked = (props:any) => {
	return (
		<InlineSVG src={TOClinked} />
	)
};
static TOCmasterchecked = (props:any) => {
	return (
		<InlineSVG src={TOCmasterchecked} />
	)
};
static TOCmasterunchecked = (props:any) => {
	return (
		<InlineSVG src={TOCmasterunchecked} />
	)
};
static TOCminus = (props:any) => {
	return (
		<InlineSVG src={TOCminus} />
	)
};
static TOCplus = (props:any) => {
	return (
		<InlineSVG src={TOCplus} />
	)
};
static TOCunlinked = (props:any) => {
	return (
		<InlineSVG src={TOCunlinked} />
	)
};
static user = (props:any) => {
	return (
		<InlineSVG src={user} />
	)
};
static versions = (props:any) => {
	return (
		<InlineSVG src={versions} />
	)
};
static warning = (props:any) => {
	return (
		<InlineSVG src={warning} />
	)
};
static warningResolved = (props:any) => {
	return (
		<InlineSVG src={warningResolved} />
	)
};
static www = (props:any) => {
	return (
		<InlineSVG src={www} />
	)
};}
