import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {
    Button, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Spinner, TabContent, TabPane
} from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Log } from 'src/Logger';
import {
    AppSession, AppSettings, FontSizes, LibraryConfig, LibrarySession, LoginType, SearchEngines,
    SettingsTarget
} from 'src/models/AppSession';
import { Book } from 'src/models/Book';
import { IUpdateCheckR } from 'src/models/dto/AppRequest';
import { ExternalLinkingError, ExternalLinkingPayload } from 'src/models/ExternalLinking';
import { Migration } from 'src/models/Migration';
import { Resource } from 'src/models/Resource';
import { Status } from 'src/network/Requests';
import { Wire } from 'src/network/Wire';
import { EmitterEvent, EventHandler } from 'src/utilities/Events';
import { Constants, Convert, ResourcePlatform } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, Drawer, DrawerContainer, FabButton, Loading, Separator, Switch, VerticalNavBar
} from '../foundation/Controls';
import { ImportOfflineForm } from '../foundation/Controls/ImportOfflineForm';
import * as Messages from '../foundation/Messages';
import { withTheme } from '../state/Accessors';
import { AppContext, LibraryContext } from '../state/Contextes';
import { IEmptyValue, IGenericValue } from '../state/Generics';
import { AddLibraryView } from './AddLibraryView';
import { BookView } from './BookView';
import { LibraryView } from './LibraryView';
import { MigrationView } from './MigrationView';

export interface IAppViewState {
  loading: boolean;
  drawerOpen: boolean;
  libraries: LibrarySession[];
  activeLibrary: string;
  notifications: Messages.INotification[];
  dialog?: Messages.IDialogItemProps;
  optionsOpen: boolean;
  online: boolean;
}
export class AppView extends React.Component<unknown, IAppViewState> {
  context: AppSession;
  static contextType = AppContext;
  static notificationContainer = React.createRef<Messages.NotificationContainer>();
  static dialogContainer = React.createRef<Messages.DialogContainer>();
  initializing: boolean;

  constructor(props: any) {
    super(props);
    this.onAppNavigation = this.onAppNavigation.bind(this);
    this.onOpenDrawer = this.onOpenDrawer.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.update = this.update.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.updateDialog = this.updateDialog.bind(this);
    this.appLoaded = this.appLoaded.bind(this);
    this.onDismissOptions = this.onDismissOptions.bind(this);
    this.externalLinkError = this.externalLinkError.bind(this);
    this.appUpdateAvailable = this.appUpdateAvailable.bind(this);
    this.checkKeys = this.checkKeys.bind(this);
    this.state = {
      libraries: new Array<LibrarySession>(),
      activeLibrary: "",
      loading: true,
      drawerOpen: false,
      notifications: [],
      optionsOpen: false,
      online: false,
    };
  }

  async componentDidMount() {
    this.context.appUpdateAvailable.on(this.appUpdateAvailable);
    this.context.appLoading.loaded.on(this.appLoaded);
    this.context.appLoading.stepLoading.on("data", this.appLoading);
    this.context.libraries.addListener(this.update);
    this.context.currentLibraryNotifier.addListener(this.update);
    this.context.notifications.addListener(this.updateNotifications);
    this.context.dialog.addListener(this.updateDialog);
    this.context.externalLinkingParseFailed.on(this.externalLinkError);
    document.removeEventListener("keydown", this.checkKeys, false);
    document.addEventListener("keydown", this.checkKeys, false);
    await this.context.initialize(window.innerHeight, window.innerWidth);
    this.update();
  }
  componentWillUnmount() {
    this.context.libraries.removeListener(this.update);
    this.context.currentLibraryNotifier.removeListener(this.update);
    this.context.notifications.removeListener(this.updateNotifications);
    this.context.dialog.removeListener(this.updateDialog);
    this.context.externalLinkingParseFailed.off(this.externalLinkError);
    document.removeEventListener("keydown", this.checkKeys, false);
  }

  private checkKeys(event: any) {
    if (event.ctrlKey || event.metaKey) {
      if (event.keyCode === 82) {
        event.preventDefault();
        event.stopPropagation();
        this.context.refreshSelectedLibrary();
        return false;
      }
    }
    return true;
  }
  appUpdateAvailable(updateResp: IUpdateCheckR) {
    setTimeout(async () => {
      let result: string | number = "false";
      if (updateResp.IsRequired) {
        result = await Messages.Dialog.confirm(
          this.context.localization.currentLocale.LoginView.ALERT_LOGIN_UPDATEREQUIRED,
          this.context.localization.currentLocale.LoginView.ALERT_LOGIN_UPDATEREQUIRED_TITLE,
          Messages.Dialog.Buttons.YesNo
        );
      } else {
        result = await Messages.Dialog.confirm(
          this.context.localization.currentLocale.LoginView.ALERT_LOGIN_UPDATEAVAILABLE,
          this.context.localization.currentLocale.LoginView.ALERT_LOGIN_UPDATEAVAILABLE_TITLE,
          Messages.Dialog.Buttons.YesNo
        );
      }
      if (result === "true") {
        this.context.shutdownApp({ RedirectUrl: updateResp.UpdatePath });
      } else if (updateResp.IsRequired) {
        this.context.shutdownApp({ RedirectUrl: "" });
      }
    }, 500);
  }

  // App level external link errors handled in the proper view for their context.
  externalLinkError(externalLink: ExternalLinkingPayload) {
    switch (externalLink.ParseError) {
      case ExternalLinkingError.INVALID:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_ERROR);
        break;
      case ExternalLinkingError.UNKNOWN_BRAND:
        Messages.Notify.error(this.context.localization.currentLocale.Application.EXTERNAL_LINK_UNKNOWN_BRAND);
        break;
    }
  }

  appLoaded() {
    if (this.state.loading === true) {
      this.setState({
        loading: false,
      });
    }
  }
  appLoading = () => {
    if (this.state.loading === false) {
      this.setState({
        loading: true,
      });
    }
  };

  update() {
    let lib = this.context.currentLibraryNotifier.get();
    if (lib && lib.config.BrandKey) {
      document.title = lib.name;
      let link: any = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = "data:image/png;base64," + lib.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
      this.setState({
        libraries: this.context.libraries.rows(),
        activeLibrary: lib.id,
      });
      if (ResourcePlatform.ShouldCheckUrlLinks() && window.location.href.replace(/\/+$/, "") !== window.location.origin) {
        // we have a link probably
        let valHref = window.location.href.replace(window.location.origin, "prolibro-" + lib.config.BrandKey + ":/");
        window.history.replaceState({}, document.title, "/");
        this.context.reportExternalLink({ Url: valHref });
      }
    }
  }

  updateNotifications() {
    this.setState({ notifications: this.context.notifications.rows() });
  }

  updateDialog() {
    this.setState({ dialog: this.context.dialog.get() });
  }

  onAppNavigation(action: AppNavBarActions) {
    switch (action) {
      case AppNavBarActions.user:
        this.setState({ drawerOpen: false }, async () => {
          let lib = this.state.libraries.filter((x) => x.config.BrandKey === this.state.activeLibrary)[0];
          if (lib.loginType === LoginType.Anonymous) {
            let result = await Messages.Dialog.confirm(
              Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_ANONYMOUS_LOGIN, [lib.userName]),
              this.context.localization.currentLocale.LibraryView.LABEL_ANONYMOUS_LOGIN_TITLE,
              Messages.Dialog.Buttons.YesNo
            );
            if (result === "true") {
              await this.context.logout(this.state.activeLibrary);
            }
          } else if (lib.loginType === LoginType.User) {
            let result = await Messages.Dialog.confirm(
              Convert.formatString(this.context.localization.currentLocale.LibraryView.LABEL_LOGOUT_CONFIRM, [lib.userName]),
              this.context.localization.currentLocale.LibraryView.LABEL_LOGOUT,
              Messages.Dialog.Buttons.YesNo
            );
            if (result === "true") {
              await this.context.logout(this.state.activeLibrary);
            }
          } else if (this.state.activeLibrary !== Constants.getAddNewConstant() && this.context.appSettings.get().CanAddLibraries) {
            let result = await Messages.Dialog.confirm(
              this.context.localization.currentLocale.Application.ALERT_REMOVE_LIBRARY,
              this.context.localization.currentLocale.Application.ALERT_REMOVE_LIBRARY_TITLE,
              Messages.Dialog.Buttons.YesNo
            );
            if (result === "true") {
              await this.context.deleteLibConfig({ BrandKey: this.state.activeLibrary });
            }
          }
        });
        break;
      case AppNavBarActions.help:
        this.setState({ optionsOpen: !this.state.optionsOpen });
        break;
      case AppNavBarActions.library:
        break;
    }

    this.onCloseDrawer();
  }

  onOpenDrawer() {
    this.setState({
      drawerOpen: true,
    });
  }

  onCloseDrawer() {
    this.setState({
      drawerOpen: false,
    });
  }
  onDismissOptions() {
    this.setState({ optionsOpen: false });
  }

  render(): any {
    return (
      <AppErrorBoundary>
        <Loading
          isLoading={this.state.loading}
          theme="opaque"
          className="full-height initial-app-loading"
          status={this.context.localization.currentLocale.LibraryView.ALERT_REFRESHING_LIBRARY}
        >
          {/* <div>
             <OfflinePackagePasswordDialog  />
          </div> */}
          <div id="appLayout" className="d-flex">
            {this.state.optionsOpen && (
              <OptionsPanel
                initialOpen={this.state.optionsOpen}
                initialSettings={this.context.appSettings.get()}
                initialConfig={this.context.currentConfig.get()}
                onDismiss={this.onDismissOptions}
                activeLibrary={this.state.activeLibrary}
              />
            )}
            <DrawerContainer direction="left" className={"flex-fill d-flex flex-row " + this.state.activeLibrary}>
              <Drawer className="menu-drawer" isOpen={this.state.drawerOpen} backdrop={true} onBackdropClicked={this.onCloseDrawer}>
                <AppNavBar onNavigation={this.onAppNavigation} isExpanded={true} />
              </Drawer>
              <Messages.NotificationContainer notifications={this.state.notifications} />
              <Messages.DialogContainer current={this.state.dialog} />
              <AppNavBar onNavigation={this.onAppNavigation} />
              <TabContent activeTab={this.state.activeLibrary} className="flex-fill d-flex flex-column">
                {this.state.libraries.map((library) => {
                  if (library.config.BrandKey === this.state.activeLibrary && library.config.BrandKey !== Constants.getAddNewConstant()) {
                    let cobrand = library.config.CoBrandImage === null ? "" : library.config.CoBrandImage;
                    library.shouldLimitRendering = false;
                    if (
                      ResourcePlatform.UsesWebSso() &&
                      !Convert.isEmptyOrSpaces(library.config.MicrosoftTenantOverride) &&
                      this.context.MSALInstance.getConfiguration().auth.authority !==
                        this.context.MSALInstance.getConfiguration().auth.authority.replace("common", library.config.MicrosoftTenantOverride)
                    ) {
                      this.context.MSALInstance.getConfiguration().auth.authority = this.context.MSALInstance.getConfiguration().auth.authority.replace(
                        "common",
                        library.config.MicrosoftTenantOverride
                      );
                    }

                    return (
                      <TabPane key={library.id} tabId={library.id} className={library.config.BrandKey + " flex-fill d-flex flex-column"}>
                        {cobrand !== "" && (
                          <div
                            className="cobrandImage"
                            style={{
                              backgroundImage: `url(data:image/png;base64,${cobrand})`,
                            }}
                          />
                        )}
                        <LibraryContext.Provider value={library}>
                          <LibraryCollectionView />
                        </LibraryContext.Provider>
                      </TabPane>
                    );
                  } else if (library.config.BrandKey !== Constants.getAddNewConstant()) {
                    library.shouldLimitRendering = true;
                    return (
                      <TabPane key={library.id} tabId={library.id} className={library.config.BrandKey + " flex-fill d-flex flex-column"}>
                        <LibraryContext.Provider value={library}>
                          <LibraryCollectionView />{" "}
                        </LibraryContext.Provider>
                      </TabPane>
                    );
                  } else {
                    return "";
                  }
                })}

                {this.context.appSettings.get().CanAddLibraries && Constants.getAddNewConstant() === this.state.activeLibrary && <AddLibraryView />}
              </TabContent>
              <FabButton src={<Image.hamburger />} active={true} onClick={this.onOpenDrawer} />
            </DrawerContainer>
          </div>
        </Loading>
      </AppErrorBoundary>
    );
  }
}

class AppErrorBoundaryState {
  errorCount: number;
}
class AppErrorBoundary extends React.Component<unknown, AppErrorBoundaryState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = { errorCount: 0 };
  }

  static getDerivedStateFromError() {}

  componentDidCatch(error: Error) {
    Messages.Notify.error(error.message);
    Log.fatal("AN APP LEVEL FRONTEND ERROR OCCURRED. Error message: " + error.message.toString());
    if (error.stack) {
      Log.fatal("Error stacktrace: " + error.stack.toString());
    } else {
      Log.fatal("Error stacktrace: None provided");
    }
    Log.fatal("Error name: " + error.name.toString());

    this.setState({ errorCount: this.state.errorCount + 1 });
  }

  render() {
    return <React.Fragment key={this.state.errorCount}>{this.props.children}</React.Fragment>;
  }
}

interface IOptionsPanelProps {
  initialSettings: AppSettings;
  initialConfig: SettingsTarget;
  initialOpen: boolean;
  activeLibrary: string;
  onDismiss: () => void;
}
interface IOptionsPanelState {
  customContent: string;
  isCustomShown: boolean;
  isLivePage: boolean;
  isLivePageLoading: boolean;
  currentSettings: AppSettings;
  currentConfig: SettingsTarget;
  currentServerUrlOverride: string;
  currentLinkingSiteUrlOverride: string;
  sendLogsDisabled: boolean;
  isOpen: boolean;
  openUpload: boolean;
  online: boolean;
}
class OptionsPanel extends React.Component<IOptionsPanelProps, IOptionsPanelState> {
  context: AppSession;
  static contextType = AppContext;
  optionsResource: Resource;
  constructor(props: IOptionsPanelProps) {
    super(props);
    this.state = {
      customContent: "",
      isCustomShown: false,
      currentSettings: props.initialSettings,
      sendLogsDisabled: false,
      isOpen: this.props.initialOpen,
      openUpload: false,
      isLivePage: false,
      isLivePageLoading: false,
      currentConfig: SettingsTarget.Release,
      currentLinkingSiteUrlOverride: "",
      currentServerUrlOverride: "",
      online: false,
    };
    this.loadAboutPage = this.loadAboutPage.bind(this);
    this.loadLivePage = this.loadLivePage.bind(this);
    this.handleReaderFontChange = this.handleReaderFontChange.bind(this);
    this.handleTOCFontChange = this.handleTOCFontChange.bind(this);
    this.handleSearchEngineChange = this.handleSearchEngineChange.bind(this);
    this.handleSearchFilteringChanged = this.handleSearchFilteringChanged.bind(this);
    this.handleLanguageOverrideChanged = this.handleLanguageOverrideChanged.bind(this);
    this.handleConfigOverrideChanged = this.handleConfigOverrideChanged.bind(this);
    this.handleHelEnabledChanged = this.handleHelEnabledChanged.bind(this);
    this.handleContentViewGuttersChanged = this.handleContentViewGuttersChanged.bind(this);
    this.handleSendLogs = this.handleSendLogs.bind(this);
    this.handleOverrideUrlSubmit = this.handleOverrideUrlSubmit.bind(this);
    this.update = this.update.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.importOfflinePackage = this.importOfflinePackage.bind(this);
    this.dismissOfflinePackage = this.dismissOfflinePackage.bind(this);
  }
  componentDidMount() {
    this.optionsResource = new Resource(Wire.shield(this.context.wire), -1);
    this.setState({
      currentSettings: this.props.initialSettings,
      currentConfig: this.props.initialConfig,
      isOpen: this.props.initialOpen,
      currentLinkingSiteUrlOverride: this.context.appSettings.get().CurrentLinkingUrlOverride,
      currentServerUrlOverride: this.context.appSettings.get().CurrentServerUrlOverride,
    });
  }
  dismiss() {
    if (this.state.isCustomShown) {
      this.setState({ isCustomShown: false });
    } else {
      this.props.onDismiss();
    }
  }
  componentWillUnmount() {}
  handleReaderFontChange(e: any) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), ReaderFontSize: +e.target.value });
    this.update();
  }
  handleTOCFontChange(e: any) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), TOCFontSize: +e.target.value });
    this.update();
  }
  handleSearchEngineChange(e: any) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), SearchEngine: +e.target.value });
    this.update();
  }
  handleSearchFilteringChanged(newVal: boolean) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), SearchFilteringEnabled: newVal });
    this.update();
  }
  handleLanguageOverrideChanged(newVal: Languages) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), LanguageOverride: newVal });
    this.context.localization.changeLanguage(newVal);
    this.update();
  }
  async handleConfigOverrideChanged(newVal: SettingsTarget) {
    let result = await this.context.setConfigOverride({ NewValue: newVal, ServerUrlOverride: "", LinkingSiteUrlOverride: "" });
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.OptionView.LABEL_CURRENT_CONFIG_TARGET_SUCCESS);
    } else {
      Messages.Notify.error(this.context.localization.currentLocale.OptionView.LABEL_CURRENT_CONFIG_TARGET_FAILURE + result.errors[0].Message);
    }
    this.update();
  }
  async handleOverrideUrlSubmit(newServer: string, newLinking: string) {
    let result = await this.context.setConfigOverride({ NewValue: 0, ServerUrlOverride: newServer, LinkingSiteUrlOverride: newLinking });
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.OptionView.LABEL_CURRENT_CONFIG_TARGET_SUCCESS);
    } else {
      Messages.Notify.error(this.context.localization.currentLocale.OptionView.LABEL_CURRENT_CONFIG_TARGET_FAILURE + result.errors[0].Message);
    }
    this.update();
  }
  handleHelEnabledChanged(newVal: boolean) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), HelpEnabled: newVal });
    this.update();
  }
  handleReopenTitlesChanged(newVal: boolean) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), ReopenTitles: newVal });
    this.update();
  }
  handleLeftPanelDefaultOpenChanged(newVal: boolean) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), LeftPanelDefaultOpen: newVal });
    this.update();
  }

  handleContentViewGuttersChanged(newVal: boolean) {
    // Persist the change
    this.context.appSettings.set({ ...this.context.appSettings.get(), ContentViewGuttersEnabled: newVal });
    this.update();
  }

  loadAboutPage(index: number) {
    this.setState({
      isCustomShown: true,
      isLivePage: false,
      customContent: JSON.parse(
        this.context.userConfigs.get(this.props.activeLibrary)!.InstanceInternationalizations[this.context.localization.currentCulture - 1].StringAboutHtml
      )[index],
    });
  }
  loadLivePage(url: string) {
    this.setState({
      isCustomShown: true,
      isLivePageLoading: true,
      isLivePage: true,
      customContent: url,
    });
  }
  update() {
    this.setState({
      currentSettings: this.context.appSettings.get(),
      currentConfig: this.context.currentConfig.get(),
      currentLinkingSiteUrlOverride: this.context.appSettings.get().CurrentLinkingUrlOverride,
      currentServerUrlOverride: this.context.appSettings.get().CurrentServerUrlOverride,
    });
  }

  async importOfflinePackage() {
    let result = await this.context.getInternetConnectionStatus();

    this.setState({
      isCustomShown: true,
      openUpload: true,
      online: result.data.Online,
    });
  }
  deleteOutdatedVersions = async () => {
    let diagRes = await Messages.Dialog.confirm(
      this.context.localization.currentLocale.OptionView.LABEL_DELETE_OUTDATED_MESSAGE,
      this.context.localization.currentLocale.OptionView.LABEL_DELETE_OUTDATED_HEADING
    );
    if (diagRes === "true") {
      this.setState({ isLivePageLoading: true }, async () => {
        let result = await this.context.libraries.get(this.props.activeLibrary)!.deleteOutdatedVersions();
        if (!result.valid()) {
          Messages.Notify.error(result.errors[0].Message);
        } else {
          this.context.libraries.get(this.props.activeLibrary)!.libraryPanelRefreshRequested.dispatch(null, this);
        }
        this.setState({ isLivePageLoading: false });
      });
    } else {
      return;
    }
  };
  dismissOfflinePackage() {
    if (this.state.isCustomShown) {
      this.setState({ isCustomShown: false, openUpload: false });
    } else {
      this.props.onDismiss();
    }
  }

  async handleSendLogs() {
    let comment = "";
    let response = await Messages.Dialog.confirm(
      <div>
        <span>{this.context.localization.currentLocale.OptionView.DIALOG_SEND_LOGS_CONFIRM}</span>
        <label>{this.context.localization.currentLocale.OptionView.LABEL_ADDITIONAL_COMMENTS}</label>
        <br />
        <input
          type="text"
          style={{ width: "100%" }}
          onChange={(e) => {
            comment = e.target.value;
          }}
        />
      </div>,
      this.context.localization.currentLocale.OptionView.DIALOG_SEND_LOGS_CONFIRM_TITLE
    );
    if (response === "true") {
      this.setState({ sendLogsDisabled: true }, async () => {
        let res = await this.context.sendAppLogs({ Comments: comment });
        if (res.status !== Status.Success) {
          Messages.Notify.error(this.context.localization.currentLocale.OptionView.ALERT_LOGS_SEND_FAILED);
        } else {
          Messages.Notify.success(this.context.localization.currentLocale.OptionView.ALERT_LOGS_SENT);
        }
        this.setState({ sendLogsDisabled: false });
      });
    }
  }
  render() {
    let userConfig = this.context.userConfigs.get(this.props.activeLibrary);
    if (!this.state.currentSettings) {
      return "";
    }
    let btnColor = "danger";
    if (this.state.sendLogsDisabled) {
      btnColor = "secondary";
    }
    let formContent;
    if (this.state.isCustomShown) {
      if (this.state.openUpload) {
        formContent = (
          <ImportOfflineForm
            dismiss={this.dismissOfflinePackage}
            online={this.state.online}
            brandKey={this.context.userConfigs.get(this.props.activeLibrary)!.BrandKey}
          />
        );
      } else if (!this.state.isLivePage) {
        formContent = <iframe srcDoc={this.state.customContent} />;
      } else {
        formContent = (
          <Loading isLoading={this.state.isLivePageLoading}>
            <iframe
              src={this.state.customContent}
              onLoad={() => {
                this.setState({ isLivePageLoading: false });
              }}
            />
          </Loading>
        );
      }
    } else {
      formContent = (
        <div className="innerContainer">
          <Loading isLoading={this.state.isLivePageLoading}>
            <Form>
              <h1>{this.context.localization.currentLocale.OptionView.LABEL_APP_SETTINGS}</h1>
              <FormGroup>
                <h4>{this.context.localization.currentLocale.OptionView.LABEL_LANGSELECTOR}</h4>
                {/* <ImportOfflineForm initialOpen={this.props.initialOpen} dismiss={this.dismiss} /> */}
                <Col sm={10}>
                  <FormGroup>
                    <Input
                      type="select"
                      name="select"
                      value={this.state.currentSettings.LanguageOverride}
                      onChange={(e) => {
                        this.handleLanguageOverrideChanged(+e.target.value);
                      }}
                    >
                      <option value={Languages.English}>{this.context.localization.currentLocale.OptionView.LABEL_ENGLISH}</option>
                      <option value={Languages.French}>{this.context.localization.currentLocale.OptionView.LABEL_FRENCH}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={10}>
                  <FormGroup>
                    <Label>{this.context.localization.currentLocale.OptionView.LABEL_ENABLEHELP}</Label>
                    <Switch
                      name="check2"
                      on={this.state.currentSettings.HelpEnabled}
                      onChange={(e) => {
                        this.handleHelEnabledChanged(e.target.checked);
                      }}
                      className="ml-3"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{this.context.localization.currentLocale.OptionView.LABEL_REOPEN_TITLES}</Label>
                    <Switch
                      name="check4"
                      on={this.state.currentSettings.ReopenTitles}
                      onChange={(e) => {
                        this.handleReopenTitlesChanged(e.target.checked);
                      }}
                      className="ml-3"
                    />
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={10}>
                  <FormGroup>
                    <Label>{this.context.localization.currentLocale.OptionView.LABEL_LEFT_PANEL_OPEN}</Label>
                    <Switch
                      name="check2"
                      on={this.state.currentSettings.LeftPanelDefaultOpen}
                      onChange={(e) => {
                        this.handleLeftPanelDefaultOpenChanged(e.target.checked);
                      }}
                      className="ml-3"
                    />
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <h3>{this.context.localization.currentLocale.OptionView.LABEL_CONTENT_SECTION}</h3>
                <h4>{this.context.localization.currentLocale.OptionView.LABEL_READER_TEXT_SIZE}</h4>
                <Col sm={10}>
                  <FormGroup>
                    <Input type="select" name="select" value={this.state.currentSettings.ReaderFontSize} onChange={this.handleReaderFontChange}>
                      <option value={FontSizes.Largest}>{this.context.localization.currentLocale.OptionView.FONTSIZE_LARGEST}</option>
                      <option value={FontSizes.Larger}>{this.context.localization.currentLocale.OptionView.FONTSIZE_LARGER}</option>
                      <option value={FontSizes.Normal}>{this.context.localization.currentLocale.OptionView.FONTSIZE_NORMAL}</option>
                      <option value={FontSizes.Smaller}>{this.context.localization.currentLocale.OptionView.FONTSIZE_SMALLER}</option>
                      <option value={FontSizes.Smallest}>{this.context.localization.currentLocale.OptionView.FONTSIZE_SMALLEST}</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={10}>
                      <FormGroup>
                        <Label>{this.context.localization.currentLocale.OptionView.LABEL_BOOK_GUTTERS}</Label>
                        <Switch
                          name="check3"
                          on={this.state.currentSettings.ContentViewGuttersEnabled}
                          onChange={(e) => {
                            this.handleContentViewGuttersChanged(e.target.checked);
                          }}
                          className="ml-3"
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <h4>{this.context.localization.currentLocale.OptionView.LABEL_SEARCHENGINE}</h4>
                <Col sm={10}>
                  <Input type="select" name="select" value={this.state.currentSettings.SearchEngine} onChange={this.handleSearchEngineChange}>
                    {Object.keys(SearchEngines)
                      .filter((key) => !isNaN(Number(SearchEngines[key])))
                      .map((it, ind) => (
                        <option key={ind + 1} value={ind + 1}>
                          {it}
                        </option>
                      ))}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup>
                <h3>{this.context.localization.currentLocale.OptionView.LABEL_TABLEOFCONTENTS_SECTION}</h3>
                <h4>{this.context.localization.currentLocale.OptionView.LABEL_TOC_TEXT_SIZE}</h4>
                <Col sm={10}>
                  <FormGroup>
                    <Input type="select" name="select" value={this.state.currentSettings.TOCFontSize} onChange={this.handleTOCFontChange}>
                      <option value={FontSizes.Largest}>{this.context.localization.currentLocale.OptionView.FONTSIZE_LARGEST}</option>
                      <option value={FontSizes.Larger}>{this.context.localization.currentLocale.OptionView.FONTSIZE_LARGER}</option>
                      <option value={FontSizes.Normal}>{this.context.localization.currentLocale.OptionView.FONTSIZE_NORMAL}</option>
                      <option value={FontSizes.Smaller}>{this.context.localization.currentLocale.OptionView.FONTSIZE_SMALLER}</option>
                      <option value={FontSizes.Smallest}>{this.context.localization.currentLocale.OptionView.FONTSIZE_SMALLEST}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </FormGroup>
              {this.context.originalConfig.get() !== SettingsTarget.Release && this.context.originalConfig.get() !== SettingsTarget.Testing && (
                <FormGroup>
                  <h4>{this.context.localization.currentLocale.OptionView.LABEL_CURRENT_CONFIG_TARGET}</h4>
                  <Col sm={10}>
                    <Input
                      type="select"
                      name="select"
                      value={this.state.currentConfig}
                      onChange={(e) => {
                        this.handleConfigOverrideChanged(+e.target.value);
                      }}
                    >
                      {Object.keys(SettingsTarget)
                        .filter((key) => !isNaN(Number(SettingsTarget[key])))
                        .map((it, ind) => (
                          <option key={ind} value={ind}>
                            {it}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </FormGroup>
              )}
              {this.context.appSettings.get().AppCanOverrideUrls && !ResourcePlatform.IsWebPlatform() && (
                <FormGroup>
                  <h3>{this.context.localization.currentLocale.OptionView.LABEL_URL_CONFIGURATIONS}</h3>
                  <h4>{this.context.localization.currentLocale.OptionView.LABEL_SERVER_OVERRIDE}</h4>
                  <Input
                    type="text"
                    name="text"
                    value={this.state.currentServerUrlOverride}
                    onChange={(e) => {
                      this.setState({ currentServerUrlOverride: e.target.value });
                    }}
                  />
                  <h4>{this.context.localization.currentLocale.OptionView.LABEL_LINKING_OVERRIDE}</h4>
                  <Input
                    type="text"
                    name="text"
                    value={this.state.currentLinkingSiteUrlOverride}
                    onChange={(e) => {
                      this.setState({ currentLinkingSiteUrlOverride: e.target.value });
                    }}
                  />
                  <Button
                    outline
                    color="info"
                    className="full-width"
                    style={{ marginTop: "5px" }}
                    onClick={() => {
                      this.handleOverrideUrlSubmit(this.state.currentServerUrlOverride, this.state.currentLinkingSiteUrlOverride);
                    }}
                  >
                    {this.context.localization.currentLocale.OptionView.LABEL_SAVE_OVERRIDES}
                  </Button>
                </FormGroup>
              )}
            </Form>
            <h3>{this.context.localization.currentLocale.OptionView.LABEL_ABOUT_SECTION}</h3>

            {userConfig !== undefined && userConfig.IsIAPEnabled && ResourcePlatform.ShouldShowStripeProducts() && (
              <Button
                outline
                color="info"
                className="full-width"
                onClick={() => {
                  this.optionsResource.openExternalWebsite(
                    ResourcePlatform.GetStripeStoreUrl(userConfig!, this.context.currentConfig.get()!) + "Login/Login" + this.props.activeLibrary
                  );
                  this.props.onDismiss();
                }}
              >
                {this.context.localization.currentLocale.Store.LABEL_STORE_PAGE_SUBSCRIPTIONS}
              </Button>
            )}
            {userConfig !== undefined && userConfig.IsIAPEnabled && !ResourcePlatform.ShouldShowStripeProducts() && ResourcePlatform.IsAppleIAP() && (
              <Button
                outline
                color="info"
                className="full-width"
                onClick={() => {
                  this.context.restorePurchases(this.props.activeLibrary);
                  this.props.onDismiss();
                }}
              >
                {this.context.localization.currentLocale.LibraryView.LABEL_RESTORE_PURCHASES}
              </Button>
            )}
            {userConfig &&
              JSON.parse(userConfig.InstanceInternationalizations[this.context.localization.currentCulture - 1].StringAboutJson).map(
                (i: string, index: number) => (
                  <Button
                    key={index}
                    outline
                    color="info"
                    className="full-width"
                    onClick={() => {
                      this.loadAboutPage(index);
                    }}
                  >
                    {i}
                  </Button>
                )
              )}
            <h3>{this.context.localization.currentLocale.OptionView.LABEL_MISC_SECTION}</h3>
            <Button
              outline
              color="info"
              className="full-width"
              onClick={() => {
                this.loadLivePage(userConfig!.InstanceInternationalizations[this.context.localization.currentCulture - 1].LicenseAgreementLink);
              }}
            >
              {this.context.localization.currentLocale.OptionView.LABEL_LICENSE_AGREEMENT}
            </Button>
            <Button
              outline
              color="info"
              className="full-width"
              onClick={() => {
                this.loadLivePage(userConfig!.InstanceInternationalizations[this.context.localization.currentCulture - 1].PrivacyPolicyLink);
              }}
            >
              {this.context.localization.currentLocale.OptionView.LABEL_PRIVACY_POLICY}
            </Button>
            <h5>{this.context.localization.currentLocale.OptionView.LABEL_APP_BUILD_NUMBER + this.context.appSettings.get().Build}</h5>
            <h5>{this.context.localization.currentLocale.OptionView.LABEL_DEVICE + this.context.appSettings.get().DeviceId}</h5>
            <h5>{this.context.appSettings.get().Version}</h5>
            {!ResourcePlatform.IsWebPlatform() && (
              <React.Fragment>
                <Button outline color="info" className="full-width" onClick={this.importOfflinePackage}>
                  {this.context.localization.currentLocale.OptionView.LABEL_IMPORT_OFFLINE_PACKAGE}
                </Button>
                <Button outline color="info" className="full-width" onClick={this.deleteOutdatedVersions}>
                  {this.context.localization.currentLocale.OptionView.LABEL_CLEAN_OUTDATED_VERSIONS}
                </Button>
                <Button disabled={this.state.sendLogsDisabled} outline color={btnColor} className="full-width" onClick={this.handleSendLogs}>
                  {this.context.localization.currentLocale.OptionView.LABEL_SEND_LOGS}
                </Button>
              </React.Fragment>
            )}
          </Loading>
        </div>
      );
    }
    let hidden = this.props.initialOpen ? "" : " modalHide";
    return (
      <div className={"flex-fill optionsModal" + hidden} onClick={this.dismiss}>
        <div
          className="optionsContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ActionIcon className="optionsCloseIcon" src={<Image.close />} onClick={this.dismiss} />
          <div className="contentContainer">{formContent}</div>
        </div>
      </div>
    );
  }
}

interface ILibraryCollectionViewProps {}

interface ILibraryCollectionViewState {
  activeTab: string;
  books: Book[];
  migrations: Migration[];
}
export class LibraryCollectionView extends React.Component<ILibraryCollectionViewProps, ILibraryCollectionViewState> {
  context: LibrarySession;
  static contextType = LibraryContext;

  constructor(props: any) {
    super(props);
    this.bookTabClicked = this.bookTabClicked.bind(this);
    this.bookSelectionChanged = this.bookSelectionChanged.bind(this);
    this.migrationSelectionChanged = this.migrationSelectionChanged.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.onCloseClicked = this.onCloseClicked.bind(this);
    this.onMigrationCloseClicked = this.onMigrationCloseClicked.bind(this);
    this.onBookVersionOpened = this.onBookVersionOpened.bind(this);
    this.onMigrationOpened = this.onMigrationOpened.bind(this);
    this.onMigrationCloseClicked = this.onMigrationCloseClicked.bind(this);
    this.onLibraryPanelRequested = this.onLibraryPanelRequested.bind(this);
    this.state = { activeTab: "library", books: new Array<Book>(), migrations: new Array<Migration>() };
  }

  bookTabClicked(id: string) {
    this.selectTab(id);
  }

  bookSelectionChanged(value: Book, event: EmitterEvent, id: number) {
    if (event === EmitterEvent.delete && this.state.activeTab === "book_" + id) {
      this.setState({
        activeTab: "library",
      });
    }
    if (event === EmitterEvent.deleteAll) {
      this.setState({
        activeTab: "library",
        books: new Array<Book>(),
      });
      return;
    }
    this.updateBooksAndMigrations();
  }
  migrationSelectionChanged(value: Migration, event: EmitterEvent, id: number) {
    if (event === EmitterEvent.delete && this.state.activeTab === "migration_" + id) {
      this.setState({
        activeTab: "library",
      });
    }
    if (event === EmitterEvent.deleteAll) {
      this.setState({
        activeTab: "library",
        migrations: new Array<Migration>(),
      });
      return;
    }
    this.updateBooksAndMigrations();
  }

  updateBooksAndMigrations() {
    this.setState({
      books: this.context.books.rows(),
      migrations: this.context.migrations.rows(),
    });
  }

  selectTab(id: string) {
    this.setState({
      activeTab: id,
    });
  }

  componentDidMount() {
    this.context.books.addListener(this.bookSelectionChanged);
    this.context.migrations.addListener(this.migrationSelectionChanged);
    this.updateBooksAndMigrations();
    this.selectTab(this.state.activeTab || "library");
    this.context.bookVersionOpened.on(this.onBookVersionOpened);
    this.context.migrationOpened.on(this.onMigrationOpened);
    this.context.libraryPanelRequested.on(this.onLibraryPanelRequested);
  }
  shouldComponentUpdate(): boolean {
    return !this.context.shouldLimitRendering;
  }

  onBookVersionOpened(versionId: number) {
    this.selectTab(`book_${versionId}`);
  }
  onMigrationOpened(versionId: number) {
    this.selectTab(`migration_${versionId}`);
  }
  onLibraryPanelRequested() {
    this.selectTab("library");
  }

  onCloseClicked(value: string) {
    this.context.closeBook({ VersionId: +value.replace("book_", ""), RemoveVersion: true });
  }
  onMigrationCloseClicked(value: string) {
    let set = this.context.migrations.get(+value.replace("migration_", ""))!;
    this.context.exitMigration({ MigrationSet: set.migrationSet }).then(() => {
      Messages.Notify.success(this.context.localization.currentLocale.Migration.LABEL_MIGRATION_EXITED);
    });
  }

  componentWillUnmount() {
    this.context.books.removeListener(this.bookSelectionChanged);
    this.context.migrations.removeListener(this.migrationSelectionChanged);
    this.context.bookVersionOpened.off(this.onBookVersionOpened);
  }

  render() {
    return (
      <div className="flex-fill d-flex flex-column">
        <LibraryBookList
          itemClicked={this.bookTabClicked}
          activeTab={this.state.activeTab}
          books={this.state.books}
          migrations={this.state.migrations}
          onCloseClicked={this.onCloseClicked}
          onMigrationCloseClicked={this.onMigrationCloseClicked}
          localization={this.context.localization}
        />
        <TabContent activeTab={this.state.activeTab} className="flex-fill d-flex flex-column">
          <TabPane tabId={`library`} className="flex-fill d-flex flex-column">
            <LibraryView />
          </TabPane>
          {this.state.books.map((book) => {
            book.shouldLimitRendering = this.context.shouldLimitRendering;
            book.isActiveTab = this.state.activeTab === `book_${book.id}`;
            return (
              <TabPane key={`book_${book.id}`} tabId={`book_${book.id}`} className="flex-fill d-flex flex-column">
                <BookView key={book.id} book={book} />
                {/* <div className="unbound-form-modal" /> */}
              </TabPane>
            );
          })}
          {this.state.migrations.map((migration) => (
            <TabPane
              key={`migration_${migration.migrationSet.VersionTo}`}
              tabId={`migration_${migration.migrationSet.VersionTo}`}
              className="flex-fill d-flex flex-column"
            >
              <MigrationView key={migration.migrationSet.VersionTo} migration={migration} />
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}

export interface ILibraryBookListProps {
  itemClicked: (id: string) => any;
  activeTab: string;
  books: Book[];
  migrations: Migration[];
  onCloseClicked: (id: string) => any;
  onMigrationCloseClicked: (id: string) => any;
  localization: Locale;
}

export class LibraryBookList extends React.Component<ILibraryBookListProps, IEmptyValue> {
  constructor(props: any) {
    super(props);
    this.state = { books: new Array<Book>() };
  }

  render(): any {
    return (
      <div className="bg-light">
        <Nav tabs className="nav-compact-tabs">
          <LibraryBookTab
            title={this.props.localization.currentLocale.LibraryView.LABEL_LIBRARYTITLE}
            value="library"
            onClick={this.props.itemClicked}
            active={this.props.activeTab === "library"}
            initialSpinner={false}
          >
            <strong>{this.props.localization.currentLocale.LibraryView.LABEL_LIBRARYTITLE}</strong>
          </LibraryBookTab>
          {this.props.books &&
            this.props.books.map((book) => (
              <LibraryBookTab
                key={`book_${book.id}`}
                value={`book_${book.id}`}
                onClick={this.props.itemClicked}
                active={this.props.activeTab === `book_${book.id}`}
                closable={true}
                onCloseClicked={this.props.onCloseClicked}
                loading={book.loading.loaded}
                initialSpinner={true}
                title={book.name}
              >
                {book.name}
              </LibraryBookTab>
            ))}
          {this.props.migrations &&
            this.props.migrations.map((migration) => (
              <LibraryBookTab
                key={`migration_${migration.migrationSet.VersionTo}`}
                value={`migration_${migration.migrationSet.VersionTo}`}
                onClick={this.props.itemClicked}
                active={this.props.activeTab === `migration_${migration.migrationSet.VersionTo}`}
                closable={true}
                onCloseClicked={this.props.onMigrationCloseClicked}
                initialSpinner={false}
                title={"Migration " + migration.migrationSet.VersionTo}
              >
                {"Migration " + migration.migrationSet.VersionTo}
              </LibraryBookTab>
            ))}
        </Nav>
      </div>
    );
  }
}

export interface ILibraryBookTabProps extends IGenericValue<string> {
  onClick: (id: string) => any;
  active: boolean;
  closable?: boolean;
  loading?: EventHandler<boolean>;
  initialSpinner: boolean;
  title: string;
  onCloseClicked?: (id: string) => any;
}
export interface ILibraryBookTabState {
  loaded: boolean;
}

export class LibraryBookTab extends React.Component<ILibraryBookTabProps, ILibraryBookTabState> {
  constructor(props: any) {
    super(props);
    this.onCloseButtonClicked = this.onCloseButtonClicked.bind(this);
    this.onTabClicked = this.onTabClicked.bind(this);
    this.onBookLoaded = this.onBookLoaded.bind(this);
    this.state = {
      loaded: !this.props.initialSpinner,
    };
  }
  onBookLoaded() {
    this.setState({
      loaded: true,
    });
  }
  componentDidMount() {
    if (this.props.loading) {
      this.props.loading.on(this.onBookLoaded);
    }
  }
  componentWillUnmount() {
    if (this.props.loading) {
      this.props.loading.off(this.onBookLoaded);
    }
  }
  onCloseButtonClicked(event: any) {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.onCloseClicked !== undefined) {
      this.props.onCloseClicked(this.props.value);
    }
  }
  onTabClicked(event: React.MouseEvent) {
    if (event.button === 1 || (event.target as HTMLElement).closest(".book-tab-close") !== null) {
      event.preventDefault();
      this.onCloseButtonClicked(event);
    } else {
      this.props.onClick(this.props.value);
    }
  }

  render() {
    let addedMargin;
    if (this.props.value !== "library" && !this.state.loaded) {
      addedMargin = { marginRight: "10px" };
    } else {
      addedMargin = { marginRight: "0px" };
    }
    return (
      <NavItem>
        <div className="book-tab-container" title={this.props.title}>
          <NavLink onMouseDown={this.onTabClicked} active={this.props.active}>
            {this.props.closable ? (
              <div
                className="book-tab-close"
                onClick={(e) => {
                  this.onCloseButtonClicked(e);
                }}
              >
                <Image.close />
              </div>
            ) : (
              ""
            )}
            <span style={addedMargin} className="book-tab-name">
              {this.props.children}
            </span>
            {this.props.value !== "library" && !this.state.loaded && <Spinner className="tab-spinner" />}
          </NavLink>
        </div>
      </NavItem>
    );
  }
}

enum AppNavBarActions {
  help,
  user,
  library,
}

interface IAppNavBarProps {
  onNavigation: (action: AppNavBarActions) => any;
  isExpanded?: boolean;
}
interface IAppNavBarState {
  libraries: LibrarySession[];
  selectedLibrary: string;
}

class AppNavBarInner extends React.Component<IAppNavBarProps, IAppNavBarState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.onLibraryChanged = this.onLibraryChanged.bind(this);
    this.update = this.update.bind(this);

    this.state = {
      libraries: new Array<LibrarySession>(),
      selectedLibrary: "",
    };
  }

  componentDidMount() {
    this.context.libraries.addListener(this.update);
    this.context.currentLibraryNotifier.addListener(this.update);
    this.update();
  }
  componentWillUnmount() {
    this.context.libraries.removeListener(this.update);
    this.context.currentLibraryNotifier.removeListener(this.update);
  }

  update() {
    if (this.context.currentLibraryNotifier.get()) {
      this.setState({
        libraries: this.context.libraries.rows(),
        selectedLibrary: this.context.currentLibraryNotifier.get().key,
      });
    }
  }

  onLibraryChanged(key: string) {
    this.context.selectLibrary(key);
    this.props.onNavigation(AppNavBarActions.library);
  }

  render() {
    const { isExpanded, onNavigation } = this.props;
    const { libraries, selectedLibrary } = this.state;

    if (isExpanded) {
      return (
        <ExpandedAppNavBar
          libraries={libraries}
          installedConfigs={this.context.installedConfigs}
          userConfigs={this.context.userConfigs.rows()}
          onLibraryChanged={this.onLibraryChanged}
          onNavigation={onNavigation}
          selectedLibrary={selectedLibrary}
          localization={this.context.localization}
          helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
          canAddLibraries={this.context.appSettings && this.context.appSettings.get().CanAddLibraries}
        />
      );
    } else {
      return (
        <CollapsedAppNavBar
          libraries={libraries}
          installedConfigs={this.context.installedConfigs}
          userConfigs={this.context.userConfigs.rows()}
          onLibraryChanged={this.onLibraryChanged}
          onNavigation={onNavigation}
          selectedLibrary={selectedLibrary}
          localization={this.context.localization}
          helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
          canAddLibraries={this.context.appSettings && this.context.appSettings.get().CanAddLibraries}
        />
      );
    }
  }
}

export const AppNavBar = withTheme<IAppNavBarProps>(AppNavBarInner);

interface IAppNavBarViewProps {
  libraries: LibrarySession[];
  userConfigs: LibraryConfig[];
  installedConfigs: LibraryConfig[];
  selectedLibrary: string;
  onLibraryChanged: (key: string) => any;
  onNavigation: (action: AppNavBarActions) => any;
  localization: Locale;
  helpEnabled: boolean;
  canAddLibraries: boolean;
}

export class CollapsedAppNavBar extends React.Component<IAppNavBarViewProps, unknown> {
  render() {
    return (
      <VerticalNavBar className="h-100 collapsed-navbar " level="first">
        <Nav vertical={true} className="scrollable flex-nowrap flex-shrink-1">
          {this.props.libraries
            .filter((item) => item.config.BrandKey !== Constants.getAddNewConstant())
            .map((item: LibrarySession) => (
              <LibraryNavItem
                key={item.key}
                libraryKey={item.key}
                name={item.name}
                icon={item.icon}
                onClick={this.props.onLibraryChanged}
                active={this.props.selectedLibrary === item.key}
              />
            ))}
          {this.props.canAddLibraries && (
            <SvgLibraryNavItem
              key={this.props.userConfigs.length + 1}
              libraryKey={Constants.getAddNewConstant()}
              name={this.props.localization.currentLocale.Application.LABEL_ADD_LIBRARY}
              icon={<Image.plus />}
              onClick={this.props.onLibraryChanged}
              active={this.props.selectedLibrary === Constants.getAddNewConstant()}
              classNames="add-library"
            />
          )}
        </Nav>

        <Nav vertical={true} className="flex-nowrap flex-shrink-0">
          <NavItem data-for="bottomnavToolbar" data-tip={this.props.localization.currentLocale.LoginView.LABEL_LOGOUT}>
            <ActionIcon onClick={() => this.props.onNavigation(AppNavBarActions.user)} src={<Image.user />} />
          </NavItem>
          <NavItem data-for="bottomnavToolbar" data-tip={this.props.localization.currentLocale.OptionView.LABEL_APP_SETTINGS}>
            <ActionIcon onClick={() => this.props.onNavigation(AppNavBarActions.help)} src={<Image.settings />} />
          </NavItem>
          {this.props.helpEnabled && <ReactTooltip id="bottomnavToolbar" place="right" type="info" effect="solid" className="primaryColoured" />}
        </Nav>
      </VerticalNavBar>
    );
  }
}

export class ExpandedAppNavBar extends React.Component<IAppNavBarViewProps, unknown> {
  render() {
    return (
      <VerticalNavBar className="h-100 " level="first">
        <Nav vertical={true} className="scrollable flex-nowrap flex-shrink-1 p-3">
          {this.props.libraries
            .filter((item) => item.config.BrandKey !== Constants.getAddNewConstant())
            .map((item: LibrarySession) => (
              <React.Fragment key={item.key}>
                <LibraryNavItem
                  libraryKey={item.key}
                  name={item.name}
                  icon={item.icon}
                  onClick={this.props.onLibraryChanged}
                  active={this.props.selectedLibrary === item.key}
                  displayName={true}
                />
                <Separator />
              </React.Fragment>
            ))}
          {this.props.canAddLibraries && (
            <React.Fragment>
              <SvgLibraryNavItem
                key={this.props.userConfigs.length + 1}
                libraryKey={Constants.getAddNewConstant()}
                name={this.props.localization.currentLocale.Application.LABEL_ADD_LIBRARY}
                icon={<Image.plus />}
                onClick={this.props.onLibraryChanged}
                active={this.props.selectedLibrary === Constants.getAddNewConstant()}
                classNames="add-library"
                displayName={true}
              />
              <Separator />
            </React.Fragment>
          )}
        </Nav>

        <Nav className="flex-nowrap flex-shrink-0 border-top">
          <NavItem data-for="navToolbar">
            <ActionIcon onClick={() => this.props.onNavigation(AppNavBarActions.user)} src={<Image.user />} />
          </NavItem>
          <NavItem data-for="navToolbar">
            <ActionIcon onClick={() => this.props.onNavigation(AppNavBarActions.help)} src={<Image.settings />} />
          </NavItem>
        </Nav>
      </VerticalNavBar>
    );
  }
}

interface ILibraryNavItemProps {
  libraryKey: string;
  name: string;
  icon: string | JSX.Element;
  onClick: (key: string) => any;
  active?: boolean;
  displayName?: boolean;
  classNames?: string;
}

export class LibraryNavItem extends React.Component<ILibraryNavItemProps, unknown> {
  render() {
    const { active, displayName, name, icon, libraryKey, onClick } = this.props;

    return (
      <NavItem className="d-flex flex-row align-items-center mx-0 clickable" onClick={() => onClick(libraryKey)}>
        <div
          className={`library-selection-icon ${active ? "active" : ""}`}
          title={name}
          style={{
            backgroundImage: "url(data:image/png;base64," + icon + ")",
          }}
        />

        {displayName && (
          <div className="library-name-container">
            <p className="my-0 ml-3 nowrap flex-fill">{name}</p>
          </div>
        )}
      </NavItem>
    );
  }
}
export class SvgLibraryNavItem extends React.Component<ILibraryNavItemProps, unknown> {
  render() {
    const { active, displayName, name, icon, libraryKey, onClick } = this.props;

    return (
      <NavItem className="d-flex flex-row align-items-center mx-0 clickable" onClick={() => onClick(libraryKey)}>
        <div className={`library-selection-icon ${active ? "active" : ""} ${this.props.classNames ? this.props.classNames : ""}`} title={name}>
          {icon}
        </div>

        {displayName && (
          <div className="library-name-container">
            <p className="my-0 ml-3 nowrap flex-fill">{name}</p>
          </div>
        )}
      </NavItem>
    );
  }
}
