import 'react-mde/lib/styles/css/react-mde-all.css';

import classnames from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import ReactMde, { Command } from 'react-mde';
import { CommandMap, ToolbarCommands } from 'react-mde/lib/definitions/types';
import ReactTooltip from 'react-tooltip';
import {
    Badge, Collapse, DropdownToggle, Form, FormFeedback, FormGroup, Label, Nav, Navbar, NavItem,
    NavLink, UncontrolledDropdown
} from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps, NavigationInitiator } from 'src/models/Book';
import { ContentPopupModel, ContentSegment, NavigationRequest } from 'src/models/Content';
import { IGetUCPreviewR } from 'src/models/dto/UCRequest';
import { Resource } from 'src/models/Resource';
import { ActionResult } from 'src/models/Result';
import {
    Annotation, AnnotationGrouping, AnnotationSorting, AnnotationType
} from 'src/models/UserContent';
import { Status } from 'src/network/Requests';
import { Wire } from 'src/network/Wire';
import { AnnotationPrint } from 'src/utilities/AnnotationPrint';
import { Binding, EmitterEvent } from 'src/utilities/Events';
import { FormState } from 'src/utilities/FormState';
import { Convert, ResourcePlatform } from 'src/utilities/Helpers';
import { PrintBox } from 'src/utilities/Printbox';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, AnnotationTypeComponent, ConcreteContentSegment, Expander, ExpanderClose,
    FilteringItem, FilteringMenu, FormValue, Icon, ItemCard, PreviewHandler, SortDropdownItem,
    SortDropdownMenu, Switch
} from '../foundation/Controls';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import { PrintModalControl } from '../foundation/PrintModalControl';
import { SelectionHandler } from '../foundation/Selection';
import {
    Action, ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { StandaloneCogniflowFrameContainer } from '../foundation/StandaloneCogniflowFrame';
import { BookContext } from '../state/Contextes';
import { IModelBinding } from '../state/Generics';

const Gfm = require("remark-gfm");
const ReactMarkdown = require("react-markdown");
const content_css = require("!!raw-loader!src/assets/css/content.css").default;
const content_jquery = require("!!raw-loader!jquery").default;
const content_api = require("!!raw-loader!src/assets/js/ContentAPI.js").default;
const content_mathJax = require("!!raw-loader!src/assets/js/tex-mml-svg.js").default;
const content_place = require("!!raw-loader!src/assets/js/Place.js").default;
const printStyle_css = require("!!raw-loader!src/assets/css/annotationPrint.css").default;
export interface IAnnotationViewState {
  formOpen: boolean;
  currentAnnotation: Annotation | null;
  sorting: AnnotationSorting;
  grouping: AnnotationGrouping;
  folderViewOpen: boolean;
  selectAnnotation: boolean;
  reversedSort: boolean;
  folderFilterCount: false | number;
  Annotations: Annotation[][];
  selectedAnnotationIDs: number[];
  selectedAnnotation: number | null;
  currentFilter: number[] | null;
  viewReady: boolean;
}
interface IAnnotationViewProps {
  isActive: boolean;
}
export class AnnotationView extends React.Component<IAnnotationViewProps, IAnnotationViewState> {
  context: Book;
  static contextType = BookContext;
  private formRef = React.createRef<AnnotationForm>();

  cogniflow = React.createRef<StandaloneCogniflowContainer>();

  constructor(props: any) {
    super(props);
    // this.onAnnotationClicked = this.onAnnotationClicked.bind(this);
    this.state = {
      formOpen: false,
      currentAnnotation: null,
      sorting: AnnotationSorting.Recent,
      reversedSort: true,
      folderViewOpen: false,
      selectAnnotation: false,
      grouping: AnnotationGrouping.Type,
      folderFilterCount: false,
      Annotations: [],
      selectedAnnotationIDs: [],
      selectedAnnotation: null,
      currentFilter: null,
      viewReady: false,
    };

    this.onAnnotationClicked = this.onAnnotationClicked.bind(this);
    this.onAnnotationContentClick = this.onAnnotationContentClick.bind(this);
    this.onFormToolbarAction = this.onFormToolbarAction.bind(this);
    this.onFolderViewToggle = this.onFolderViewToggle.bind(this);
    this.onCloseFolderView = this.onCloseFolderView.bind(this);
    this.onFolderFilterChanged = this.onFolderFilterChanged.bind(this);
    this.onGroupingChanged = this.onGroupingChanged.bind(this);
    this.sortAnnotations = this.sortAnnotations.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onReady = this.onReady.bind(this);
    this.checkChanges = this.checkChanges.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.buildAnnotationItem = this.buildAnnotationItem.bind(this);
    this.update = this.update.bind(this);
    this.groupSelectionClicked = this.groupSelectionClicked.bind(this);
  }
  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.annotations, this);
    this.context.annotations.addListener(this.update);
    await this.context.initAnnotations();
    this.setState({ viewReady: true }, () => {
      this.update();
      this.context.loading.setLoaded(BookLoadingSteps.annotations);
    });
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.annotations, this.onReady);
    this.context.annotationContentClicked.on(this.onAnnotationContentClick);
    this.setState({
      sorting: this.context.appSettings.get().AnnotationSorting,
      reversedSort: this.context.appSettings.get().AnnotationSortingReversed,
      grouping: this.context.appSettings.get().AnnotationGrouping,
    });
  }

  componentWillUnmount() {
    this.context.annotations.removeListener(this.update);
    this.context.annotationContentClicked.off(this.onAnnotationContentClick);
  }
  onAnnotationContentClick(ids: number[]) {
    if (ids.length > 1) {
      return;
    }
    let id = ids[0];
    let anno = this.context.annotations.get(id);
    if (anno) {
      this.readAnnotation(anno);
    }
  }

  sortAnnotations(annos: Annotation[]): Annotation[] {
    if (this.state.sorting === AnnotationSorting.Note) {
      annos = annos.sort((x1, x2) => {
        if (x1.Note > x2.Note) {
          return 1;
        }
        if (x1.Note < x2.Note) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === AnnotationSorting.Recent) {
      annos = annos.sort((x1, x2) => {
        if (x1.LastUpdate > x2.LastUpdate) {
          return 1;
        }
        if (x1.LastUpdate < x2.LastUpdate) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === AnnotationSorting.ReadingOrder) {
      annos = annos.sort((x1, x2) => {
        if (x1.Ranges[0].Spine === x2.Ranges[0].Spine) {
          if (x1.Ranges[0].StartTermIndex > x2.Ranges[0].StartTermIndex) {
            return 1;
          }
          if (x1.Ranges[0].StartTermIndex < x2.Ranges[0].StartTermIndex) {
            return -1;
          }
          return 0;
        }

        if (x1.Ranges[0].Spine > x2.Ranges[0].Spine) {
          return 1;
        }
        if (x1.Ranges[0].Spine < x2.Ranges[0].Spine) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.reversedSort) {
      annos = annos.reverse();
    }
    return annos;
  }

  update(affectedItem?: Annotation, event?: EmitterEvent) {
    if (event === EmitterEvent.delete || (event === EmitterEvent.action && affectedItem === undefined)) {
      this.setState({ currentAnnotation: null });
      this.closeAnnotationForm();
    }
    if (event === EmitterEvent.update) {
      /* this.closeAnnotationForm(); */
    }

    let newFilter = this.state.currentFilter;
    // If the edited item is no longer in the filter, it's because it was changed to a type not in filter.
    // In order to avoid it disappearing, add the new type in the filter automatically.
    if (affectedItem && newFilter !== null && newFilter.indexOf(affectedItem.AnnotationTypeId) === -1) {
      newFilter = newFilter.concat([affectedItem.AnnotationTypeId]);
      this.setState({ currentFilter: newFilter });
    }

    let finalAnnos: Annotation[][] = [];
    let validTypes: number[] = [];
    if (newFilter === null) {
      validTypes = this.context.annotationTypes.rows().map((x) => x.Id);
    } else {
      newFilter.map((item) => {
        if (this.context.annotations.rows().some((it) => it.AnnotationTypeId === +item)) {
          validTypes.push(+item);
        }
      });
    }
    // All the annos, sorted by typeId.
    let holder: Annotation[] = this.context.annotations
      .rows()
      .filter((it) => validTypes.indexOf(it.AnnotationTypeId) > -1 && this.context.annotationTypes.exists(it.AnnotationTypeId))
      .sort((x1, x2) => {
        if (x1.AnnotationTypeId > x2.AnnotationTypeId) {
          return 1;
        }
        if (x1.AnnotationTypeId < x2.AnnotationTypeId) {
          return -1;
        }
        return 0;
      });

    // If there's no items in the collection don't bother doing anything.
    if (holder.length === 0) {
      this.setState({ Annotations: finalAnnos });
      return;
    }

    // If we're grouping the faves, we'll separate them here so the render can render grouped lists.
    // Sub groups will also be sorted by their current sort param.
    if (this.state.grouping === AnnotationGrouping.Type) {
      let currentFolder: number = holder[0].AnnotationTypeId;
      let group: Annotation[] = [];
      for (let i = 0; i < holder.length; i++) {
        if (holder[i].AnnotationTypeId === currentFolder) {
          group.push(holder[i]);
        } else {
          group = this.sortAnnotations(group);
          finalAnnos.push(group);
          group = [];
          group.push(holder[i]);
          currentFolder = holder[i].AnnotationTypeId;
        }
      }
      group = this.sortAnnotations(group);
      finalAnnos.push(group);
    } else {
      holder = this.sortAnnotations(holder);
      // Set indices and first/last
      let count = 0;
      holder.map((x) => {
        x.Index = count;
        count++;
        x.IsFirst = false;
        x.IsLast = false;
      });
      holder[0].IsFirst = true;
      holder[holder.length - 1].IsLast = true;
      finalAnnos.push(holder);
    }

    this.setState({ Annotations: finalAnnos }, () => {
      // Avoid reload spam when the type is deleted.
      if (event === EmitterEvent.action && affectedItem !== undefined) {
        return;
      }
      if (this.cogniflow.current) {
        this.cogniflow.current.reloadCogniflow();
      }
    });
  }

  onAnnotationClicked(annotation: Annotation, action: AnnotationItemAction) {
    if (this.state.selectAnnotation === true) {
      // add Id of Annotation to list of selectedAnnotationIDs
      let selectedAnnoList = this.state.selectedAnnotationIDs; // current state variable
      if (!selectedAnnoList?.includes(annotation.Id)) {
        selectedAnnoList?.push(annotation.Id); // add ID
        this.setState({ selectedAnnotationIDs: selectedAnnoList });
      } else {
        let filteredList = selectedAnnoList?.filter((i) => i !== annotation.Id);
        this.setState({ selectedAnnotationIDs: filteredList });
      }
      if (this.cogniflow.current) {
        this.cogniflow.current.updateNodes();
      }
    } else {
      switch (action) {
        case AnnotationItemAction.item:
          this.setState({ selectedAnnotation: annotation.Id });
          this.context.contentNavigation(NavigationRequest.toAnnotation(annotation.Id), NavigationInitiator.annotation);
          break;
        case AnnotationItemAction.showMore:
          this.readAnnotation(annotation);
          break;
      }
    }
  }

  async readAnnotation(annotation: Annotation) {
    await this.checkChanges();
    this.setState({
      formOpen: true,
      currentAnnotation: annotation,
    });
  }

  async checkChanges() {
    if (this.formRef.current && this.formRef.current.state.edited && this.formRef.current.state.form.formValid()) {
      let result = await Messages.Dialog.confirm(
        this.context.localization.currentLocale.AnnotationView.ALERT_ANNOTATIONSAVE_PROMPT,
        this.context.localization.currentLocale.AnnotationView.ALERT_ANNOTATIONSAVE_HEADING
      );
      if (result === "true") {
        await this.formRef.current.submit(undefined);
      } else {
        this.formRef.current.setState({ edited: false });
      }
    }
  }

  onFormToolbarAction(action: AnnotationFormActions) {
    switch (action) {
      case AnnotationFormActions.back:
        this.closeAnnotationForm();
        break;
      case AnnotationFormActions.save:
        if (this.formRef.current != null) {
          if (action === AnnotationFormActions.save && this.formRef.current.valid()) {
            this.formRef.current.submit(undefined);
          }
        }
        break;
      case AnnotationFormActions.delete:
        if (this.formRef.current != null) {
          this.formRef.current.deleteAnnotation();
        }
        break;
      case AnnotationFormActions.goto:
        if (this.formRef.current != null) {
          if (this.state.currentAnnotation && this.state.currentAnnotation !== null) {
            this.onAnnotationClicked(this.state.currentAnnotation, AnnotationItemAction.item);
          }
        }
        break;
    }
  }

  onFolderViewToggle() {
    this.setState({
      folderViewOpen: !this.state.folderViewOpen,
    });
  }
  onSelectAnnotationViewToggle = () => {
    this.setState({
      selectAnnotation: !this.state.selectAnnotation,
      selectedAnnotationIDs: [],
    });
  };
  onClearSelectedAnnotations = () => {
    this.setState({
      selectedAnnotationIDs: [],
    });
  };
  onPrintSelectedAnnotations = async () => {
    let annos: JSX.Element[] = [];
    if (this.state.selectedAnnotationIDs.length === 0) {
      Messages.Notify.error(this.context.localization.currentLocale.AnnotationView.LABEL_ONE_OR_MORE_ANNOTATIONS);
    } else {
      let previewResult = await this.context.requestAnnotationPrintPreviews({
        AnnotationIDs: this.state.selectedAnnotationIDs,
      });
      if (!previewResult.valid()) {
        Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
        return;
      }
      previewResult.data.FormattedAnnotations.map((anno) => {
        annos.push(<AnnotationPrint model={anno} context={this.context} />);
      });
      const div = document.createElement("div");

      ReactDOM.render(
        <PrintBox headScript="" headStyle={printStyle_css}>
          {annos}
        </PrintBox>,
        div
      );

      let result = await this.context.printAnnotations({
        AnnotationIDs: this.state.selectedAnnotationIDs,
        FormattedPrintPayload: div.innerHTML,
      });
      if (result.valid() && !Convert.isEmptyOrSpaces(result.data.RawContent)) {
        // Do native call for print on web
        const win = ResourcePlatform.openNewTabWithContent(result.data.RawContent, "Print Selection", false);
        // Adding a timeout to let the ContentView window react to the tab opening properly.
        // Without this, the whole browser freezes while the print preview is opened.
        if (win) {
          setTimeout(() => win.print(), 1000);
        }
      } else if (!result.valid()) {
        Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
      }
    }
  };

  onDeleteSelectedAnnotations = async () => {
    if (this.state.selectedAnnotationIDs.length === 0) {
      Messages.Notify.error(this.context.localization.currentLocale.AnnotationView.LABEL_ONE_OR_MORE_ANNOTATIONS);
    } else {
      let resp = await Messages.Dialog.confirm(this.context.localization.currentLocale.AnnotationView.DIALOG_DELETE_SELECTED);
      if (resp === "true") {
        let response = await this.context.deleteAnnotations({
          AnnotationIDs: this.state.selectedAnnotationIDs,
        });
        if (response.valid()) {
          this.setState({ selectAnnotation: false, selectedAnnotationIDs: [] });
          Messages.Notify.success(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_DELETED);
        } else {
          Messages.Notify.error(this.context.localization.currentLocale.AnnotationView.ERROR_COULD_NOT_DELETE_SELECTED);
        }
      }
    }
  };

  onCloseFolderView() {
    this.setState({
      folderViewOpen: false,
    });
  }

  onFolderFilterChanged(filter: { [key: string]: boolean } | null) {
    let selectedFolders = 0;
    let totalCount = 0;
    let newFilter: number[] = [];
    for (let item in filter) {
      if (filter.hasOwnProperty(item) && this.context.annotations.rows().some((it) => it.AnnotationTypeId === +item)) {
        totalCount++;
        if (filter[item] === true) {
          selectedFolders++;
          newFilter.push(+item);
        }
      }
    }

    this.setState(
      {
        folderFilterCount: selectedFolders >= totalCount ? false : selectedFolders,
        currentFilter: newFilter,
      },
      this.update
    );
  }

  onGroupingChanged(grouping: AnnotationGrouping) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), AnnotationGrouping: grouping });
    this.setState(
      {
        grouping,
      },
      this.update
    );
  }

  onSortingChanged(sort: AnnotationSorting, reversed: boolean) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), AnnotationSorting: sort, AnnotationSortingReversed: reversed });
    this.setState(
      {
        sorting: sort,
        reversedSort: reversed,
      },
      this.update
    );
  }

  async closeAnnotationForm() {
    await this.checkChanges();
    this.setState({
      formOpen: false,
    });
  }
  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let message = request;
      let items: Annotation[] = [];
      if (message.Batches[0].Action === Action.append) {
        let nextId = message.Batches[0].AnchorMainId + 1;
        items = this.state.Annotations[0].slice(nextId, nextId + message.Batches[0].BatchSize);
      }
      if (message.Batches[0].Action === Action.prepend) {
        items = this.state.Annotations[0].slice(
          message.Batches[0].AnchorMainId - message.Batches[0].BatchSize < 0 ? 0 : message.Batches[0].AnchorMainId - message.Batches[0].BatchSize,
          message.Batches[0].AnchorMainId
        );
      }
      message.Batches[0].Nodes = [];
      message.Batches[0].Nodes = items;
      resolve(message);
    });
  }
  private initializeFlow(anchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let result: Annotation[] = this.state.Annotations[0].slice(0, 80);
      if (anchor) {
        result = this.state.Annotations[0].slice(anchor, anchor + 80);
      }
      resolve({
        nodes: result,
        targetSpine: result.length > 0 ? result[0].Index! : 0,
      });
    });
  }
  private buildAnnotationItem(node: INode): any {
    let item = node as Annotation;
    let annoType = this.context.annotationTypes.get(item.AnnotationTypeId)!;
    if (!annoType) {
      return undefined;
    }
    return (
      <AnnotationItem
        description={annoType.Value}
        lastUpdate={item.LastUpdate}
        type={annoType}
        key={item.Id}
        item={item}
        onClick={this.onAnnotationClicked}
        language={this.context.appSettings.get().LanguageOverride}
        selected={this.state.selectedAnnotationIDs.includes(item.Id)}
      />
    );
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-anno-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-anno-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    batchSize: 20,
  };
  navigationDone() {}
  groupSelectionClicked(items: Annotation[]) {
    let groupIDs = items.map((groupItem) => groupItem.Id);
    const filteredArray = groupIDs.filter((value) => this.state.selectedAnnotationIDs.includes(value));
    let newSet = this.state.selectedAnnotationIDs;
    if (filteredArray.length > 0) {
      newSet = newSet.filter((it) => !groupIDs.includes(it));
    } else {
      newSet = this.state.selectedAnnotationIDs.concat(groupIDs);
    }
    this.setState({ selectedAnnotationIDs: newSet });
  }
  render() {
    // Don't render if the view isn't ready, or if there are annotations but no types.
    if (!this.state.viewReady) {
      return "";
    }
    let lists: JSX.Element;
    // More than one grouping means the
    if (this.state.grouping === AnnotationGrouping.Type && this.state.Annotations.length > 0) {
      let groupHolder = [];
      for (let i = 0; i < this.state.Annotations.length; i++) {
        groupHolder.push(
          <GroupedAnnotationList
            type={this.context.annotationTypes.get(this.state.Annotations[i][0].AnnotationTypeId)!}
            key={this.state.Annotations[i]![0].AnnotationTypeId}
            items={this.state.Annotations[i]!}
            onItemClicked={this.onAnnotationClicked}
            language={this.context.appSettings.get().LanguageOverride}
            selectedAnnotationIDs={this.state.selectedAnnotationIDs}
            groupSelectionClicked={this.groupSelectionClicked}
            selectionModeOn={this.state.selectAnnotation}
            selectedAnnotationID={this.state.selectedAnnotation!}
          />
        );
      }
      lists = (
        <div className="d-flex flex-column full-height full-width">
          <div className="flex-fill scrollable">{groupHolder}</div>
        </div>
      );
    } else if (this.state.Annotations.length > 0) {
      lists = (
        <StandaloneCogniflowContainer
          provider={this.flowProvider}
          builder={this.buildAnnotationItem}
          initialize={this.initializeFlow}
          extraSettings={this.settings}
          ref={this.cogniflow}
          key={0}
        />
      );
    } else {
      lists = (
        <span key={0} className="noUcItems">
          {this.context.localization.currentLocale.AnnotationView.LABEL_NO_ANNOTATIONS}
        </span>
      );
    }
    return (
      <div className="annotation-view full-height d-flex flex-column position-relative">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <AnnotationToolbar
                  folderFilterCount={this.state.folderFilterCount}
                  onFolderViewToggle={this.onFolderViewToggle}
                  onSelectAnnotationViewToggle={this.onSelectAnnotationViewToggle}
                  onClearSelectedAnnotations={this.onClearSelectedAnnotations}
                  onPrintSelectedAnnotations={this.onPrintSelectedAnnotations}
                  onDeleteSelectedAnnotations={this.onDeleteSelectedAnnotations}
                  onSortingChange={this.onSortingChanged}
                  folderViewOpen={this.state.folderViewOpen}
                  selectAnnotation={this.state.selectAnnotation}
                  localization={this.context.localization}
                  initialReversed={this.state.reversedSort}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  initialSort={this.state.sorting}
                />
                <Expander isOpen={this.state.folderViewOpen} className="bg-light border-top border-bottom">
                  <AnnotationFolderView
                    onFolderFilterChanged={this.onFolderFilterChanged}
                    onGroupingChanged={this.onGroupingChanged}
                    grouping={this.state.grouping}
                  />
                </Expander>
                {lists}
              </React.Fragment>
            ),
            right: (
              <React.Fragment>
                <AnnotationFormToolbar
                  onActionClicked={this.onFormToolbarAction}
                  key={this.state.currentAnnotation && this.state.currentAnnotation !== null ? this.state.currentAnnotation.Id : -1}
                  model={
                    this.state.currentAnnotation && this.state.currentAnnotation !== null
                      ? this.context.annotations.bind(this.state.currentAnnotation)
                      : new Binding(undefined)
                  }
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                />
                <div className="flex-fill scrollable listContainer">
                  <AnnotationForm
                    ref={this.formRef}
                    model={this.state.currentAnnotation ? this.context.annotations.bind(this.state.currentAnnotation) : new Binding(undefined)}
                  />
                </div>
              </React.Fragment>
            ),
          }}
        </RightFlip>
      </div>
    );
  }
}

interface IAnnotationFolderViewProps {
  grouping: AnnotationGrouping;
  onFolderFilterChanged: (filter: { [key: string]: any }) => any;
  onGroupingChanged: (grouping: AnnotationGrouping) => any;
}

interface IAnnotationFolderViewState {
  folders: AnnotationType[];
}
class AnnotationFolderView extends React.Component<IAnnotationFolderViewProps, IAnnotationFolderViewState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: any) {
    super(props);
    this.state = { folders: new Array<AnnotationType>() };
    this.onFolderGroupingSwitchChanged = this.onFolderGroupingSwitchChanged.bind(this);
    this.updateFolders = this.updateFolders.bind(this);
  }

  componentDidMount() {
    this.context.annotationTypes.addListener(this.updateFolders);

    this.updateFolders();
  }

  componentWillUnmount() {
    this.context.annotationTypes.removeListener(this.updateFolders);
  }

  updateFolders() {
    this.setState({
      folders: this.context.annotationTypes.rows(),
    });
  }

  onFolderGroupingSwitchChanged(event: any) {
    this.props.onGroupingChanged(event.target.checked ? AnnotationGrouping.Type : AnnotationGrouping.None);
  }

  render() {
    return (
      <div>
        <div className="p-3">
          <label>{this.context.localization.currentLocale.AnnotationView.LABEL_GROUPANNOTATIONS}</label>
          <Switch on={this.props.grouping === AnnotationGrouping.Type} onChange={this.onFolderGroupingSwitchChanged} className="ml-3" />
          <FilteringMenu onFilterChanged={this.props.onFolderFilterChanged}>
            {this.context.annotationTypes &&
              this.context.annotationTypes
                .rows()
                .filter((ty) => this.context.annotations.rows().some((it) => it.AnnotationTypeId === ty.Id))
                .map((it) => (
                  <FilteringItem key={it.Id} value={it.Id}>
                    <AnnotationTypeComponent color={it.Color} name={it.Value} />
                  </FilteringItem>
                ))}
          </FilteringMenu>
        </div>
      </div>
    );
  }
}

interface IAnnotationToolbarState {}

interface IAnnotationToolbarProps {
  onFolderViewToggle: () => any;
  onSelectAnnotationViewToggle: () => any;
  onClearSelectedAnnotations: () => any;
  onPrintSelectedAnnotations: () => any;
  onDeleteSelectedAnnotations: () => any;
  onSortingChange: (sort: AnnotationSorting, reversed: boolean) => any;
  folderViewOpen: boolean;
  selectAnnotation: boolean;
  folderFilterCount: false | number;
  localization: Locale;
  helpEnabled: boolean;
  initialReversed: boolean;
  initialSort: AnnotationSorting;
}
class AnnotationToolbar extends React.Component<IAnnotationToolbarProps, IAnnotationToolbarState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let filterColor = this.props.folderFilterCount === 0 ? "danger" : "primary";
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle caret color="link" data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}>
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initial={this.props.initialSort} initialReversed={this.props.initialReversed} onSortSelected={this.props.onSortingChange}>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationSorting.Recent}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_DATE}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationSorting.ReadingOrder}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_READING_ORDER}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationSorting.Note}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_NOTE}
                </SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_TOGGLE_FOLDER}>
              <ActionIcon onClick={this.props.onFolderViewToggle} src={<Image.folder />} active={this.props.folderViewOpen} />
            </NavItem>
            <NavItem>
              {this.props.folderFilterCount !== false && (
                <Badge color={filterColor}>
                  <Icon src={<Image.filter />} /> {this.props.folderFilterCount}{" "}
                </Badge>
              )}
            </NavItem>
            {
              <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_PRINT_SHARE}>
                <ActionIcon src={<Image.select2 />} active={this.props.selectAnnotation} onClick={this.props.onSelectAnnotationViewToggle} />
              </NavItem>
            }
            {this.props.helpEnabled && <ReactTooltip id="annotationToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
          </Nav>
        </Collapse>

        <Nav navbar={true}>
          <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_CLEAR_SELECTION}>
            {this.props.selectAnnotation === true && <ActionIcon src={<Image.stack_cancel />} onClick={this.props.onClearSelectedAnnotations} />}
          </NavItem>
          {ResourcePlatform.CanPrint() && (
            <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_PRINT_ANNOTATIONS}>
              {this.props.selectAnnotation === true && <ActionIcon src={<Image.print />} onClick={this.props.onPrintSelectedAnnotations} />}
            </NavItem>
          )}
          <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_DELETE_SELECTED}>
            {this.props.selectAnnotation === true && <ActionIcon src={<Image.delete_item />} onClick={this.props.onDeleteSelectedAnnotations} />}
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

enum AnnotationFormActions {
  back,
  delete,
  save,
  goto,
}

interface IAnnotationFormToolbarProps extends IModelBinding<Annotation | undefined> {
  onActionClicked: (action: AnnotationFormActions) => any;
  localization: Locale;
  helpEnabled: boolean;
}

interface IAnnotationFormToolbarState {
  actionsEnabled: boolean;
}
class AnnotationFormToolbar extends React.Component<IAnnotationFormToolbarProps, IAnnotationFormToolbarState> {
  constructor(props: any) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = { actionsEnabled: false };
  }

  onModelUpdate() {
    if (this.props.model.current && this.props.model.current !== null) {
      this.setState({
        actionsEnabled: true,
      });
    } else {
      this.setState({
        actionsEnabled: false,
      });
    }
  }

  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.Application.LABEL_BACK}>
              <NavLink className="flip-back" onClick={() => this.props.onActionClicked(AnnotationFormActions.back)}>
                <Icon src={<Image.arrowback />} /> <small>{this.props.localization.currentLocale.AnnotationView.LABEL_ANNOTATIONVIEW}</small>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav navbar={true}>
          <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_GOTO}>
            <ActionIcon src={<Image.target />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(AnnotationFormActions.goto)} />
          </NavItem>
          <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.Application.LABEL_DELETE}>
            <ActionIcon
              src={<Image.delete_item />}
              enabled={this.state.actionsEnabled}
              onClick={() => this.props.onActionClicked(AnnotationFormActions.delete)}
            />
          </NavItem>
          <NavItem data-for="annotationToolbar" data-tip={this.props.localization.currentLocale.Application.ALERT_SAVE}>
            <ActionIcon src={<Image.save />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(AnnotationFormActions.save)} />
          </NavItem>
          {this.props.helpEnabled && <ReactTooltip id="annotationToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
        </Nav>
      </Navbar>
    );
  }
}

export interface IAnnotationListProps {
  items: Annotation[];
  onItemClicked: (item: Annotation, action: AnnotationItemAction) => any;
  selectedAnnotationIDs: number[];
}
interface IAnnotationGroupedListProps {
  items: Annotation[];
  type: AnnotationType;
  language: Languages;
  onItemClicked: (item: Annotation, action: AnnotationItemAction) => any;
  selectedAnnotationIDs: number[];
  selectedAnnotationID: number;
  selectionModeOn: boolean;
  groupSelectionClicked: (items: Annotation[]) => void;
}

export class AnnotationList extends React.Component<IAnnotationListProps, unknown> {
  context: Book;
  static contextType = BookContext;

  render() {
    return (
      <div>
        {this.props.items &&
          this.props.items.map((item: Annotation) => {
            let annoType = this.context.annotationTypes.get(item.AnnotationTypeId);
            if (!annoType) {
              return "";
            }
            return (
              <AnnotationItem
                description={annoType.Value}
                lastUpdate={item.LastUpdate}
                type={annoType}
                key={item.Id}
                item={item}
                onClick={this.props.onItemClicked}
                language={this.context.appSettings.get().LanguageOverride}
                selected={this.props.selectedAnnotationIDs.includes(item.Id)}
              />
            );
          })}
      </div>
    );
  }
}

export interface IGroupedAnnotationListState {
  isOpen: boolean;
}
class GroupedAnnotationList extends React.Component<IAnnotationGroupedListProps, IGroupedAnnotationListState> {
  constructor(props: IAnnotationGroupedListProps) {
    super(props);
    this.onOpenToggle = this.onOpenToggle.bind(this);
    this.conditionalOnOpenToggle = this.conditionalOnOpenToggle.bind(this);
    this.state = { isOpen: false };
  }

  onOpenToggle(e: React.MouseEvent) {
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }
  conditionalOnOpenToggle(e: React.MouseEvent) {
    if (this.props.selectionModeOn) {
      this.props.groupSelectionClicked(this.props.items);
    } else {
      this.onOpenToggle(e);
    }
  }

  render() {
    if (!this.props.type) {
      return null;
    }

    let groupIDs = this.props.items.map((groupItem) => groupItem.Id);
    let allSelected = "";
    if (groupIDs.filter((value) => this.props.selectedAnnotationIDs.includes(value)).length === this.props.items.length) {
      allSelected = "allSelected";
    }

    return (
      <div className={classnames("grouped-container", allSelected)}>
        <div className="d-flex align-items-center justify-content-between" onClick={this.conditionalOnOpenToggle}>
          <AnnotationTypeComponent color={this.props.type.Color} name={this.props.type.Value} />
          <ExpanderClose isOpen={this.state.isOpen} onClick={this.onOpenToggle} />
        </div>
        <Expander isOpen={this.state.isOpen}>
          {this.props.items &&
            this.state.isOpen &&
            this.props.items.map((item: Annotation) => (
              <AnnotationItem
                lastUpdate={item.LastUpdate}
                type={this.props.type}
                key={item.Id}
                item={item}
                onClick={this.props.onItemClicked}
                language={this.props.language}
                selected={this.props.selectedAnnotationIDs.includes(item.Id) || (this.props.selectedAnnotationID === item.Id)}
              />
            ))}
        </Expander>
      </div>
    );
  }
}

interface IAnnotationItemProps {
  item: Annotation;
  type: AnnotationType;
  description?: string;
  lastUpdate: any;
  language: Languages;
  onClick: (item: Annotation, action: AnnotationItemAction) => any;
  selected?: boolean;
}

enum AnnotationItemAction {
  item,
  showMore,
}
class AnnotationItem extends React.Component<IAnnotationItemProps, unknown> {
  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onShowMoreClick = this.onShowMoreClick.bind(this);
  }
  // will have to add a function to switch "selected" prop of an item to true onItemClick (if SelectAnnotation Button is on)
  private onClick() {
    this.props.onClick(this.props.item, AnnotationItemAction.item);
  }

  private onShowMoreClick() {
    this.props.onClick(this.props.item, AnnotationItemAction.showMore);
  }

  render() {
    let something = this.props.description ? (
      <AnnotationTypeComponent color={this.props.type.Color} name={this.props.description} />
    ) : (
      <span>{Convert.dateToFormattedString(this.props.lastUpdate, this.props.language)}</span>
    );
    let note: string | undefined = this.props.item.Note;
    const MAX_WORDS = 20;
    if (Convert.isEmptyOrSpaces(note) && this.props.description) {
      if (this.props.description) {
        note = Convert.dateToFormattedString(this.props.lastUpdate, this.props.language);
      } else {
        note = undefined;
      }
    } else if (note.split(" ").length > MAX_WORDS) {
      let interm = note.replace(/\n/g, "");
      note = interm.replace(/(([^\s]+\s\s*){20})(.*)/, "$1…");
    }
    return (
      <ItemCard onClick={this.onClick} showMore={true} onShowMoreClick={this.onShowMoreClick} selected={this.props.selected}>
        {{
          title: (
            <span>
              <Icon src={<Image.annotations />} className="text-muted valign-text-bottom" /> {this.props.item.Preview}
            </span>
          ),
          subtitle: note,
          description: something,
        }}
      </ItemCard>
    );
  }
}

interface IAnnotationFormProps extends IModelBinding<Annotation | undefined> {}

interface IAnnotationFormState {
  form: FormState;
  edited: boolean;
  dropdownOpen: boolean;
  markdownSelectedTab?: "write" | "preview" | undefined;
}
class AnnotationForm extends React.Component<IAnnotationFormProps, IAnnotationFormState> {
  context: Book;
  content: PreviewHandler;
  selection: SelectionHandler;

  currentPopup: ContentSegment | undefined;
  previewRef: React.RefObject<StandaloneCogniflowFrameContainer>;
  private printModal = React.createRef<PrintModalControl>();

  noteResource: Resource;
  static contextType = BookContext;
  constructor(props: any) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.deleteAnnotation = this.deleteAnnotation.bind(this);
    this.onDropdownSelection = this.onDropdownSelection.bind(this);
    this.buildPopup = this.buildPopup.bind(this);
    this.extendedGenerateSegment = this.extendedGenerateSegment.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.scrollToPreview = this.scrollToPreview.bind(this);
    this.insertedSegmentCallback = this.insertedSegmentCallback.bind(this);
    this.markdownLinkOverride = this.markdownLinkOverride.bind(this);
    this.executeURL = this.executeURL.bind(this);
    this.showMarkdownInfo = this.showMarkdownInfo.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
    let tableCommand: Command = {
      icon: () => (
        <div title="Insert 3x3 table" className="customTableInsert">
          <Image.table />
        </div>
      ),
      execute: (opts) => {
        opts.textApi.replaceSelection(
          "| First Header  | Second Header | Third Header |\n| ------------- | ------------- | -------------|\n| Content Cell  | Content Cell | Content Cell |\n| Content Cell  | Content Cell  | Content Cell |\n"
        );
      },
    };
    let toolbar: ToolbarCommands = JSON.parse(JSON.stringify(ReactMde.defaultProps.toolbarCommands!));
    let commands: CommandMap = JSON.parse(JSON.stringify(ReactMde.defaultProps.commands!));
    toolbar.push(["insert-table"]);
    commands = { ...{ "insert-table": tableCommand } };

    this.customToolbar = toolbar;
    this.customCommands = commands;
    this.previewRef = React.createRef<StandaloneCogniflowFrameContainer>();
    let form = new FormState({
      id: 0,
      note: "",
      type: 0,
      lastUpdate: new Date(),
      preview: "",
      ranges: [],
    });
    this.submit = this.submit.bind(this);
    let initialTab: "write" | "preview" | undefined = "preview";
    if (this.props.model.current) {
      if (Convert.isEmptyOrSpaces(this.props.model.current.Note)) {
        initialTab = "write";
      } else {
        initialTab = "preview";
      }
    } else {
      initialTab = "write";
    }
    this.state = {
      form,
      edited: false,
      dropdownOpen: false,
      markdownSelectedTab: initialTab,
    };
  }

  setSelectedTab(newVal: "write" | "preview" | undefined) {
    this.setState({ markdownSelectedTab: newVal });
  }

  onModelUpdate() {
    if (this.props.model.current) {
      const annotation = this.props.model.current;
      let initialTab: "write" | "preview" | undefined = "preview";
      if (annotation) {
        if (Convert.isEmptyOrSpaces(annotation.Note)) {
          initialTab = "write";
        } else {
          initialTab = "preview";
        }
      } else {
        initialTab = "write";
      }
      this.setState({
        form: this.state.form.update({
          id: annotation.Id,
          note: annotation.Note,
          type: annotation.AnnotationTypeId,
          lastUpdate: Convert.dateToFormattedString(annotation.LastUpdate, this.context.appSettings.get().LanguageOverride),
          preview: annotation.Preview,
          ranges: annotation.Ranges,
        }),
        edited: false,
        markdownSelectedTab: initialTab,
      });
      // this.previewRef.current!.reload();
    } else {
      this.setState({
        form: this.state.form.reset(),
        edited: false,
      });
    }
  }

  handleInput(event: any) {
    this.setState({
      form: this.state.form.update({ note: event }),
      edited: true,
    });
  }
  customToolbar: ToolbarCommands | undefined = undefined;
  customCommands: CommandMap | undefined = undefined;
  componentDidMount() {
    this.noteResource = new Resource(Wire.shield(this.context.wire), -1);
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  componentDidUpdate() {
    if (this.props.model.current && this.state.form.values.id !== this.props.model.current.Id) {
      this.props.model.off(this.onModelUpdate);
      this.props.model.on(this.onModelUpdate);
      if (this.props.model.current) {
        this.previewRef.current!.flow()!.reloadCogniflow();
      }
    }
  }

  async submit(event?: any) {
    if (event) {
      event.preventDefault();
    }

    let form = this.state.form;
    if (this.state.edited) {
      if (form.formValid()) {
        let result = await this.context.updateAnnotation({
          Id: form.values.id,
          AnnotationTypeId: form.values.type,
          Note: form.values.note,
        });
        if (result.valid()) {
          Messages.Notify.success(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_UPDATED);
          this.setState({ /* form: this.state.form.reset(), */ edited: false, markdownSelectedTab: "preview" });
          this.previewRef.current!.flow()!.reloadCogniflow();
        } else {
          Messages.Notify.error(result.errors[0].Message);
        }
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATIONERROR);
      }
    }
  }
  async deleteAnnotation(): Promise<boolean> {
    if (
      (await Messages.Dialog.confirm(
        this.context.localization.currentLocale.AnnotationView.ALERT_ANNOTATIONDELETE_PROMPT,
        this.context.localization.currentLocale.AnnotationView.ALERT_ANNOTATIONDELETE_HEADING
      )) === "true"
    ) {
      let form = this.state.form;
      let id = form.values.id;
      let result = await this.context.deleteAnnotation({
        Id: id,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATION_DELETED);
        this.setState({ form: this.state.form.reset(), edited: false, markdownSelectedTab: "preview" });
        return true;
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.AnnotationView.LABEL_ANNOTATIONERROR);
      }
    }
    return false;
  }
  private async handlePrintSelection() {
    let sel = this.selection.getSelectionObject();
    let selFrag = this.selection.getSelectionContent();
    if (selFrag === undefined) {
      return;
    }
    let res = await this.context.requestPrintSelection({
      FirstDocId: sel.firstDocId,
      FirstOffset: sel.firstWordCount,
      LastOffset: sel.secondWordCount,
      SecondDocId: sel.secondDocId,
    });
    if (res && res.valid() && res.data && res.data != null && res.data.RequestApproved) {
      let rawHtml = "";
      let hasAnnos = selFrag.querySelector("span[data-anno]") !== null;
      for (let i = 0; i < selFrag.childNodes.length; i++) {
        if ((selFrag.childNodes[i] as any).outerHTML !== undefined) {
          rawHtml += (selFrag.childNodes[i] as any).outerHTML;
        } else if ((selFrag.childNodes[i] as any).textContent !== undefined) {
          rawHtml += (selFrag.childNodes[i] as any).textContent;
        }
      }
      this.printModal.current!.printSelection(
        sel,
        (this.content.cogniflow.getNodeBySecondary(sel.firstDocId) as ContentSegment).SpineId,
        (this.content.cogniflow.getNodeBySecondary(sel.secondDocId) as ContentSegment).SpineId,
        rawHtml,
        hasAnnos
      );
    } else {
      setTimeout(() => {
        if (res.errors && res.errors.length > 0 && res.status === Status.AuthorizationFailed) {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_AUTHFAILED);
        } else if (res.errors && res.errors.length > 0 && res.status === Status.BadRequest) {
          Messages.Notify.error(this.context.localization.currentLocale.PrintView.PRINT_FAILED_BADREQUEST);
        } else if (res.valid() && !res.data.RequestApproved) {
          Messages.Notify.error(
            Convert.formatString(this.context.localization.currentLocale.PrintView.PRINT_REFUSED, [res.data.SelectedWords, res.data.MaxWords])
          );
        }
      }, 300);
    }
  }

  valid(): boolean {
    return this.state.form.formValid();
  }

  onDropdownSelection(eventKey: any) {
    let form = this.state.form;
    this.setState({
      form: form.change({ name: "type", type: "", value: +eventKey }),
      edited: true,
    });
  }

  private async flowProvider(): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let response: IResponse = { Batches: [] };
      resolve(response);
    });
  }
  private async initializeFlow(anchor?: number, searchQuery?: string, recurse?: boolean): Promise<{ nodes: any[]; targetSpine: number }> {
    if (!this.props.model.current) {
      return new Promise<{ nodes: any[]; targetSpine: number }>(async () => {});
    }

    if (!this.content || recurse) {
      this.content = this.content = new PreviewHandler(
        this.previewRef.current!.flow()!,
        this.previewRef.current!.document()!,
        this.context,
        this.props.model.current
      );
      if (this.content.apiServer.isInitialized() === true) {
        this.previewRef.current!.loadBodyScripts();
      } else {
        return this.delay(100).then(async () => this.initializeFlow(anchor, "", true));
      }
    }
    return new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result: ActionResult<IGetUCPreviewR> = await this.context.getUCPreview({
        AnnotationId: this.props.model.current ? this.props.model.current.Id : -1,
        FavouriteId: -1,
      });
      if (result.valid()) {
        this.content.updateInstance(
          this.previewRef.current!.flow()!,
          this.previewRef.current!.document()!,
          result.data.PreviewPopup,
          result.data.PreviewPopupParent,
          this.context,
          this.props.model.current
        );
        resolve({
          nodes: result.data.Preview,
          targetSpine: result.data.Preview[0].SpineId,
        });
      } else {
        reject();
      }
    });
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  private extendedGenerateSegment(node: ContentSegment, attributes: any, key: number): JSX.Element {
    return (
      <ConcreteContentSegment
        classes={attributes["className"]}
        headAttr={this.settings.segmentDataDescriptor.secondaryIdDataAttribute}
        head={attributes[this.settings.segmentDataDescriptor.secondaryIdDataAttribute]}
        spineAttr={this.settings.segmentDataDescriptor.mainIdDataAttribute}
        spine={attributes[this.settings.segmentDataDescriptor.mainIdDataAttribute]}
        key={key}
        originalModel={this.props.model.current}
        item={node}
        segmentAdded={this.content.apiServer ? this.content.apiServer.triggerSegmentAdded : () => {}}
      />
    );
  }
  private insertedSegmentCallback() {
    this.scrollToPreview();
  }
  private scrollToPreview() {
    this.content.adjustFontSize();
    setTimeout(() => {
      this.content.scrollToPreview();
    }, 100);
    if (this.currentPopup) {
      this.content.showPopup(this.currentPopup);
      this.currentPopup = undefined;
    }
  }
  markdownLinkClicked() {
    return "webMedia";
  }
  markdownLinkOverride(e: React.MouseEvent, book: Book) {
    e.preventDefault();
    e.stopPropagation();
    let sender = e.target as HTMLAnchorElement;
    if (!sender.getAttribute("href")) {
      return;
    }
    let hrefValue: string = sender.getAttribute("href")!.trim();
    if (hrefValue === "url") {
      hrefValue = sender.innerText;
    }
    let finalLink = Convert.DeepLinkUrlToNative(hrefValue);
    if (finalLink !== null) {
      book.reportExternalLink({ Url: finalLink });
    } else {
      book.openExternalWebsite(hrefValue, hrefValue);
    }

    // if ((e.target as HTMLAnchorElement).target === "webMedia") {
    //   if (this.noteResource) {
    //     this.noteResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
    //   }
    // }
    // if (((e.target as HTMLElement).parentElement as HTMLAnchorElement).target === "webMedia") {
    //   if (this.noteResource) {
    //     this.noteResource.openExternalWebsite(((e.target as HTMLElement).parentElement as HTMLAnchorElement).href);
    //   }
    // }
  }
  executeURL(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.noteResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
  }
  showMarkdownInfo() {
    Messages.Dialog.build({
      buttons: Messages.Dialog.Buttons.Ok,
      content: <span onClick={this.executeURL}>{this.context.localization.currentLocale.AnnotationView.LABEL_MARKDOWN_NOTE + " "}</span>,
      title: "Markdown",
    }).create();
  }
  settings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "SpineId",
      mainIdDataAttribute: "data-cogniflow--spine",
      secondaryIdDataAttribute: "data-cogniflow--headid",
      secondaryIdNodeAttribute: "HeadId",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "ContentSegment",
      applyDirectlyToSegment: true,
    },
    segmentContainerClasses: "html body",
    scrollingClasses: "BookView tex2jax_ignore",
    batchSize: 3,
  };
  buildPopup(pop: ContentPopupModel) {
    return this.content.buildPopup(pop);
  }

  render() {
    let form = this.state.form;
    let selectedType = this.context.annotationTypes.get(this.state.form.values.type);

    let allTypes: AnnotationType[] = [];
    if (selectedType === undefined) {
      selectedType = { Color: "#000000", Value: "null", Id: 0, LastUpdate: new Date() };
    } else {
      allTypes = this.context.annotationTypes
        .rows()
        .filter((item) => item.Id !== selectedType!.Id)
        .slice(0, 200)
        .sort((a, b) => new Date(b.LastUpdate).getTime() - new Date(a.LastUpdate).getTime());
    }
    if (this.context.style !== undefined && this.context.javascript !== undefined) {
      return (
        <Form
          className="p-2 form-condensed"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup>
            <Label>{this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}</Label>
            <div className="dropdownContainer">
              <Dropdown onSelect={this.onDropdownSelection}>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <AnnotationTypeComponent color={selectedType.Color} name={selectedType.Value} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {allTypes.map((item) => (
                    <Dropdown.Item eventKey={item.Id.toString()} key={item.Id} as="div">
                      <AnnotationTypeComponent color={item.Color} name={item.Value} />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="note">
              {this.context.localization.currentLocale.AnnotationView.LABEL_NOTE}
              <ActionIcon className={"markdownInfo"} src={<Image.info />} onClick={this.showMarkdownInfo} />
            </Label>
            <div className="container noteEditContainer">
              <ReactMde
                toolbarCommands={this.customToolbar}
                commands={this.customCommands}
                value={form.values.note}
                onChange={this.handleInput}
                selectedTab={this.state.markdownSelectedTab}
                onTabChange={this.setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(
                    <dl onClick={(e) => this.markdownLinkOverride(e, this.context)}>
                      <React.Fragment>
                        <dd className="markdownContainer">
                          <ReactMarkdown plugins={[Gfm]} linkTarget={this.markdownLinkClicked}>
                            {markdown}
                          </ReactMarkdown>
                        </dd>
                      </React.Fragment>
                    </dl>
                  )
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1,
                  },
                }}
              />
            </div>
            <FormFeedback tooltip={true}>{form.getErrors("note")}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{this.context.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}</Label>
            <FormValue>{form.values.lastUpdate && form.values.lastUpdate.toString()}</FormValue>
          </FormGroup>
          <FormGroup className={"form-group form-group-grow"}>
            <Label>{this.context.localization.currentLocale.AnnotationView.LABEL_PREVIEW}</Label>
            <StandaloneCogniflowFrameContainer
              ref={this.previewRef}
              className="content-reader"
              provider={this.flowProvider}
              initialize={this.initializeFlow}
              builder={this.extendedGenerateSegment}
              addonBuilder={this.buildPopup}
              segmentsInsertedCallback={this.insertedSegmentCallback}
              navigationDoneCallback={this.scrollToPreview}
              extraSettings={this.settings}
              head={
                <React.Fragment>
                  <style>{content_css}</style>
                  <style>{this.context.style}</style>
                </React.Fragment>
              }
              scripts={[
                {
                  src: content_jquery,
                  head: true,
                },
                {
                  src: content_place,
                  head: true,
                },
                {
                  src: content_api,
                  head: true,
                },
                {
                  src: this.context.javascript,
                  head: false,
                },
                { src: content_mathJax, head: true },
              ]}
            />
          </FormGroup>
        </Form>
      );
    } else {
      return "";
    }
  }
}
