import * as React from 'react';
import { Button } from 'reactstrap';
import { LibrarySession } from 'src/models/AppSession';
import { LibraryProductCell, UnifiedProduct } from 'src/models/Library';
import { Resource } from 'src/models/Resource';
import { LibraryContext } from 'src/ui/state/Contextes';
import { ResourcePlatform } from 'src/utilities/Helpers';

import { Elements } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';

import { SplitForm } from './SplitForm';

export interface IPurchaseSummaryProps {
  initiatePurchase: (method: PaymentMethod) => void;
  initiateApplePurchase: () => void;
  cancelPurchase: () => void;
  product: LibraryProductCell;
  selectedPlan: UnifiedProduct;
  defaultSplash: string;
  moreInfoResource: Resource;
}
export interface IPurchaseSummaryState {}
export class PurchaseSummary extends React.PureComponent<IPurchaseSummaryProps, IPurchaseSummaryState> {
  context: LibrarySession;
  static contextType = LibraryContext;
  constructor(props: IPurchaseSummaryProps) {
    super(props);
  }
  markdownLinkClicked() {
    return "webMedia";
  }
  markdownLinkOverride(e: React.MouseEvent) {
    if ((e.target as HTMLAnchorElement).target === "webMedia") {
      e.preventDefault();
      e.stopPropagation();
      if (this.props.moreInfoResource) {
        this.props.moreInfoResource.openExternalWebsite((e.target as HTMLAnchorElement).href);
      }
    }
  }
  render() {
    return (
      <div className="purchaseContainer">
        <h1>{this.context.localization.currentLocale.Store.LABEL_YOUR_ORDER}</h1>
        <div className="purchaseSummary">
          <Button close onClick={this.props.cancelPurchase} className="pl-3 pb-1 closeBtn" />
          <div className="orderDetails" style={ResourcePlatform.IsAppleIAP() ? { width: "100%" } : {}}>
            <div className="headers">
              <div
                className="cover"
                style={{
                  backgroundImage: `url(data:image/png;base64,${
                    !this.props.product.ImageThumbnail || this.props.product.ImageThumbnail === null || this.props.product.ImageThumbnail.length === 0
                      ? this.props.defaultSplash
                      : String(this.props.product.ImageThumbnail)
                  })`,
                }}
              />
              <h1 className="prodName">{this.props.product.ProductName}</h1>
            </div>
            <h3 className="prodSub">{this.props.selectedPlan.Title + " (" + this.props.selectedPlan.FormattedPrice + ")"}</h3>
            <br />
            <p className="planDesc">{this.props.selectedPlan.Description}</p>
            {ResourcePlatform.toMarkdown(this.props.product.ProductDescription, this.markdownLinkOverride, this.markdownLinkClicked, "Description")}
            {ResourcePlatform.IsAppleIAP() && (
              <Button onClick={this.props.initiateApplePurchase} style={{ marginTop: "15px" }} color="primary" outline block>
                {this.context.localization.currentLocale.Store.LABEL_SUBSCRIBE}
              </Button>
            )}
          </div>
          {!ResourcePlatform.IsAppleIAP() && (
            <div className="payBox">
              <div className="floatingWidget">
                <h2>{this.context.localization.currentLocale.Store.LABEL_PAYMENT_DETAILS}</h2>
                <Elements stripe={this.context.stripe}>
                  <SplitForm
                    localization={this.context.localization}
                    initiatePurchase={this.props.initiatePurchase}
                    purchaseCancelled={this.props.cancelPurchase}
                  />
                </Elements>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
