import { INavigationRequest } from './dto/ContentRequest';

// Content start
export class ContentSegment {
  Content: string;
  SpineId: number;
  HeadId: number;
  IsFirst: boolean;
  IsLast: boolean;
  Favourites: ContentFavourite[];
  Annotations: ContentNote[];
}

export class ContentPopupModel {
  index: number;
  popupSegment: ContentSegment;
}

export class ContentFavourite {
  Id: number;
  YDocHeight: number;
  YOffset: number;
}

export class ContentNote {
  Id: number;
  IsHit: boolean;
}

export class Range {
  StartHeadId: number;
  EndHeadId: number;
  StartTermIndex: number;
  EndTermIndex: number;
}
// Content end

// TOC start
export class TocNode {
  Current: ExtendedHeader;
  Children: TocNode[];
  Depth: number;
}

export class FlattenTocNode {
  Current: ExtendedHeader;
  Children: ExtendedHeader[];
  Depth: number;
}

export class ExtendedHeader {
  Id: number;
  SpineId: number;
  ChildCount: number;
  ChildrenHitCount: number;
  HitCount: number;
  IsHit: boolean;
  Parent: number;
  Rank: number;
  Score: number;
  Value: string;
}
// TOC end

// Search start
export class SearchHeader {
  Index: number;
  ItemId: number;
  Relevancy: number;
  HitTermCount: number;
  IsFirst: boolean;
  IsLast: boolean;
  Path: string;
  Rank: number;
  Title: string;
  MoreCount: number;
  Excerpts: ContentExcerpt[];
  HeaderType: SearchHeaderType;
}
export enum SearchHeaderType{
  ContentResult,
  Tip,
  Bulletin
}
export class ContentExcerpt {
  Content: string;
  IsHighlight: boolean;
}
export class FormattedSearchField {
  Name: string;
  FormattedPrefix: string;
}

export class FormattedSearchForm {
  Name: string;
  Resolution: string;
  FormInputs: SearchFormInput[];
  FormFilter: FormFilter;
}

export class FormFilter {
  DefaultFilter: Set<number>;
  FilterDescription: string;
  FilterOptions: FilterListItem[];
}

export class FilterListItem {
  Filter: Set<number>;
  Label: string;
  IsSelected: boolean;
}

export class SearchFormInput {
  Name: string;
  Prefix: string;
  Suffix: string;
  Query: QueryCommand;
  CommandPrefix: string;
  ListType: ListType;
  ListItems: ListItem[];
}

export class ListItem {
  Value: string;
  Label: string;
  IsSelected: boolean;
}

export enum QueryCommand {
  Annotation,
  Highlight,
  Note,
  OProx,
  UProx,
  Field,
  AutoTerm,
}
export enum ListType {
  Multi,
  Radio,
}
export enum HitDirection {
  Undefined = 0,
  PreviousHit = 1,
  NextHit = 2,
  FirstHit = 3,
  LastHit = 4,
  FirstHitInSegment = 5,
  LastHitInSegment = 6,
}

export enum SearchSorting {
  Ranked,
  ReadingOrder,
}
export enum SearchViewMode {
  Expanded,
  Collapsed,
}

// Search end

// Resource begin
export enum ResourceType {
  UNKNOWN = 0,
  CSS = 1,
  JAVASCRIPT = 2,
  BOOKCONTENT = 3,
  JPG = 4,
  GIF = 5,
  PNG = 6,
  PDF = 7,
  MP3 = 8,
  MP4 = 9,
  DOC = 10,
  HTML = 11,
  JSONSearchList = 12,
  JSONAboutList = 13,
  JSONSearchForm = 14,
  XLS = 15,
  PLAINTEXT = 16,
  INT = 17,
  SVG = 18,
  ZIP = 19
}
// Resource end

export enum NavigationRequestType {
  unspecified = 0,
  toc,
  header,
  annotation,
  favourite,
  hit,
  relativeOffset,
  selector,
  range,
  popup,
  tip
}

export class NavigationRequest implements INavigationRequest {
  Type: NavigationRequestType;
  HeadId: number;
  AnnoId: number;
  HitDirection: number;
  FavId: number;
  RelativeOffset: number;
  ContentSelector: string;
  NavigationRange: Range;
  IsReplay: boolean;
  Tip: number;

  static toHeader(headId: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.header;
    request.HeadId = headId;
    return request;
  }

  static toAnnotation(annotationId: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.annotation;
    request.AnnoId = annotationId;
    return request;
  }

  static toFavourite(favouriteId: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.favourite;
    request.FavId = favouriteId;
    return request;
  }

  static toTip(tipId: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.tip;
    request.Tip = tipId;
    return request;
  }

  static toRelativeOffset(headId: number, offset: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.relativeOffset;
    request.HeadId = headId;
    request.RelativeOffset = offset;
    return request;
  }

  static toSelector(headId: number, selector: string) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.selector;
    request.HeadId = headId;
    request.ContentSelector = selector;
    return request;
  }

  static toRange(headId: number, startTerm: number, endHead: number, endTerm: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.range;
    request.HeadId = headId;
    request.NavigationRange = new Range();
    request.NavigationRange.StartHeadId = headId;
    request.NavigationRange.StartTermIndex = startTerm;
    request.NavigationRange.EndHeadId = endHead;
    request.NavigationRange.EndTermIndex = endTerm;
    return request;
  }

  static toHit(headId: number, hitDirection: number) {
    let request = new NavigationRequest();
    request.Type = NavigationRequestType.hit;
    request.HeadId = headId;
    request.HitDirection = hitDirection;
    return request;
  }
}
