import * as _ from 'underscore';

interface IKeyValuePair<K, V> {
  key: K;
  value: V;
}

export interface IDictionary<K, V> extends Iterable<IKeyValuePair<K, V>> {
  count(): number;
  get(key: K): V | undefined;
  containsKey(key: K): boolean;
  clear(): void;
  values(): V[];
  keys(): K[];
  iterator(): IterableIterator<IKeyValuePair<K, V>>;
}

export class Dictionary<K, V> implements IDictionary<K, V> {
  protected table: { [key: string]: IKeyValuePair<K, V> };
  private toStr: (key: K) => string;
  private _keys: K[];
  public length: number;

  private static defaultToString(item: any): string {
    if (item === null) {
      return "$COLLECTION_NULL";
    } else if (_.isUndefined(item)) {
      return "$COLLECTION_UNDEFINED";
    } else if (_.isString(item)) {
      return "$$s" + item;
    } else {
      return "$$o" + item.toString();
    }
  }

  constructor(initial?: Array<IKeyValuePair<K, V>>, toStr?: (key: K) => string) {
    this.toStr = toStr || Dictionary.defaultToString;
    this.table = {};
    this.length = 0;
    this._keys = new Array<K>();
    if (initial) {
      for (const pair of initial) {
        this.set(pair.key, pair.value);
      }
    }
  }

  count() {
    return this.length;
  }

  set(key: K, value: V): V | undefined {
    let stringifiedKey: string = this.toStr(key);
    let ret: V | undefined;
    if (_.has(this.table, stringifiedKey)) {
      ret = this.table[stringifiedKey].value;
    } else {
      this._keys.push(key);
      this.length++;
    }
    this.table[stringifiedKey] = {
      key,
      value,
    };
    return ret;
  }

  get(key: K): V | undefined {
    let stringifiedKey: string = this.toStr(key);
    if (_.has(this.table, stringifiedKey)) {
      return this.table[stringifiedKey].value;
    }
    return undefined;
  }

  remove(key: K): V | undefined {
    let stringifiedKey: string = this.toStr(key);

    if (_.has(this.table, stringifiedKey)) {
      let ret: V = this.table[stringifiedKey].value;
      delete this.table[stringifiedKey];

      let keyIndex: number = _.map(this._keys, (k) => this.toStr(k)).indexOf(stringifiedKey);
      if (keyIndex !== -1) {
        this._keys.splice(keyIndex, 1);
      }
      this.length--;
      return ret;
    }

    return undefined;
  }

  containsKey(key: K): boolean {
    return _.has(this.table, this.toStr(key));
  }

  clear() {
    this.table = {};
    this._keys = [];
    this.length = 0;
  }

  values(): V[] {
    return _.map(_.values(this.table), (pair) => pair.value);
  }

  keys(): K[] {
    return this._keys;
  }

  iterator() {
    return Object.values(this.table)[Symbol.iterator]();
  }

  [Symbol.iterator]() {
    return this.iterator();
  }
}

export class ImmutableDictionary<K, V> implements IDictionary<K, V> {
  protected table: { [key: string]: IKeyValuePair<K, V> };

  /**
   * Keeping an array representation of our paired rows to prevent unnecessary
   * recomputing of this array.
   */
  private _values: V[];
  private toStr: (key: K) => string;

  private static defaultToString(item: any): string {
    if (item === null) {
      return "$COLLECTION_NULL";
    } else if (_.isUndefined(item)) {
      return "$COLLECTION_UNDEFINED";
    } else if (_.isString(item)) {
      return "$$s" + item;
    } else {
      return "$$o" + item.toString();
    }
  }

  constructor(initial?: { [key: string]: IKeyValuePair<K, V> }, toStr?: (key: K) => string) {
    this.toStr = toStr || ImmutableDictionary.defaultToString;
    this.table = initial ? initial : {};
    this._values = Object.values(this.table).map((pair) => pair.value);
  }

  count(): number {
    return this._values.length;
  }

  set(key: K, value: V): ImmutableDictionary<K, V> {
    const stringifiedKey = this.toStr(key);
    const updatedTable = { ...this.table };
    updatedTable[stringifiedKey] = { key, value };

    return new ImmutableDictionary(updatedTable, this.toStr);
  }

  setAll(items: Array<[K, V]>): ImmutableDictionary<K, V> {
    const updatedTable = { ...this.table };
    for (const item of items) {
      updatedTable[this.toStr(item[0])] = { key: item[0], value: item[1] };
    }

    return new ImmutableDictionary<K, V>(updatedTable, this.toStr);
  }

  get(key: K): V | undefined {
    const stringifiedKey = this.toStr(key);
    const pair = this.table[stringifiedKey];
    return pair ? pair.value : undefined;
  }

  remove(key: K): ImmutableDictionary<K, V> {
    const stringifiedKey = this.toStr(key);
    const pairToRemove = this.table[stringifiedKey];

    if (pairToRemove) {
      const { ...others } = this.table;
      this.table = others;
      return new ImmutableDictionary(others, this.toStr);
    }

    return this;
  }

  containsKey(key: K): boolean {
    const stringifiedKey = this.toStr(key);
    return this.table[stringifiedKey] !== undefined;
  }

  clear(): ImmutableDictionary<K, V> {
    return new ImmutableDictionary();
  }

  values(): V[] {
    return this._values;
  }

  keys(): K[] {
    return Object.values(this.table).map((pair) => pair.key);
  }

  iterator() {
    return Object.values(this.table)[Symbol.iterator]();
  }

  [Symbol.iterator]() {
    return this.iterator();
  }
}
