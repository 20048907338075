import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Collapse, DropdownToggle, Nav, Navbar, NavItem, UncontrolledDropdown } from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps, NavigationInitiator } from 'src/models/Book';
import { NavigationRequest } from 'src/models/Content';
import { ActionResult } from 'src/models/Result';
import { Action, HistoryType } from 'src/models/UserContent';
import { ActionItemUtils } from 'src/utilities/ActionItem';
import { Convert } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import { ActionIcon, Icon, SelectDropdownItem, SelectDropdownMenu } from '../foundation/Controls';
import * as Messages from '../foundation/Messages';
import {
    ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { BookContext } from '../state/Contextes';

interface IActionViewProps {
  isActive: boolean;
}
interface IActionViewState {
  currentScope: HistoryType;
  refreshCount: number;
  viewReady: boolean;
}

export class ActionView extends React.Component<IActionViewProps, IActionViewState> {
  context: Book;
  static contextType = BookContext;

  constructor(props: any) {
    super(props);
    this.state = { currentScope: HistoryType.Unspecified, refreshCount: 0, viewReady: false };
    this.onScopeChanged = this.onScopeChanged.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
    this.onSearchActionClick = this.onSearchActionClick.bind(this);
    this.onExternalWebsiteActionClick = this.onExternalWebsiteActionClick.bind(this);
    this.onResourceActionClicked = this.onResourceActionClicked.bind(this);
    this.onReady = this.onReady.bind(this);
  }
  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.actions, this);
    await this.context.initActions();
    this.context.loading.setLoaded(BookLoadingSteps.actions);
    this.setState({ viewReady: true });
    this.setState({
      currentScope: this.context.appSettings.get().HistoryFilter,
    });
  }

  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.actions, this.onReady);
    this.context.historyPanelRequested.on(this.onScopeChanged);
  }

  componentWillUnmount() {
    this.context.loading.stepLoading.off(BookLoadingSteps.actions, this.onReady);
    this.context.historyPanelRequested.off(this.onScopeChanged);
  }
  shouldComponentUpdate(nextProps: any): boolean {
    return nextProps.isActive;
  }

  private onScopeChanged(scope: HistoryType) {
    if (this.state.currentScope !== scope) {
      this.setState({
        currentScope: scope,
      });
      this.context.appSettings.set({ ...this.context.appSettings.get(), HistoryFilter: scope });
    }
  }

  private onActionClick(action: Action) {
    if (action.Type === HistoryType.ExternalWebsite) {
      this.onExternalWebsiteActionClick(action);
    } else if (action.Type === HistoryType.Search) {
      this.onSearchActionClick(action);
    } else if (action.Type === HistoryType.ResourceViewing) {
      this.onResourceActionClicked(action);
    } else {
      this.context.contentNavigation(NavigationRequest.toHeader(action.ObjectId), NavigationInitiator.actionItem);
    }
  }

  private onResourceActionClicked(action: Action) {
    this.context.resources.openResource(action.ObjectId.toString());
  }

  private onSearchActionClick(action: Action) {
    // We got to convert the raw data to the proper format because items can come
    // from older version of the application
    const searchCriteria = ActionItemUtils.migrateSearchAction(action);
    this.context.searchRemotelyExecuted.dispatch(searchCriteria.Query, this);
    this.context.executeSearch(searchCriteria, false);
  }

  private onExternalWebsiteActionClick(action: Action) {
    let url: string;
    if (action.SecondaryText !== null && (action.SecondaryText.indexOf("http") === 0 || action.SecondaryText.indexOf("https") === 0)) {
      url = action.SecondaryText;
    } else {
      url = action.SupplementaryText;
    }

    this.context.resources.openExternalWebsite(url);
  }

  render() {
    return (
      <div className="action-view full-height d-flex flex-column">
        <ActionToolbar
          currentScope={this.state.currentScope}
          onScopeSelected={this.onScopeChanged}
          localization={this.context.localization}
          helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
        />
        <ActionFlow scope={this.state.currentScope} onActionClick={this.onActionClick} viewReady={this.state.viewReady} />
      </div>
    );
  }
}

interface IActionItemProps {
  action: Action;
  language: Languages;
  onClick: (action: Action) => void;
}

interface IActionItemState {}
export class ActionItem extends React.Component<IActionItemProps, IActionItemState> {
  constructor(props: IActionItemProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  private handleClick() {
    this.props.onClick(this.props.action);
  }

  render() {
    const { action } = this.props;

    return (
      <div className="d-flex flex-row bg-light border-bottom p-1 m-1 actionItem" tabIndex={0} onClick={this.handleClick}>
        <div className="flex-shrink-0 p-3 align-self-center">
          <Icon src={ActionUtils.getTypeIcon(action.Type)} className="text-muted" />
        </div>
        <div className="flex-fill">
          <div className="mb-0 breakText">{action.SupplementaryText}</div>
          <br />
          {!Convert.isEmptyOrSpaces(action.SecondaryText) && (
            <React.Fragment>
              <small className="breakText d-block">{action.SecondaryText}</small>
              <br />
            </React.Fragment>
          )}
          <small>{Convert.dateToFormattedString(action.CreationDate, this.props.language)}</small>
        </div>
      </div>
    );
  }
}

interface IActionFlowProps {
  scope: HistoryType;
  onActionClick: (action: Action) => void;
  viewReady: boolean;
}

interface IActionFlowState {}
export class ActionFlow extends React.PureComponent<IActionFlowProps, IActionFlowState> {
  context: Book;
  static contextType = BookContext;
  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: any) {
    super(props);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildActionItem = this.buildActionItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
    this.onActionsChanged = this.onActionsChanged.bind(this);
  }

  componentDidMount() {
    this.context.actionsChanged.on(this.onActionsChanged);
  }
  componentWillUnmount() {
    this.context.actionsChanged.off(this.onActionsChanged);
  }

  onActionsChanged() {
    if (this.cogniflow.current) {
      this.cogniflow.current.reloadCogniflow();
    }
  }

  private initializeFlow(): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result: ActionResult<Action[]> = await this.context.getRecentActions(this.props.scope);
      if (result.valid()) {
        resolve({
          nodes: result.data,
          targetSpine: result.data.length > 0 ? result.data[0].Index : 0,
        });
      } else {
        reject();
      }
    });
  }

  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.fetchActions(Convert.cogniflowRequestToMessage(request), this.props.scope);
      if (result.valid()) {
        resolve(Convert.cogniflowMessageToResponse(result.data));
      } else {
        reject(result.errors.length > 0 ? result.errors[0] : "ActionFlow failure detected");
      }
    });
  }

  private buildActionItem(node: INode): JSX.Element {
    let action = node as Action;
    return <ActionItem action={action} onClick={this.props.onActionClick} language={this.context.appSettings.get().LanguageOverride} />;
  }

  componentDidUpdate() {
    if (this.cogniflow.current) {
      this.cogniflow.current.reloadCogniflow();
    }
  }
  private navigationDone() {}
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-history-index",
      secondaryIdDataAttribute: "data-history-id",
      secondaryIdNodeAttribute: "Id",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      contentAttribute: "",
      applyDirectlyToSegment: false,
    },
    scrollingClasses: "flex-fill",
    batchSize: 20,
  };
  render() {
    if (this.props.viewReady) {
      return (
        <StandaloneCogniflowContainer
          provider={this.flowProvider}
          builder={this.buildActionItem}
          initialize={this.initializeFlow}
          extraSettings={this.settings}
          navigationDoneCallback={this.navigationDone}
          ref={this.cogniflow}
        />
      );
    }

    return null;
  }
}

interface IActionToolbarState {}

interface IActionToolbarProps {
  currentScope: HistoryType;
  onScopeSelected: (scope: HistoryType) => any;
  localization: Locale;
  helpEnabled: boolean;
}

class ActionToolbar extends React.Component<IActionToolbarProps, IActionToolbarState> {
  context: Book;
  static contextType = BookContext;
  private actionTypes = [
    HistoryType.Unspecified,
    HistoryType.Search,
    HistoryType.SearchResultNavigation,
    HistoryType.TOCNavigation,
    HistoryType.ResourceViewing,
    HistoryType.ScrubberNavigation,
    HistoryType.ExternalWebsite,
    HistoryType.AppClosure,
    HistoryType.LinkNavigation,
  ];

  cogniflow = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: any) {
    super(props);
    this.deleteHistory = this.deleteHistory.bind(this);
  }

  async deleteHistory(): Promise<boolean> {
    if (
      (await Messages.Dialog.confirm(
        this.context.localization.currentLocale.HistoryView.ALERT_HISTORYDELETE_HEADING,
        this.context.localization.currentLocale.HistoryView.ALERT_HISTORYDELETE_PROMPT
      )) === "true"
    ) {
      let result = await this.context.deleteHistory();
      if (result.valid()) {
        this.context.actionsChanged.dispatch("", this);
        Messages.Notify.success(this.context.localization.currentLocale.HistoryView.LABEL_HISTORY_DELETED);
        return true;
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.HistoryView.LABEL_HISTORYERROR);
      }
    }
    return false;
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs" className="flex-shrink-0">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle caret color="link" data-for="actionToolbar" data-tip={this.props.localization.currentLocale.HistoryView.LABEL_FILTER_ACTIONS}>
                <Icon src={ActionUtils.getTypeIcon(this.props.currentScope)} />
              </DropdownToggle>
              <SelectDropdownMenu initial={this.props.currentScope} onSelected={this.props.onScopeSelected}>
                {this.actionTypes.map((v) => (
                    <SelectDropdownItem key={v} value={v}>
                      <Icon src={ActionUtils.getTypeIcon(v)} className="mr-3 ml-3" />
                      {ActionUtils.getTypeName(v, this.props.localization)}
                    </SelectDropdownItem>
                  ))}
              </SelectDropdownMenu>
            </UncontrolledDropdown> 
            <NavItem data-for="actionToolbar" data-tip={this.props.localization.currentLocale.AnnotationView.LABEL_CLEAR_SELECTION}>
              <ActionIcon src={<Image.delete_item />} onClick={this.deleteHistory} />
            </NavItem>
            {this.props.helpEnabled && <ReactTooltip id="actionToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

class ActionUtils {
  static getTypeIcon(type: HistoryType): React.ReactNode {
    switch (type) {
      case HistoryType.Search:
        return <Image.search />;
      case HistoryType.SearchResultNavigation:
        return <Image.searchResult />;
      case HistoryType.TOCNavigation:
        return <Image.toc />;
      case HistoryType.ResourceViewing:
        return <Image.media />;
      case HistoryType.ScrubberNavigation:
        return <Image.scrubber />;
      case HistoryType.ExternalWebsite:
        return <Image.www />;
      case HistoryType.AppClosure:
        return <Image.off />;
      case HistoryType.LinkNavigation:
        return <Image.link />;
      default:
        return <Image.history />;
    }
  }

  static getTypeName(type: HistoryType, localization: Locale): React.ReactNode {
    switch (type) {
      case HistoryType.Search:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_SEARCH;
      case HistoryType.SearchResultNavigation:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_SEARCHRESULT;
      case HistoryType.TOCNavigation:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_TOCLINK;
      case HistoryType.ResourceViewing:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_RESOURCE;
      case HistoryType.ScrubberNavigation:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_SCRUB;
      case HistoryType.ExternalWebsite:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_EXTERNAL;
      case HistoryType.AppClosure:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_SWITCH_OFF;
      case HistoryType.LinkNavigation:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_LINK;
      default:
        return localization.currentLocale.HistoryView.LABEL_ITEMTYPE_ALL;
    }
  }
}
