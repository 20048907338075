import { Action } from 'src/ui/foundation/StandaloneCogniflow';

import { SearchSorting } from '../Content';

export class CogniflowBatch<T = any> {
  Action: Action;
  TargetMainId: number;
  AnchorMainId: number;
  BatchSize: number;
  Nodes: T[];
  Refresh?: boolean;
  Query?: string;
}

export class CogniflowMessage<T = any> {
  constructor() {
    this.Batches = new Array<CogniflowBatch<T>>();
  }
  Batches: Array<CogniflowBatch<T>>;
}
export class ResultsCogniflowMessage<T = any> {
  SearchSorting: SearchSorting;
  constructor() {
    this.Batches = new Array<CogniflowBatch<T>>();
    this.SearchSorting = SearchSorting.Ranked;
  }
  Batches: Array<CogniflowBatch<T>>;
}
