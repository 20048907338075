import * as React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/ReaderModels';
import { DataItem, DataRow, DataTable } from '../DataTable';
import { Action, IBatch, IResponse } from '../StandaloneCogniflow';

interface IAttachmentFormProps {
  parentSubmission: Models.IUserFormSubmission;
  currentAttachments: Models.IUserFormSubmissionAttachment[];
  handleNewAttachment: (newAttach: Models.IUserFormSubmissionAttachment) => void;
  handleUpdatedAttachment: (newAttach: Models.IUserFormSubmissionAttachment) => void;
  deleteAttachment: (removeAttach: Models.IUserFormSubmissionAttachment) => void;
}
interface IAttachmentFormState {
  editingAttachment: number;
}

export class AttachmentForm extends React.Component<IAttachmentFormProps, IAttachmentFormState> {
  attachmentTable = React.createRef<DataTable>();

  constructor(props: any) {
    super(props);
    this.state = { editingAttachment: -1 };
  }

  handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0] !== undefined) {
      this.setNewAttachment(e.target.files![0]!);
    }
  };

  componentDidUpdate(prevProps: IAttachmentFormProps) {
    if (prevProps.currentAttachments.length !== this.props.currentAttachments.length) {
      this.attachmentTable.current!.reload();
    }
  }

  setNewAttachment = (file: File) => {
    let reader = new FileReader();
    reader.onload = () => {
      let dataReplace = /data: ?.*; ?base64. ?/g;
      let fileData = (reader.result! as string).replace(dataReplace, "");
      let len = fileData.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = fileData.charCodeAt(i);
      }
      let extension = "";
      if (file) {
        extension = "." + file.name.split(".").pop() ?? "";
      }

      let index = this.props.currentAttachments.findIndex((x) => x.Name === file.name);
      let existing = index >= 0;
      let attachment: Models.IUserFormSubmissionAttachment = existing ? this.props.currentAttachments[index] : ({} as Models.IUserFormSubmissionAttachment);

      attachment.Name = file.name.trimEnd().replace(/\.[^/.]+$/, "");
      attachment.AttachmentData = Array.from(bytes);
      attachment.AttachmentExtension = extension;
      if (!existing) attachment.CreationDate = new Date(Date.now());
      attachment.LastModificationDate = new Date(Date.now());
      attachment.UserFormSubmissionId = this.props.parentSubmission.TableId;
      if (!existing) this.props.handleNewAttachment(attachment);
      else this.props.handleUpdatedAttachment(attachment);
    };
    reader.readAsDataURL(file);
  };
  private attachmentFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });

  private initializeAttachments = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.props.currentAttachments;
      if (result === null) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });
  selectAttachment = (node: Models.IUserFormSubmissionAttachment) => {
    this.setState({ editingAttachment: node.Index }, () => {
      this.attachmentTable.current!.reRender();
    });
  };
  private generateAttachment = (node: Models.IUserFormSubmissionAttachment) => {
    let dataItems = [];
    let attrs = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={2} className="" key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.AttachmentExtension} />);
    dataItems.push(<DataItem flexVal={1} className="centerText" key={3} value={node.AttachmentData.length.toString() + " bytes"} />);
    return (
      <DataRow
        className={this.state.editingAttachment === node.Index ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectAttachment}
      />
    );
  };
  attachmentDeleteRequest = () => {
    let attachment = this.props.currentAttachments[this.state.editingAttachment];
    this.props.deleteAttachment(attachment);
  };
  render() {
    let setting = JSON.parse(JSON.stringify(Models.genericDataSettings));
    return (
      <div className="full-width full-height attachments">
        <div className="attachmentsTable">
          <DataTable
            tableClassName="full-height"
            headers={["Filename", "Extension", "Size"]}
            headerFlexes={[2, 1, 1]}
            flowProvider={this.attachmentFlowProvider}
            initializeFlowProvider={this.initializeAttachments}
            objectBuilder={this.generateAttachment}
            ref={this.attachmentTable}
            settingsOverride={setting}
            canDelete
            selectedRowDelete={this.attachmentDeleteRequest}
          />
        </div>
        <div className="attachmentsControl">
          <FormGroup style={{ flex: "1", display: "flex" }}>
            <Input style={{ width: "initial" }} accept="*" type="file" name="tipThumbnail" id="tipThumbnail" onChange={this.handleAttachmentChange} />
          </FormGroup>
        </div>
      </div>
    );
  }
}
