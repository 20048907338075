import * as React from 'react';
import { Book } from 'src/models/Book';
import { AnnotationPrintModel } from 'src/models/UserContent';

import { Convert, ResourcePlatform } from './Helpers';

export interface IAnnotationPrintProps {
  model: AnnotationPrintModel;
  context: Book;
  underline?: boolean;
  omitNotes?: boolean;
}
export interface IAnnotationPrintState {}
export class AnnotationPrint extends React.PureComponent<IAnnotationPrintProps, IAnnotationPrintState> {
  constructor(props: IAnnotationPrintProps) {
    super(props);
  }
  render() {
    let excerpts: JSX.Element[] = [];
    excerpts.push(<span>...</span>);
    this.props.model.Excerpts.map((item) => {
      if (item.IsHighlight) {
        if (this.props.underline === true) {
          excerpts.push(
            <span style={{ textDecoration: "underline", textDecorationColor: this.props.model.AnnotationType.Color, textDecorationThickness: "3px" }}>
              {item.Content}
            </span>
          );
        } else {
          excerpts.push(<span style={{ backgroundColor: this.props.model.AnnotationType.Color }}>{item.Content}</span>);
        }
      } else {
        excerpts.push(<span>{item.Content}</span>);
      }
    });
    excerpts.push(<span>...</span>);
    return (
      <div className="annoContainer">
        <div className="typeBlock">
          <div className="colourCircle" style={{ backgroundColor: this.props.model.AnnotationType.Color }} />
          <h2 className="typeName">
            {this.props.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATION_TYPE}: {this.props.model.AnnotationType.Value}
          </h2>
        </div>
        <h3>
          {this.props.context.localization.currentLocale.AnnotationView.LABEL_DATE}:{" "}
          {Convert.dateToFormattedString(this.props.model.Annotation.LastUpdate, this.props.context.localization.currentCulture)}
        </h3>
        <h4>{this.props.model.BreadCrumbs}</h4>
        {this.props.omitNotes !== true && !Convert.isEmptyOrSpaces(this.props.model.Annotation.Note) && (
          <div className="noteBox">
            {ResourcePlatform.toMarkdown(
              this.props.model.Annotation.Note,
              () => {},
              () => ""
            )}
          </div>
        )}
        <div className="annoExcerpts">
          <p>{excerpts}</p>
        </div>
      </div>
    );
  }
}

