import { AppSession } from './models/AppSession';
import { LogType } from './models/dto/AppRequest';

export class Log {
  private constructor() {} // prevent the instantiation, as a static class.

  static error(msg: string, err: any) {
    Log.write("ERROR", `${msg} | ${String(err)}`);
  }
  static info(msg: string) {
    Log.write("INFO", msg);
  }
  static warn(msg: string) {
    Log.write("WARN", msg);
  }
  static fatal(msg: string) {
    Log.write("FATAL", msg);
  }

  private static write(type: string, msg: string) {
    try {
      if (type === "ERROR") {
        AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Error });
      }
      if (type === "INFO") {
        AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Info });
      }
      if (type === "WARN") {
        AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Warning });
      }
      if (type === "FATAL") {
        AppSession.current.logInfo({ Info: "FRONTEND: " + msg, Type: LogType.Fatal });
      }
    } catch (e) {
      console.log("Could not put logger info.");
      console.log(e);
    }
  }
}
