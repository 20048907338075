import { Languages } from 'src/localization/Locale';
import { IBatch } from 'src/ui/foundation/StandaloneCogniflow';

import {
    AppSettings, FontSizes, LibraryConfig, LibraryViewModes, SearchEngines, SettingsTarget
} from '../AppSession';
import { ILibraryDownloadVersionR, ILibraryOpenVersionR } from './LibraryRequest';
import { IExecuteMigrationR } from './MigrationRequest';

export enum LoginResult {
  NotSet,
  Online,
  Offline,
  InvalidCredentials,
  UnknownError,
  AccountNotActive,
  OnlineAnonymous,
  OfflineAnonymous,
  ConnectionFailed,
}
export enum LogType {
  Info,
  Debug,
  Error,
  Fatal,
  Warning,
}

export interface ILogin {
  Username: string;
  Password: string;
  RememberMe: boolean;
  LibraryKey: string;
  Width: number;
  Height: number;
  IsAnonymous: boolean;
  Token: string;
}
export interface ILoginR {
  Session: string;
  Result: LoginResult;
  Username: string;
  LibraryKey: string;
  IsContentManipulationAllowed: boolean;
  StoreSocketId: string;
  UsedToken: string;
}

export interface ILogout {}

export interface ILogoutR {}

export interface IInitialize {
  Width: number;
  Height: number;
}

export interface IInitializeR {
  Libraries: ILibraryR[];
  Settings: AppSettings;
  CurrentConfig: SettingsTarget;
  OriginalConfig: SettingsTarget;
  CurrentServerUrlOverride: string;
  CurrentLinkingUrlOverride: string;
  UpdateCheck: IUpdateCheckR;
  ExternalLinkSocketId: string;
  AppBrandKey: string;
  HeldArgUrl: string;
}

export interface ILibraryR {
  Login: ILoginR;
  RunningDownloads: ILibraryDownloadVersionR[];
  OpenVersions: ILibraryOpenVersionR[];
  CurrentMigrations: IExecuteMigrationR[];
}

export interface ISetReaderFontSize {
  NewValue: FontSizes;
}

export interface ISetReaderFontSizeR {}
export interface ISetContentsFontSize {
  NewValue: FontSizes;
}

export interface ISetContentsFontSizeR {}
export interface ISetTOCFontSize {
  NewValue: FontSizes;
}

export interface ISetTOCFontSizeR {}
export interface ISetSearchEngine {
  NewValue: SearchEngines;
}

export interface ISetSearchEngineR {}
export interface ISetLibraryViewMode {
  NewValue: LibraryViewModes;
}

export interface ISetLibraryViewModeR {}
export interface ISetLanguageOverride {
  NewValue: Languages;
}

export interface ISetLanguageOverrideR {}

export interface ISetHelpEnabled {
  NewValue: boolean;
}
export interface ISetHelpEnabledR {}
export interface ISetLeftPanelDefaultOpen {
  NewValue: boolean;
}
export interface ISetLeftPanelDefaultOpenR {}

export interface ISetContentGuttersEnabledR {}
export interface ISetContentGuttersEnabled {
  NewValue: boolean;
}

export interface ISetSearchFilteringEnabled {
  NewValue: boolean;
}

export interface ISetSearchFilteringEnabledR {}
export interface ISetTOCLinking {
  NewValue: boolean;
}
export interface ISaveSettingsR {}
export interface ISaveSettings {
  NewSettings: AppSettings;
}
export interface IAppSendLogs {
  Comments: string;
}
export interface IAppSendLogsR {}

export interface ILogInfo {
  Info: string;
  Type: LogType;
}
export interface ILogInfoR {}

export interface ISetTOCLinkingR {}

export interface IGetLibraryConfigs {}
export interface IGetLibraryConfigsR {
  UserConfigs: LibraryConfig[];
  InstalledConfigs: LibraryConfig[];
}
export interface IAddLibraryConfig {
  BrandKey: string;
}
export interface IAddLibraryConfigR {
  FoundConfig: LibraryConfig;
}
export interface IDeleteLibraryConfig {
  BrandKey: string;
}
export interface IDeleteLibraryConfigR {}

export interface IUpdateCheck {}
export interface IUpdateCheckR {
  IsAvailable: boolean;
  IsRequired: boolean;
  UpdatePath: string;
}
export interface IApplicationShutdown {
  RedirectUrl: string;
}
export interface IApplicationShutdownR {}
export interface ISetConfigurationOverride {
  NewValue: SettingsTarget;
  ServerUrlOverride: string;
  LinkingSiteUrlOverride: string;
}

export interface ISetConfigurationOverrideR {}

export interface IFlow {
  FlowRequest: IBatch;
}
export interface IFlowR {
  FlowResponse: IBatch;
}

export interface IGetInternetConnectionStatusR {
  Online: boolean;
}

export interface IGetInternetConnectionStatus {
}

export interface IGetPasswordFromLicenseKeyR {
  Password: string;
}

export interface IGetPasswordFromLicenseKey {
   LicenseKey: string;
}