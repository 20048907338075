import { Dictionary } from 'src/collections/Generics';

type EmitterListenerType<T> = (value: T | undefined, event: EmitterEvent, key: number | string) => any;

/**
 * Emitter accepts event listeners as callback, and will call the listeners
 * on emit() function call. Optionally accepts a type argument, which allow
 * listeners to receive the instance of this type passed to emit().
 */
export class Emitter<T = Record<string, unknown>> {
  protected listeners: Array<EmitterListenerType<T>>;
  /**
   * Instantiate this instance
   */
  constructor() {
    this.listeners = new Array<EmitterListenerType<T>>();
  }

  addListener(listener: EmitterListenerType<T>): void {
    this.listeners.push(listener);
  }

  removeListener(listener: EmitterListenerType<T>): void {
    const callbackIndex = this.listeners.indexOf(listener);
    if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
  }

  emit(value: T | undefined, event = EmitterEvent.action, key: number | string = -1): void {
    this.listeners.forEach((listener) => this.callListener(listener, value, event, key));
  }

  protected callListener(listener: EmitterListenerType<T>, value: T | undefined, event: EmitterEvent, key: number | string): void {
    listener(value, event, key);
  }
}

export enum EmitterEvent {
  insert,
  update,
  delete,
  action, // Some action not relating to data manipulation is being emitted
  deleteAll,
}
/**
 * CollectionEmitter is an Emitter but also allow to attach callback to a particular
 * unique key of type K (could be an index or a dictionary key, the realization is left to the user).
 * Will call all the listeners for a particular key on emitItem(key) function call.
 */
export class CollectionEmitter<K, I = Record<string, unknown>> {
  protected itemListeners: Dictionary<K, Array<EmitterListenerType<I>>>;

  constructor() {
    this.itemListeners = new Dictionary<K, Array<EmitterListenerType<I>>>();
  }
  addListener(key: K, listener: EmitterListenerType<I>) {
    if (!this.itemListeners.containsKey(key)) {
      this.itemListeners.set(key, new Array<EmitterListenerType<I>>());
    }
    this.itemListeners.get(key)!.push(listener);
  }

  removeListeners(key: K) {
    this.itemListeners.remove(key);
  }

  removeListener(key: K, listener: EmitterListenerType<I>) {
    if (this.itemListeners.containsKey(key)) {
      const callbackIndex = this.itemListeners.get(key)!.indexOf(listener);
      if (callbackIndex > -1) this.itemListeners.get(key)!.splice(callbackIndex, 1);
    }
  }

  emit(key: K, value?: I): void {
    if (this.itemListeners.containsKey(key)) {
      this.itemListeners.get(key)!.forEach((listener) => this.callItemListener(listener, value));
    }
  }

  protected callItemListener(listener: EmitterListenerType<I>, value?: I) {
    listener(value, EmitterEvent.action, -1);
  }
}

export type EventListener<T> = (arg: T, sender?: any) => any;

export class EventHandler<T> {
  private listeners: Array<EventListener<T>>;
  latched: boolean;
  protected last: { arg: T; sender: any } | undefined;
  constructor(latched?: boolean, last?: { arg: T; sender: any }) {
    this.latched = latched || false;
    this.listeners = new Array<EventListener<T>>();
    if (this.latched) {
      this.last = last;
    }
  }
  public dispatch(arg: T, sender: any) {
    if (this.latched) {
      this.last = { arg, sender };
    }
    for (const listener of this.listeners) {
      listener(arg, sender);
    }
  }
  public on(listener: EventListener<T>) {
    this.listeners.push(listener);
    if (this.latched && this.last !== undefined) {
      listener(this.last.arg, this.last.sender);
    }
  }
  public off(listener: EventListener<T>) {
    const callbackIndex = this.listeners.indexOf(listener);
    if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
  }
}

export class MapEventHandler<K, T> {
  listeners: Dictionary<K, EventHandler<T>>;
  protected lasts: Dictionary<K, { arg: T; sender: any }>;
  latched: boolean;
  constructor(latched?: boolean) {
    this.latched = latched || false;
    this.listeners = new Dictionary<K, EventHandler<T>>();

    this.lasts = new Dictionary<K, { arg: T; sender: any }>();
  }

  public dispatch(key: K, arg: T, sender: any) {
    if (this.listeners.containsKey(key)) {
      this.listeners.get(key)!.dispatch(arg, sender);
    }
    if (this.latched) {
      this.lasts.set(key, { arg, sender });
    }
  }

  public on(key: K, listener: EventListener<T>) {
    if (!this.listeners.containsKey(key)) {
      this.listeners.set(key, new EventHandler<T>(this.latched, this.lasts.get(key)));
    }
    this.listeners.get(key)!.on(listener);
  }

  public handler(key: K): EventHandler<T> {
    if (!this.listeners.containsKey(key)) {
      this.listeners.set(key, new EventHandler<T>(this.latched, this.lasts.get(key)));
    }
    return this.listeners.get(key)!;
  }

  public off(key: K, listener?: EventListener<T>) {
    if (this.listeners.containsKey(key) && listener !== undefined) {
      this.listeners.get(key)!.off(listener);
    } else {
      this.listeners.remove(key);
    }
  }
}

export class Binding<T> extends EventHandler<T> {
  constructor(model: T) {
    super(true);
    this.dispatch(model, this);
  }

  get current(): T {
    return this.last!.arg;
  }
}

export enum LoadingStatus {
  initial = 0,
  loading = 1,
  loaded = 2,
}

export class LoadingReport {
  steps: Dictionary<string, LoadingStatus>;
  loaded: EventHandler<boolean>;
  stepLoaded: MapEventHandler<string, boolean>;
  stepLoading: MapEventHandler<string, boolean>;

  constructor() {
    this.steps = new Dictionary<string, LoadingStatus>();
    this.loaded = new EventHandler<boolean>(true);
    this.stepLoaded = new MapEventHandler<string, boolean>(true);
    this.stepLoading = new MapEventHandler<string, boolean>(true);
  }

  setStart(step: string) {
    this.steps.set(step, LoadingStatus.initial);
  }

  setLoading(step: string) {
    this.steps.set(step, LoadingStatus.loading);
    this.fireLoading(step);
  }

  setLoaded(step: string) {
    this.steps.set(step, LoadingStatus.loaded);
    this.fireLoaded(step);
  }

  isLoading(step?: string): boolean {
    if (step !== undefined) {
      return this.steps.get(step) === LoadingStatus.loading;
    } else {
      for (const s of this.steps.keys()) {
        if (this.isLoading(s)) {
          return true;
        }
      }
      return false;
    }
  }

  isLoaded(step?: string): boolean {
    if (step !== undefined) {
      return this.steps.get(step) === LoadingStatus.loaded;
    } else {
      for (const s of this.steps.keys()) {
        if (!this.isLoaded(s)) {
          return false;
        }
      }
      return true;
    }
  }

  private fireLoading(step: string) {
    this.stepLoading.dispatch(step, true, this);
  }

  private fireLoaded(step: string) {
    this.stepLoaded.dispatch(step, true, this);
    for (const s of this.steps.keys()) {
      if (this.steps.get(s) !== LoadingStatus.loaded) {
        return;
      }
    }
    this.loaded.dispatch(true, this);
  }
}
