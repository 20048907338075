import * as React from 'react';
import { ITable } from 'src/collections/Observable';
import { Locale } from 'src/localization/Locale';
import { ExtendedHeader, FlattenTocNode, TocNode } from 'src/models/Content';

import { withTheme } from '../state/Accessors';

export interface IScrollNavigationModalProps {
    headerToSpine: Map<number, number>,
    spineIdToHeader: ITable<FlattenTocNode>,
    currentSpine: number,
    currentTocResults?: ITable<ExtendedHeader>;
    color?: string,
    isLight?: boolean,
    onNavigationRequested: (spineId: number) => void,
    onNavigationCanceled: () => void,
    localization:Locale;
}

export interface IScrollNavigationModalState {
    headersToDisplay?: TocNode
}

class ScrollNavigationModalInner extends React.Component<IScrollNavigationModalProps, IScrollNavigationModalState> {

    constructor(props: IScrollNavigationModalProps) {
        super(props);
        this.computeHeadersToDisplay = this.computeHeadersToDisplay.bind(this);
        this.onHeaderClick = this.onHeaderClick.bind(this);
        this.renderTOCPreview = this.renderTOCPreview.bind(this);
        this.renderTOCNode = this.renderTOCNode.bind(this);
        this.renderTOCNodeHits = this.renderTOCNodeHits.bind(this);

        this.state = {
            headersToDisplay: undefined
        };
    }

    componentDidMount() {
        this.computeHeadersToDisplay();
    }

    componentDidUpdate(prevProps: IScrollNavigationModalProps) {
        if (prevProps.currentSpine !== this.props.currentSpine) {
            this.computeHeadersToDisplay();
        }
    }

    private computeHeadersToDisplay() {
        const { currentSpine, headerToSpine, spineIdToHeader } = this.props;

        let currentHeader = spineIdToHeader.get(currentSpine);
        let parent: FlattenTocNode | undefined;
        let parentSpine: number;
        let tree: TocNode = {
            Current: currentHeader!.Current,
            Children: [],
            Depth: -1 // We don't know the depth yet since we're building the TOC from the leaf to the root.
        };

        while (currentHeader && currentHeader.Current.Parent) {
            parentSpine = headerToSpine.get(currentHeader.Current.Parent)!;
            parent = spineIdToHeader.get(parentSpine);
            if (parent) {
                tree = {
                    Current: parent.Current,
                    Children: [tree],
                    Depth: -1
                };
            }
            currentHeader = parent;
        }

        this.setState({ headersToDisplay: tree });
    }

    private onHeaderClick(event: React.MouseEvent | React.TouchEvent, spine: number) {
        event.stopPropagation(); // Prevent click from propagating to backdrop click.
        this.props.onNavigationRequested(spine)
    }

    private renderTOCPreview(): React.ReactNode {
        if (!this.state.headersToDisplay) {
            return null;
        }

        return this.renderTOCNode(this.state.headersToDisplay);
    }

    private renderTOCNode(node: TocNode, currentDepth = 0): React.ReactNode {
        if (!node || !node.Current) {
            return null;
        }

        return <React.Fragment key={node.Current.Id}>
            <div className="scroll-navigation-header clickable p-2" onClick={event => this.onHeaderClick(event, node.Current.SpineId)}>
                <p className="mb-0 mt-0 d-flex" style={{ paddingLeft: currentDepth * 14 + "px" }}>
                    {this.renderTOCNodeHits(node)}
                    {node.Current.Value}
                </p>
            </div>
            {node.Children.length > 0 && node.Children.map(child => this.renderTOCNode(child, currentDepth + 1))}
        </React.Fragment>;
    }

    private renderTOCNodeHits(node: TocNode) {
        if (this.props.currentTocResults && this.props.currentTocResults.exists(node.Current.Id)) {
            const result = this.props.currentTocResults.get(node.Current.Id) as ExtendedHeader;

            if (result.HitCount > 0) {
                return <span className="text-danger pr-1">({result.HitCount})</span>;
            }
        }

        return null;
    }

    render() {
        return (
            <div className="w-100 h-100 position-absolute scroll-navigation-container" onClick={this.props.onNavigationCanceled}>
                <div className="h-50 bg-white flex flex-column scroll-navigation-modal">
                    <div className="w-100 p-1 text-white border border-white font-weight-bold" style={{ backgroundColor: this.props.color }}>{this.props.localization.currentLocale.NavigationView.LABEL_TOCVIEW}</div>
                    {this.renderTOCPreview()}
                </div>
            </div>
        );
    }
}

export const ScrollNavigationModal = withTheme<IScrollNavigationModalProps>(ScrollNavigationModalInner);