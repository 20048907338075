import * as React from 'react';

import { Image } from '../foundation/Assets';

export interface IArrowUpProps {
    onArrowUp: (value: boolean) => void;
}
export class ArrowUp extends React.Component<IArrowUpProps> {
    constructor(props: IArrowUpProps) {
        super(props);
        this.onArrowUp = this.onArrowUp.bind(this);
        this.startScroll = this.startScroll.bind(this);
        this.stopScroll = this.stopScroll.bind(this);
    }
    buttonDelay: NodeJS.Timeout;
    buttonPressInterval: NodeJS.Timeout;
    
    private startScroll() {
        this.onArrowUp();
        this.buttonDelay = setTimeout(() => {
            this.buttonPressInterval = setInterval(() => {
                this.onArrowUp();
            }, 64);
        }, 590);
    }

    private stopScroll = () => {
        clearInterval(this.buttonPressInterval);
        clearTimeout(this.buttonDelay);
    }

    private onArrowUp() {
        this.props.onArrowUp(true);
    }

    render() {
        return (
            <div>
                <div onMouseDown={this.startScroll} onMouseUp={this.stopScroll} onMouseLeave={this.stopScroll} onTouchStart={this.startScroll} onTouchEnd={this.stopScroll} className="arrow up"> <Image.arrownext /> </div>
            </div>
        )
    }
}