import * as React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { AppSession, OfflinePackageInstallPayload } from 'src/models/AppSession';
import { Status } from 'src/network/Requests';

import * as Messages from '../../foundation/Messages';
import { AppContext } from '../../state/Contextes';
import { Loading } from '../Controls';
import { DataTable } from '../DataTable';

interface IImportOfflineFormProps {
    dismiss: () => void;
    online: boolean;
    brandKey:string;
}

interface IImportOfflineFormState {
  editingAttachment: number;
  packagePath: string;
  loading: boolean;
  password: string;
  passwordUsed: boolean;
  status: string;
}

export class ImportOfflineForm extends React.Component<IImportOfflineFormProps, IImportOfflineFormState> {
  context: AppSession;
  static contextType = AppContext;
  attachmentTable = React.createRef<DataTable>();

  constructor(props: any) {
    super(props);
    this.state = { 
      editingAttachment: -1, 
      packagePath: "",
      loading: false, 
      password: "",
      passwordUsed: false,
      status: "Installing offline package."
    };
    this.submit = this.submit.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.offlineImportInstallMessageReceived = this.offlineImportInstallMessageReceived.bind(this);
  }

  componentDidMount(): void {
    this.setState({ status: this.context.localization.currentLocale.ImportOfflineForm.INSTALLING_OFFLINE_PACKAGE })
    this.context.offlineImportInstallMessageReceived.on(this.offlineImportInstallMessageReceived);
  }

  componentWillUnmount(): void {
    this.context.offlineImportInstallMessageReceived.off(this.offlineImportInstallMessageReceived);
  }

  offlineImportInstallMessageReceived(payload: OfflinePackageInstallPayload) {
    this.setState({ status: payload.Message})
  }

  async submit() {
    let packagePathElement = (document.getElementById('packagePath') as HTMLInputElement);
    let inputValue = packagePathElement.value;
    packagePathElement.readOnly = true;
    let submitButton = (document.getElementById('submit') as HTMLButtonElement);
    submitButton.hidden = true;
    inputValue = inputValue.replaceAll('"', '');
    if (this.props.online) {
      this.setState({ loading: true });
      const pathArray = inputValue.split("\\");
      const lastIndex = pathArray.length - 1;
      let licenseKey = pathArray[lastIndex].split(".")[0];
      
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(licenseKey))
      {
        let result = await this.context.getPasswordFromLicenseKey(licenseKey);
        if (result.valid())
        {
          let password = result.data.Password;
          console.log("Password: " + password);
          if (password === "") {
            this.setState({ loading: false, passwordUsed: true, packagePath: inputValue, });
            this.forceUpdate();
            return;
          }
          let response = await this.context.submitOfflinePackagePassword(inputValue, password, this.props.brandKey);
          if (response.valid() && response.status === Status.Success)
          {
            Messages.Notify.success(this.context.localization.currentLocale.ImportOfflineForm.SUCCESS_INSTALL_OFFLINE_PACKAGE);
            window.location.reload();
          }
          else
          {
          Messages.Notify.error(this.context.localization.currentLocale.ImportOfflineForm.ERROR_INSTALL_OFFLINE_PACKAGE + ': ' + response.errors[0].Message);
          }
        }
        else
        {
        Messages.Notify.error(this.context.localization.currentLocale.ImportOfflineForm.ERROR_INSTALL_OFFLINE_PACKAGE + ': ' + result.errors[0].Message);
        }
      }

      else
      {
        Messages.Notify.error(this.context.localization.currentLocale.ImportOfflineForm.INVALID_PACKAGE_PATH);
      }
      
      
      
      this.setState({ loading: false });
      this.props.dismiss();
    }
    this.setState({
      packagePath: inputValue,
    });
  }

  handlePasswordInput(event: any) {
    this.setState({
      password: event.target.value
    });
  }

  async submitPassword(password: string) {
    this.setState({ loading: true });
    let result = await this.context.submitOfflinePackagePassword(this.state.packagePath, password, this.props.brandKey);
    if(result.status === Status.Success) {
      this.setState({ loading: false });
      Messages.Notify.success(this.context.localization.currentLocale.Application.LABEL_PACKAGE_IMPORTED);
      window.location.reload();
    }
  }

  render() {
    return (
      <Loading
        isLoading={this.state.loading}
        theme="opaque"
        className="full-height initial-app-loading"
        status={this.state.status}
      >
        <div className="innerContainer">
          <div className="full-width full-height attachments">
            <div className="attachmentsTable">
              <Form>
                <h1> {this.context.localization.currentLocale.ImportOfflineForm.IMPORT_OFFLINE_PACKAGE} </h1>
              </Form>
              <h1>{this.context.localization.currentLocale.ImportOfflineForm.UPLOAD_OFFLINE_PACKAGE_PROMPT}</h1> 
              <h6> {this.context.localization.currentLocale.ImportOfflineForm.ENTER_OFFLINE_PACKAGE_PATH} </h6>
            </div>
            <div className="attachmentsControl">
              <FormGroup style={{ flex: "1", display: "flex" }}>
                <Input type="text" name="packagePath" id="packagePath" placeholder="Enter the path of your offline package" />
              </FormGroup>
              <FormGroup style={{ flex: "1", display: "flex" }}>
                <Button
                  outline
                  color="info"
                  className="full-width"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick = {this.submit}
                  id='submit'
                > Submit </Button>
              </FormGroup>
            </div>
                
            {(this.state.passwordUsed || (this.state.packagePath && !this.props.online)) && (
              <div>
                <div>
                  {this.context.localization.currentLocale.ImportOfflineForm.PROMPT_FOR_PASSWORD_REQUEST_FROM_ADMIN}
                </div>
                <FormGroup>
                  <Label>Enter password</Label>
                  <Input type="text" name="password" value={this.state.password} onChange={this.handlePasswordInput}/>
                  <Button 
                    className="full-width" 
                    onClick={() => this.submitPassword(this.state.password)} 
                    variant="outline-primary" 
                    color="primary" outline
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  > Submit </Button>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      </Loading>
    );
  }
}
