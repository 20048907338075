import { Log } from 'src/Logger';

export class SelectionHandler {
  innerWindow: Document;
  constructor(window: Document) {
    this.innerWindow = window;
  }

  intialWordCharaters = /^[a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]*/;
  terminatingWordCharaters = /[a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]*$/;
  notTerminatingWordCharaters = /[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]*$/;
  terminalwordandnotTerminalWordCharaters = /[a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]*[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]*$/;
  noContent = /^(\s|[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F])+$/;
  regTrim = /(^[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]+|[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]+$)/g;
  NoTermBlock = /[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]+/;
  startingWhiteSpace = /^[^a-zA-Z\d\u00c0-\u00d6\u00d8-\u00F6\u00F8-\u024F]+/;
  uglyLastWordCount = 0;

  setCopy() {
    this.innerWindow.execCommand("copy");
  }
  clearSelection() {
    if (this.innerWindow.getSelection && this.innerWindow.getSelection() !== null) {
      this.innerWindow.getSelection()!.empty();
    }
  }
  getSelectionObject(txt?: Selection | null) {

    if ((txt === null || txt === undefined) && this.innerWindow !== undefined && typeof this.innerWindow === typeof window && !this.innerWindow.hasOwnProperty("anchorNode")) {
      txt = this.innerWindow.getSelection();
    }

    if (!txt) {
      throw new Error("No selected text");
    }
    let firstwordcountLock;

    // 1) Get the document the two nodes are in
    let anchorNodeDoc = this.selectionDoc(txt.anchorNode as HTMLElement);
    let focusNodeDoc = this.selectionDoc(txt.focusNode as HTMLElement);

    let anchorId = -1;
    let focusId = -1;
    if (anchorNodeDoc) {
      anchorId = +anchorNodeDoc.dataset["cogniflow-Headid"]!;
    }
    if (focusNodeDoc) {
      focusId = +focusNodeDoc.dataset["cogniflow-Headid"]!;
    }

    let firstSelectionNode: HTMLElement | null = null;
    let firstSelectionNodeWordCount = 0;
    let firstSelectionDoc: HTMLElement | null = null;
    let firstSelectionDocId = 0;
    let firstSelectionOffset = 0;

    let secondSelectionDocId = 0;
    let secondSelectionNode: HTMLElement | null = null;
    let secondSelectionNodeWordCount = 0;
    let secondSelectionOffset = 0;

    // 2) If the documents are the same
    if (anchorNodeDoc !== null && focusNodeDoc !== null && anchorId === focusId) {
      firstSelectionDocId = anchorId;
      secondSelectionDocId = focusId;
      firstSelectionDoc = anchorNodeDoc;
      // a) Search for one the occurrence of either until one is located (counting the Words in each node)
      // b) Continue to search find nodeWordCount for the second node
      // c) If the second node occurs at the same node
      firstSelectionNodeWordCount = this.countNodeTreeWordsBreakson(anchorNodeDoc, txt.anchorNode as HTMLElement);

      if (txt.anchorNode === txt.focusNode) {
        secondSelectionNodeWordCount = firstSelectionNodeWordCount;
        if (txt.anchorOffset > txt.focusOffset) {
          firstSelectionNode = txt.focusNode as HTMLElement | null;
          secondSelectionNode = txt.anchorNode as HTMLElement | null;
          firstSelectionOffset = txt.focusOffset;
          secondSelectionOffset = txt.anchorOffset;
        } else {
          firstSelectionNode = txt.anchorNode as HTMLElement | null;
          secondSelectionNode = txt.focusNode as HTMLElement | null;
          firstSelectionOffset = txt.anchorOffset;
          secondSelectionOffset = txt.focusOffset;
        }
      } else {
        secondSelectionNodeWordCount = this.countNodeTreeWordsBreakson(focusNodeDoc, txt.focusNode as HTMLElement);
        if (secondSelectionNodeWordCount < firstSelectionNodeWordCount) {
          let temp = firstSelectionNodeWordCount;
          firstSelectionNodeWordCount = secondSelectionNodeWordCount;
          secondSelectionNodeWordCount = temp;

          firstSelectionNode = txt.focusNode as HTMLElement | null;
          secondSelectionNode = txt.anchorNode as HTMLElement | null;
          firstSelectionOffset = txt.focusOffset;
          secondSelectionOffset = txt.anchorOffset;
        } else {
          firstSelectionNode = txt.anchorNode as HTMLElement | null;
          secondSelectionNode = txt.focusNode as HTMLElement | null;
          firstSelectionOffset = txt.anchorOffset;
          secondSelectionOffset = txt.focusOffset;
        }
      }
      firstwordcountLock = firstSelectionNodeWordCount;
    } else {
      // the two documents are different
      if (anchorNodeDoc !== null && focusNodeDoc !== null && anchorId > focusId) {
        firstSelectionDocId = focusId;
        secondSelectionDocId = anchorId;

        firstSelectionNodeWordCount = this.countNodeTreeWordsBreakson(focusNodeDoc, txt.focusNode as HTMLElement);
        secondSelectionNodeWordCount = this.countNodeTreeWordsBreakson(anchorNodeDoc, txt.anchorNode as HTMLElement);

        firstSelectionDoc = focusNodeDoc;

        firstSelectionNode = txt.focusNode as HTMLElement | null;
        secondSelectionNode = txt.anchorNode as HTMLElement | null;
        firstSelectionOffset = txt.focusOffset + 1;
        secondSelectionOffset = txt.anchorOffset;
      } else if (anchorNodeDoc !== null && focusNodeDoc !== null) {
        firstSelectionDocId = anchorId;
        secondSelectionDocId = focusId;

        firstSelectionNodeWordCount = this.countNodeTreeWordsBreakson(anchorNodeDoc, txt.anchorNode as HTMLElement);
        secondSelectionNodeWordCount = this.countNodeTreeWordsBreakson(focusNodeDoc, txt.focusNode as HTMLElement);

        firstSelectionDoc = anchorNodeDoc;
        firstSelectionNode = txt.anchorNode as HTMLElement | null;
        secondSelectionNode = txt.focusNode as HTMLElement | null;
        firstSelectionOffset = txt.anchorOffset + 1;
        secondSelectionOffset = txt.focusOffset;
      }
    }

    // Default values for sections
    let firstSelectionText = "";
    let secondSelectionText = "";
    let tempS1 = -1;
    let tempS2 = -1;

    try {
      if (!(typeof firstSelectionNode!.nodeValue === undefined) && !(firstSelectionNode!.nodeValue === null)) {
        firstSelectionText = firstSelectionNode!.nodeValue.substring(0, firstSelectionOffset);
        tempS1 = firstSelectionOffset;
      }

      if (!(typeof secondSelectionNode!.nodeValue === undefined) && !(secondSelectionNode!.nodeValue === null)) {
        secondSelectionText = secondSelectionNode!.nodeValue.substring(0, secondSelectionOffset);
        tempS2 = secondSelectionOffset;
      }

      if (tempS1 > -1) {
        //  if there is a textnode in the first selection
        // Code not in use for the moment firstSelectionOffset must be a word
        /*
                  if (firstSelectionNode.nodeValue.substring(firstSelectionOffset).search(/^[^\w\u00b5-\u024F]/) === -1){ //  ^\W // buggy if anchornode is longer
                      firstSelectionOffset -= firstSelectionText.search(/[\w\u00b5-\u024F]*$/); //  \w*
                  }else{
                      firstSelectionOffset -= firstSelectionText.search(/[\w\u00b5-\u024F]+[^\w\u00b5-\u024F]*$/); //  \w+\W*$
                  }
                  */

        // is the start of a word
        firstSelectionOffset = 0;

        // Questionable code
        // if ((firstSelectionNode.nodeValue.substring(tempS1).search(/^[\w\u00b5-\u024F]/) === -1) // \w
        // 	firstSelectionNodeWordCount++;

        if (secondSelectionDocId === firstSelectionDocId) {
          firstSelectionNodeWordCount += firstSelectionText.replace(this.startingWhiteSpace, "").split(this.NoTermBlock).length - 1;
        } else if (firstSelectionNode!.parentNode === firstSelectionDoc) {
          firstSelectionNodeWordCount = 0;
        } else {
          //  Selection is spanning multiple segments.

          firstSelectionNodeWordCount += firstSelectionText.replace(this.startingWhiteSpace, "").replace(this.regTrim, "").split(this.NoTermBlock).length - 1;
          firstSelectionNodeWordCount = firstSelectionNodeWordCount < 0 ? 0 : firstSelectionNodeWordCount;
        }
      } else {
        if (secondSelectionDocId !== firstSelectionDocId) {
          firstSelectionNodeWordCount = 0;
        }
        firstSelectionOffset = 0;
      }

      if (tempS2 > -1) {
        // Code not in use for the moment secondSelectionOffset must be a word
        /* secondSelectionOffset -= secondSelectionText.search(/[\w\u00b5-\u024F]+[^\w\u00b5-\u024F]*$/);	//  \w+\W**/
        //
        if (firstSelectionNode === secondSelectionNode && tempS1 > -1 && this.noContent.test(secondSelectionText.substr(tempS1))) {
          let innerObject = {
            firstDocId: firstSelectionDocId,
            firstWordCount: firstSelectionNodeWordCount,
            firstOffset: firstSelectionOffset,
            secondDocId: secondSelectionDocId,
            secondWordCount: secondSelectionNodeWordCount,
            secondOffset: secondSelectionOffset === undefined ? 0 : secondSelectionOffset,
            hadError: true,
          };
          Log.info("Could not establish offsets of selection. Selection was probably empty.");
          return innerObject;
        } else if (this.noContent.test(secondSelectionText)) {
          // no charaters in selecton
          // must highlight to previous node's word
          // or report no words highlighted
          if (firstSelectionNode === secondSelectionNode) {
            let innerObject = {
              firstDocId: firstSelectionDocId,
              firstWordCount: firstSelectionNodeWordCount,
              firstOffset: firstSelectionOffset,
              secondDocId: secondSelectionDocId,
              secondWordCount: secondSelectionNodeWordCount,
              secondOffset: secondSelectionOffset === undefined ? 0 : secondSelectionOffset,
              hadError: true,
            };
            Log.warn("Could not establish offsets of selection. Selection was probably empty.");
            return innerObject;
          }
          secondSelectionOffset = this.uglyLastWordCount;
          secondSelectionNodeWordCount--;
        } else {
          secondSelectionOffset = this.terminatingWordCharaters.exec(secondSelectionText)![0].length;
          if (secondSelectionOffset === 0) {
            secondSelectionOffset =
              this.terminalwordandnotTerminalWordCharaters.exec(secondSelectionText)![0].length -
              this.notTerminatingWordCharaters.exec(secondSelectionText)![0].length;
          } else {
            secondSelectionOffset += this.intialWordCharaters.exec(secondSelectionNode!.nodeValue!.substring(tempS2))![0].length;
          }
          secondSelectionNodeWordCount += secondSelectionText.replace(this.regTrim, "").split(this.NoTermBlock).length - 1;
        }
      } else if (anchorNodeDoc !== null && focusNodeDoc !== null) {
        // the second selection is a special case as it may have subcontent
        if (anchorId !== focusId) {
          if ((secondSelectionNode as HTMLElement)!.hasAttribute("class") && this.hasClass(secondSelectionNode as HTMLElement, "ContentSegment")) {
            let secondSelectionDocItem = this.innerWindow.querySelector("[data-cogniflow--headid='" + (secondSelectionDocId - 1).toString() + "']");
            secondSelectionDocId = secondSelectionDocId - 1;
            secondSelectionNodeWordCount =
              (this.countNodeTreeWordsBreakson(secondSelectionDocItem as HTMLElement, secondSelectionDocItem!.nextSibling as HTMLElement) ) - 1;
            secondSelectionOffset = this.uglyLastWordCount;
          } else {
            secondSelectionOffset = this.uglyLastWordCount;
            secondSelectionNodeWordCount--;
          }
        } else if (firstwordcountLock === secondSelectionNodeWordCount) {
          secondSelectionNodeWordCount += this.countNodeTreeWordsBreakson(
            secondSelectionNode as HTMLElement,
            secondSelectionNode!.nextSibling as HTMLElement
          ) ;
          secondSelectionOffset = this.uglyLastWordCount;
          secondSelectionNodeWordCount--;
        } else {
          if (secondSelectionNode!.nodeName === "TD") {
            if (secondSelectionNode!.parentNode!.firstChild !== secondSelectionNode) {
              secondSelectionNodeWordCount -= this.countNodeTreeWordsBreakson(
                secondSelectionNode!.parentNode as HTMLElement,
                secondSelectionNode as HTMLElement
              ) ;
              secondSelectionNodeWordCount += this.countNodeTreeWordsBreakson(
                secondSelectionNode!.parentNode as HTMLElement,
                secondSelectionNode!.parentNode!.nextSibling as HTMLElement
              ) ;
            }
            secondSelectionOffset = this.uglyLastWordCount;
            secondSelectionNodeWordCount--;
          } else {
            secondSelectionOffset = this.uglyLastWordCount;
            secondSelectionNodeWordCount--;
          }
        }
      }

      let ourObject = {
        firstDocId: firstSelectionDocId,
        firstWordCount: firstSelectionNodeWordCount,
        firstOffset: firstSelectionOffset,
        secondDocId: secondSelectionDocId,
        secondWordCount: secondSelectionNodeWordCount,
        secondOffset: secondSelectionOffset === undefined ? 0 : secondSelectionOffset,
        hadError: false,
      };

      return ourObject;
    } catch (err) {
      throw err;
    }

  }

  selectionDoc(rootNode: HTMLElement) {
    try {
      let nodeCatch = rootNode;
      while (!this.hasClass(nodeCatch, "ContentSegment")) {
        nodeCatch = nodeCatch.parentNode as HTMLElement;
      }

      return nodeCatch;
    } catch (err) {
      Log.error("Selection doc error", err);
      return null;
    }
  }

  hasClass(nodeCatch: HTMLElement, className: string) {
    try {
      if (nodeCatch.className === undefined || nodeCatch.className === "") {
        return false;
      }
      return nodeCatch.className.trim().split(" ").indexOf(className) >= 0;
    } catch (e) {
      return false;
    }
  }

  /*
   * Name: getPreviousSibling
   * Input: node: Node - node for which to get its previous sibling
   * Output: Node - the first previous sibling of the node parameter that is not null
   * Purpose: Get the previous sibling of a node, discarding the null nodes that are sometimes
   *          automatically added to an html document
   */
  getPreviousSibling(node: HTMLElement) {
    let previousNode = node.previousSibling; //  previous sibling of the node parameter to be returned
    if (previousNode === null) {
      return previousNode;
    }
    return previousNode;
  }

  /*
     * Name: getNextSibling
     * Input: node: Node - node for which to get its next sibling
     * Output: Node - the first next sibling of the node parameter that is not null
     // * Purpose: Get the next sibling of a node, discarding the null nodes that are sometimes
     *          automatically added to an html document
     */
  getNextSibling(node: HTMLElement) {
    let nextNode = node.nextSibling; //  next sibling of the node parameter to be returned
    if (nextNode === null) {
      return nextNode;
    }
    while (nextNode.nodeType !== 1) {
      nextNode = nextNode.nextSibling;
      if (nextNode === null) {
        return nextNode;
      }
    }
    return nextNode;
  }

  /*
   * Name: countNodeTreeWords
   * Input: node: Node - root node in which the tree starts
   *        wordCount: int - current value of words counted
   * Output: int - the number of words in the text nodes of the specified node tree
   * Purpose: The purpose of this recursive function is to count the number of words that are
   *          within the node tree
   */
  countNodeTreeWords(node: HTMLElement, wordCount: number) {
    if (node.hasChildNodes() === true) {
      //  Loop to analyze the child nodes if they exist
      for (let i = 0; i < node.childNodes.length; i++) {
        wordCount = this.countNodeTreeWords(node.childNodes[i] as HTMLElement, wordCount);
      }
    }

    wordCount += this.countNodeWords(node)!;

    return wordCount;
  }

  /*
   * Name: mathJaxWordsCount
   * Input: mathjaxNode - any node, function will determine if this node is the root of a MathJax rendered equation.
   * Output: int - returns the words count of the Math ML source used to generated the equation or undefined if the node is not a MathJax equation
   * Purpose:
   */
  mathJaxWordsCount(): number | undefined {
    return undefined; //  Deactivated cause regular word count is enough when MathJax output mode is set to HTML-CSS.
  }

  recursiveHasChild(node: HTMLElement, child: HTMLElement): boolean {
    let hasChild = false;
    if (node === child) {
      return true;
    }
    if (node.hasChildNodes() === true) {
      for (let i = 0; i < node.childNodes.length; i++) {
        hasChild = this.recursiveHasChild(node.childNodes[i] as HTMLElement, child);
        if (hasChild) {
          break;
        }
      }
    }
    return hasChild;
  }

  recursiveCountNodeTreeWordsBreakson(currentNode: HTMLElement, stopNode1: HTMLElement): Array<number | boolean> | number {
    //  must return when break is discovered
    let wordCount = 0;
    let i;
    if (currentNode.hasChildNodes() === true) {
      let mathresult = this.mathJaxWordsCount();
      if (mathresult !== undefined) {
        return (mathresult as unknown) as number;
      }
      for (i = 0; i < currentNode.childNodes.length; i++) {
        if (currentNode.childNodes[i].nodeName === "SCRIPT") {
          //  Skip over script tags.
          continue;
        }

        if (currentNode.childNodes[i] === stopNode1) {
          i = currentNode.childNodes.length + 3;
        } else {
          let wordCatch = this.recursiveCountNodeTreeWordsBreakson(currentNode.childNodes[i] as HTMLElement, stopNode1);
          if (wordCatch[1]) {
            i = currentNode.childNodes.length + 3;
          }
          wordCount += wordCatch[0];
        }
      }
    }
    if (i === currentNode.childNodes.length + 4) {
      return [wordCount + this.countNodeWords(currentNode)!, true];
    }
    return [wordCount + this.countNodeWords(currentNode)!, false];
  }

  countNodeTreeWordsBreakson(currentNode: HTMLElement, stopNode1: HTMLElement): number {
    //  must return when break is discovered
    let wordCount = 0;
    let i;

    if (currentNode.hasChildNodes() === true) {
      let mathresult = this.mathJaxWordsCount();
      if (mathresult !== undefined && mathresult !== 0) {
        return mathresult;
      }
      for (i = 0; i < currentNode.childNodes.length; i++) {
        if (currentNode.childNodes[i].nodeName === "SCRIPT") {
          //  Skip over script tags.
          continue;
        }
        if (currentNode.childNodes[i] === stopNode1) {
          i = currentNode.childNodes.length + 3;
        } else {
          let wordCatch = this.recursiveCountNodeTreeWordsBreakson(currentNode.childNodes[i] as HTMLElement, stopNode1);
          if (wordCatch[1]) {
            i = currentNode.childNodes.length + 3;
          }
          wordCount += wordCatch[0];
        }
      }
    }
    return wordCount + this.countNodeWords(currentNode)!;
  }

  /*
   *  Name: countNodeWords
   *  Input: node: Node - node for which to find the number of words
   *  Output: int - Number of words that are within the node's text content
   *  Purpose: Get the number of words that are within the node's text content
   */
  countNodeWords(node: HTMLElement) {
    try {
      if (node !== null && node.nodeValue !== null && node.nodeType === 3) {
        // if (node.nodeValue.match(/^(\s|\W)+$/)){
        if (this.noContent.test(node.nodeValue)) {
          return 0;
        } else {
          // alert(testingVar+ "+"+ node.nodeValue.charCodeAt(0) +"%" + node.nodeValue +":"+node.nodeValue.replace(/(^[^\w\u00b5-\u024F]+|[^\w\u00b5-\u024F]+$)/g,'').split(/[^\w\u00b5-\u024F]+/).length);
          // return node.nodeValue.replace(/(^\W+|\W+$)/g,"").split(/\W+/).length;
          // testingVar += node.nodeValue.replace(/(^[^\w\u00b5-\u024F]+|[^\w\u00b5-\u024F]+$)/g,'').split(/[^\w\u00b5-\u024F]+/).length;
          let arraycatch = node.nodeValue.replace(this.regTrim, "").split(this.NoTermBlock);
          this.uglyLastWordCount = arraycatch.pop()!.length;
          return arraycatch.length + 1;
        }
      } else {
        return 0;
      }
    } catch (alert) {
      Log.error("countNodeWords error.", alert);
      return null;
    }
  }

  findPos(obj: HTMLElement) {
    let curtop = 0;
    if (obj.offsetTop) {
      curtop += obj.offsetTop;
    }
    while (obj.offsetParent) {
      obj = obj.offsetParent as HTMLElement;
      curtop += obj.offsetTop;
    }
    return curtop;
  }

  getQuickSearch() {
    let selectedText = this.innerWindow.getSelection()!.toString();
    return selectedText;
  }

  getWebSearch() {
    let selectedText = this.innerWindow.getSelection()!.toString();
    return selectedText;
  }

  getFavourite() {
    try {
      let txt = null;
      if (this.innerWindow !== undefined && typeof this.innerWindow === typeof window && !this.innerWindow.hasOwnProperty("anchorNode")) {
        txt = this.innerWindow.getSelection();
      }
      if (txt) {
        let anchorHeight = Math.max(this.findPos(txt.anchorNode as HTMLElement), this.findPos(txt.anchorNode!.parentNode as HTMLElement));
        let focusHeight = Math.max(this.findPos(txt.focusNode as HTMLElement), this.findPos(txt.focusNode!.parentNode as HTMLElement));

        let NodeDoc: HTMLElement;
        if (focusHeight > anchorHeight) {
          NodeDoc = this.selectionDoc(txt.anchorNode as HTMLElement)!;
        } else {
          NodeDoc = this.selectionDoc(txt.focusNode as HTMLElement)!;
        }
        let rect = NodeDoc.getBoundingClientRect();
        let selectRect = txt.getRangeAt(0).getBoundingClientRect();
        let deltaYOffset = Math.floor(selectRect.top - rect.top);

        // deltaYOffset must always be postive
        if (deltaYOffset < 0) {
          deltaYOffset = 0;
        }

        let ourObject = {
          YDocHeight: NodeDoc.scrollHeight,
          YOffset: deltaYOffset,
          DocId: NodeDoc.dataset["cogniflow-Headid"]!,
        };
        return ourObject;
      }
    } catch (err) {
      Log.error("getFavourite error.", err);
    }
    return null;
  }

  getSelectionText(): { selTxt: string, sel: Selection | null } {
    let text = "";
    try {
      let sel = this.innerWindow.getSelection();
      if (sel) {
        text = this.innerWindow.getSelection()!.toString();
      } else if ((this.innerWindow as any).selection && (this.innerWindow as any).selection.type !== "Control") {
        return { sel: null, selTxt: "" };
      }
      return { sel: sel, selTxt: text };
    } catch (e) {
      Log.error("Could not get selection!", e);
      return { sel: null, selTxt: "" };
    }
  }

  getSelectionContent(): DocumentFragment | undefined {
    const sel: Selection | null = this.innerWindow.getSelection ? this.innerWindow.getSelection() : (this.innerWindow as any).selection;
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      return range.cloneContents();
    }

    return undefined;
  }

  reportSelectionError(err: string) {
    Log.error("reportSelectionError.", err);
  }
}
