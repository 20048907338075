import * as React from 'react';

export interface PrintBoxProps {
  headStyle: string;
  headScript: string;
}
export class PrintBox extends React.PureComponent<PrintBoxProps, unknown> {
  render() {
    return (
      <html>
        <head>
          <style>{this.props.headStyle}</style>
          <script>{this.props.headScript}</script>
        </head>
        <body>{this.props.children}</body>
      </html>
    );
  }
}
