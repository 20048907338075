import { ICogniflowOptionalSettings } from 'src/ui/foundation/StandaloneCogniflow';

export const genericDataSettings: ICogniflowOptionalSettings = {
  segmentDataDescriptor: {
    mainIdNodeAttribute: "Index",
    mainIdDataAttribute: "data-index",
    secondaryIdNodeAttribute: "Id",
    secondaryIdDataAttribute: "data-id",
    contentAttribute: "",
    isFirstAttribute: "IsFirst",
    isLastAttribute: "IsLast",
    applyDirectlyToSegment: true,
  },
  batchSize: 15,
  isIndexBased: true,
};

export interface IDataPaging {
  Index: number;
  IsFirst: boolean;
  IsLast: boolean;
}

export enum TitleStatus {
  Inactive = 1,
  Review = 2,
  Active = 3,
}

export interface ITitle extends IDataPaging {
  TableId: number;
  TitleRef: string;
  PublisherId: number;
  PublicationPackId: number;
  TitleName: string;
  TitleStatus: TitleStatus;
  PublicationDate: Date;
  ExpirationDate: Date;
}

export class Title implements ITitle {
  TableId: number;
  TitleRef: string;
  PublisherId: number;
  PublicationPackId: number;
  TitleName: string;
  TitleStatus: TitleStatus;
  PublicationDate: Date;
  ExpirationDate: Date;
  Index: number;
  IsFirst: boolean;
  IsLast: boolean;
}

export enum UserFormSubmissionType {
  Tip,
  Bulletin,
  Feedback,
}
export enum UserFormSubmissionState {
  New,
  UnderReview,
  Published,
  Rejected,
  Completed,
}
export interface IUserFormSubmission {
  TableId: number;
  UserId: number;
  VersionId: number | null;
  ApproverUserId: number | null;
  SegmentId: number | null;
  UserFormDefinitionId: number;
  TableGuid: string;
  UserReadReceiptNeeded: boolean | null;
  SubmissionState: number;
  Submission: string;
  CreationDate: Date | null;
  LastModificationDate: Date | null;
}

export interface IUserFormDefinition {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  FormType: UserFormSubmissionType;
  CreationDate: Date;
  IsActive: boolean;
  DataSchema: string;
  ReaderUiSchema: string;
  DashboardUiSchema: string;
}

export interface IUserFormSubmissionAttachment extends IDataPaging {
  TableId: number;
  UserFormSubmissionId: number;
  AttachmentExtension: string;
  AttachmentData: number[];
  CreationDate: Date;
  LastModificationDate: Date;
  TableGuid: string;
  Name: string;
}

