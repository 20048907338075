import * as React from 'react';
import { TwitterPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';
import {
    Button, Col, Collapse, DropdownToggle, Form, FormFeedback, FormGroup, Input, Nav, Navbar,
    NavItem, NavLink, Row, UncontrolledDropdown
} from 'reactstrap';
import { Languages, Locale } from 'src/localization/Locale';
import { Book, BookLoadingSteps } from 'src/models/Book';
import { AnnotationType, AnnotationTypeSorting } from 'src/models/UserContent';
import { Binding, EmitterEvent } from 'src/utilities/Events';
import { FormState } from 'src/utilities/FormState';
import { Convert } from 'src/utilities/Helpers';
import { Validator } from 'src/utilities/Validator';

import { Image } from '../foundation/Assets';
import {
    ActionIcon, AnnotationTypeComponent, Expander, Icon, ItemCard, SortDropdownItem,
    SortDropdownMenu
} from '../foundation/Controls';
import { RightFlip } from '../foundation/Layout';
import * as Messages from '../foundation/Messages';
import {
    Action, ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer
} from '../foundation/StandaloneCogniflow';
import { BookContext } from '../state/Contextes';
import { IModelBinding } from '../state/Generics';

interface IAnnotationTypeViewState {
  formOpen: boolean;
  editing: number;
  createViewOpen: boolean;
  currentAnnotationType: AnnotationType | null;
  createAnnotationType: AnnotationType;
  sorting: AnnotationTypeSorting;
  reversedSort: boolean;
  sortedTypes: AnnotationType[];
}
interface IAnnotationViewProps {
  isActive: boolean;
}
export class AnnotationTypeView extends React.Component<IAnnotationViewProps, IAnnotationTypeViewState> {
  context: Book;
  private formRef = React.createRef<AnnotationTypeForm>();
  private createformRef = React.createRef<AnnotationTypeForm>();
  cogniflow = React.createRef<StandaloneCogniflowContainer>();

  static contextType = BookContext;
  constructor(props: any) {
    super(props);
    this.state = {
      editing: 0,
      formOpen: false,
      createViewOpen: false,
      currentAnnotationType: null,
      createAnnotationType: new AnnotationType(),
      sorting: AnnotationTypeSorting.Recent,
      reversedSort: false,
      sortedTypes: [],
    };
    this.editType = this.editType.bind(this);
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.toggleCreateView = this.toggleCreateView.bind(this);
    this.onReady = this.onReady.bind(this);
    this.update = this.update.bind(this);
    this.readAnnotationType = this.readAnnotationType.bind(this);
    this.closeAnnotationTypeForm = this.closeAnnotationTypeForm.bind(this);
    this.onFormToolbarAction = this.onFormToolbarAction.bind(this);
    this.createAnnotationType = this.createAnnotationType.bind(this);
    this.flowProvider = this.flowProvider.bind(this);
    this.initializeFlow = this.initializeFlow.bind(this);
    this.buildAnnotationTypeItem = this.buildAnnotationTypeItem.bind(this);
    this.navigationDone = this.navigationDone.bind(this);
  }
  segmentInserted() {}
  editType(item: AnnotationType) {
    this.readAnnotationType(item);
  }
  onSortingChanged(sort: AnnotationTypeSorting, reversed: boolean) {
    this.context.appSettings.set({ ...this.context.appSettings.get(), AnnotationTypeSorting: sort, AnnotationTypeSortingReversed: reversed });
    this.setState(
      {
        sorting: sort,
        reversedSort: reversed,
      },
      () => {
        this.update(null, 0);
      }
    );
  }

  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.annotationTypes, this);
    await this.context.initAnnotationTypes();
    this.context.annotationTypes.addListener(this.update);
    this.update();
    this.context.loading.setLoaded(BookLoadingSteps.annotationTypes);
    this.setState({
      createAnnotationType: {
        Color: "#" + this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_COLOR,
        Value: this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NAME_DEFAULT,
        Id: 0,
        LastUpdate: new Date(),
      },
    });
  }
  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.annotationTypes, this.onReady);
    this.setState({
      sorting: this.context.appSettings.get().AnnotationTypeSorting,
      reversedSort: this.context.appSettings.get().AnnotationTypeSortingReversed,
    });
  }
  update(affectedItem?: any, event?: EmitterEvent) {
    if (event === EmitterEvent.delete) {
      this.setState({ currentAnnotationType: null });
      this.closeAnnotationTypeForm();
    }
    this.setState({ sortedTypes: this.sortAnnotations(this.context.annotationTypes.rows()) }, () => {
      if (this.cogniflow.current && this.cogniflow.current !== null && event !== undefined) {
        this.cogniflow.current.reloadCogniflow();
      }
    });
  }
  sortAnnotations(annoTypes: AnnotationType[]): AnnotationType[] {
    if (this.state.sorting === AnnotationTypeSorting.Title) {
      annoTypes = annoTypes.sort((x1, x2) => {
        let x1v = x1.Value.toLowerCase().replace(/ /g, "");
        let x2v = x2.Value.toLowerCase().replace(/ /g, "");
        if (x1v > x2v) {
          return 1;
        }
        if (x1v < x2v) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.sorting === AnnotationTypeSorting.Recent) {
      annoTypes = annoTypes.sort((x1, x2) => {
        if (x1.LastUpdate > x2.LastUpdate) {
          return 1;
        }
        if (x1.LastUpdate < x2.LastUpdate) {
          return -1;
        }
        return 0;
      });
    }
    if (this.state.reversedSort) {
      annoTypes = annoTypes.reverse();
    }
    let count = 0;
    annoTypes.map((x) => {
      x.Index = count;
      count++;
      x.IsFirst = false;
      x.IsLast = false;
    });
    if (annoTypes.length > 0) {
      annoTypes[0].IsFirst = true;
      annoTypes[annoTypes.length - 1].IsLast = true;
    }
    return annoTypes;
  }
  async closeAnnotationTypeForm() {
    await this.checkChanges();
    this.setState({
      formOpen: false,
      createViewOpen: false,
    });
  }
  async readAnnotationType(annotationType: AnnotationType) {
    await this.checkChanges();
    this.setState({
      formOpen: true,
      currentAnnotationType: annotationType,
    });
  }

  async onFormToolbarAction(action: AnnotationTypeFormActions) {
    switch (action) {
      case AnnotationTypeFormActions.create:
        if (this.createformRef.current !== null && this.createformRef.current.valid()) {
          this.createformRef.current.createAnnotationType();
        }
        this.closeAnnotationTypeForm();
        break;
      case AnnotationTypeFormActions.back:
        this.closeAnnotationTypeForm();
        break;
      case AnnotationTypeFormActions.save:
        if (this.formRef.current != null && action === AnnotationTypeFormActions.save && this.formRef.current.valid()) {
          this.formRef.current.submit();
        }
        break;
      case AnnotationTypeFormActions.delete:
        if (this.formRef.current != null) {
          await this.formRef.current.deleteAnnotationType();
        }
        break;
    }
  }

  private createAnnotationType() {
    this.onFormToolbarAction(AnnotationTypeFormActions.create);
  }

  private toggleCreateView() {
    this.setState({
      createViewOpen: !this.state.createViewOpen,
    });
  }
  async checkChanges() {
    if (this.formRef.current && this.formRef.current.state.edited && this.formRef.current.state.form.formValid()) {
      let result = await Messages.Dialog.confirm(
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPESAVE_PROMPT,
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPESAVE_HEADING
      );
      if (result === "true") {
        await this.formRef.current.submit();
      } else {
        this.formRef.current.setState({ edited: false });
      }
    }
  }
  private flowProvider(request: IRequest): Promise<IResponse> {
    return new Promise<IResponse>((resolve) => {
      let message = request;
      let items: AnnotationType[] = [];
      if (message.Batches[0].Action === Action.append || message.Batches[0].Action === Action.navAppend) {
        let nextId = message.Batches[0].AnchorMainId + 1;
        items = this.state.sortedTypes.slice(nextId, nextId + message.Batches[0].BatchSize);
      }
      if (message.Batches[0].Action === Action.prepend) {
        items = this.state.sortedTypes.slice(
          message.Batches[0].AnchorMainId - message.Batches[0].BatchSize < 0 ? 0 : message.Batches[0].AnchorMainId - message.Batches[0].BatchSize,
          message.Batches[0].AnchorMainId
        );
      }
      message.Batches[0].Nodes = [];
      message.Batches[0].Nodes = items;
      resolve(message);
    });
  }

  private navigationDone() {}

  componentDidUpdate() {}

  private initializeFlow(anchor?: number): Promise<{ nodes: any[]; targetSpine: number }> {
    return new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let result: AnnotationType[] = [];
      if (anchor) {
        result = this.state.sortedTypes.slice(anchor, anchor + 100);
      } else {
        result = this.state.sortedTypes.slice(0, 100);
      }
      resolve({
        nodes: result,
        targetSpine: result[0].Index ? result[0].Index : 0,
      });
    });
  }

  private buildAnnotationTypeItem(node: INode): any {
    let item = node as AnnotationType;
    item = this.context.annotationTypes.get(item.Id)!;
    return <AnnotationTypeListItem item={item} onClick={this.readAnnotationType} language={this.context.appSettings.get().LanguageOverride} />;
  }
  settings: ICogniflowOptionalSettings = {
    segmentDataDescriptor: {
      mainIdNodeAttribute: "Index",
      mainIdDataAttribute: "data-type-index",
      secondaryIdNodeAttribute: "Id",
      secondaryIdDataAttribute: "data-type-id",
      contentAttribute: "",
      isFirstAttribute: "IsFirst",
      isLastAttribute: "IsLast",
      applyDirectlyToSegment: false,
    },
    batchSize: 20,
  };
  render(): any {
    return (
      <div className="annotationType-view full-height d-flex flex-column position-relative">
        <RightFlip
          breakpoint={768}
          className="flex-fill"
          isOpen={this.state.formOpen}
          panelClassName={{
            left: "d-flex flex-column",
            right: "d-flex flex-column border-left bg-body",
          }}
        >
          {{
            left: (
              <React.Fragment>
                <AnnotationTypeToolbar
                  onCreateNewToggle={this.toggleCreateView}
                  createViewOpen={this.state.createViewOpen}
                  onSortingChange={this.onSortingChanged}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                  initialReversed={this.state.reversedSort}
                  initialSort={this.state.sorting}
                />
                <Expander isOpen={this.state.createViewOpen} className="bg-light border-top border-bottom">
                  <AnnotationTypeForm
                    onActionClicked={this.createAnnotationType}
                    ref={this.createformRef}
                    key={this.state.createAnnotationType.Id}
                    model={new Binding<AnnotationType>(this.state.createAnnotationType)}
                    showSave={true}
                  />
                </Expander>
                {this.state.sortedTypes.length > 0 && (
                  <StandaloneCogniflowContainer
                    provider={this.flowProvider}
                    builder={this.buildAnnotationTypeItem}
                    initialize={this.initializeFlow}
                    segmentsInsertedCallback={this.segmentInserted}
                    navigationDoneCallback={this.navigationDone}
                    extraSettings={this.settings}
                    ref={this.cogniflow}
                  />
                )}
                {this.state.sortedTypes.length === 0 && (
                  <span key={0} className="noUcItems">
                    {this.context.localization.currentLocale.AnnotationTypeView.LABEL_NO_TYPES}
                  </span>
                )}
              </React.Fragment>
            ),
            right: (
              <React.Fragment>
                <AnnotationTypeFormToolbar
                  onActionClicked={this.onFormToolbarAction}
                  key={this.state.currentAnnotationType && this.state.currentAnnotationType !== null ? this.state.currentAnnotationType.Id : -1}
                  model={this.state.currentAnnotationType ? this.context.annotationTypes.bind(this.state.currentAnnotationType) : new Binding(undefined)}
                  localization={this.context.localization}
                  helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
                />
                <div className="flex-fill scrollable  listContainer">
                  <AnnotationTypeForm
                    onActionClicked={this.onFormToolbarAction}
                    ref={this.formRef}
                    key={this.state.currentAnnotationType && this.state.currentAnnotationType !== null ? this.state.currentAnnotationType.Id : -1}
                    model={this.state.currentAnnotationType ? this.context.annotationTypes.bind(this.state.currentAnnotationType) : new Binding(undefined)}
                    showSave={false}
                  />
                </div>
              </React.Fragment>
            ),
          }}
        </RightFlip>
      </div>
    );
  }
}

interface IAnnotationTypeToolbarProps {
  onSortingChange: (sort: AnnotationTypeSorting, reversed: boolean) => any;
  onCreateNewToggle: () => any;
  createViewOpen: boolean;
  localization: Locale;
  helpEnabled: boolean;
  initialSort: AnnotationTypeSorting;
  initialReversed: boolean;
}
interface IAnnotationTypeToolbarState {}

class AnnotationTypeToolbar extends React.Component<IAnnotationTypeToolbarProps, IAnnotationTypeToolbarState> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({ createViewOpen: false });
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                color="link"
                data-for="annotationTypeToolbar"
                data-tip={this.props.localization.currentLocale.LibraryView.LABEL_SORT_BUTTON}
              >
                <Icon src={<Image.sort />} />
              </DropdownToggle>
              <SortDropdownMenu initial={this.props.initialSort} initialReversed={this.props.initialReversed} onSortSelected={this.props.onSortingChange}>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationTypeSorting.Recent}>
                  {this.props.localization.currentLocale.AnnotationView.LABEL_LAST_MODIFIED}
                </SortDropdownItem>
                <SortDropdownItem onClick={this.props.onSortingChange} value={AnnotationTypeSorting.Title}>
                  {this.props.localization.currentLocale.FavouriteView.LABEL_TITLE}
                </SortDropdownItem>
              </SortDropdownMenu>
            </UncontrolledDropdown>
            <NavItem data-for="annotationTypeToolbar" data-tip={this.props.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPE_NEW}>
              <ActionIcon onClick={this.props.onCreateNewToggle} src={<Image.plus />} active={this.props.createViewOpen} />
            </NavItem>
            {this.props.helpEnabled && <ReactTooltip id="annotationTypeToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
interface IAnnotationTypeItemProps {
  item: AnnotationType;
  language: Languages;
  onClick: (item: AnnotationType, action: AnnotationTypeItemAction) => any;
}
enum AnnotationTypeItemAction {
  item,
  showMore,
}
class AnnotationTypeListItem extends React.Component<IAnnotationTypeItemProps, unknown> {
  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onShowMoreClick = this.onShowMoreClick.bind(this);
  }

  private onClick() {
    this.props.onClick(this.props.item, AnnotationTypeItemAction.item);
  }

  private onShowMoreClick() {
    this.props.onClick(this.props.item, AnnotationTypeItemAction.showMore);
  }

  render() {
    return (
      <ItemCard onClick={this.onClick} showMore={true} onShowMoreClick={this.onShowMoreClick}>
        {{
          title: <AnnotationTypeComponent color={this.props.item.Color} name={this.props.item.Value} tokenClassname="larger-token" />,
          description: this.props.item.LastUpdate && (
            <span style={{ fontStyle: "italic" }}>{Convert.dateToFormattedString(this.props.item.LastUpdate, this.props.language)}</span>
          ),
        }}
      </ItemCard>
    );
  }
}

interface IAnnotationTypeFormProps extends IModelBinding<AnnotationType | undefined> {
  onActionClicked: (action: AnnotationTypeFormActions) => any;
  goBackClicked?: () => void;
  showSave: boolean;
}

interface IAnnotationTypeFormState {
  form: FormState;
  edited: boolean;
}

export class AnnotationTypeForm extends React.Component<IAnnotationTypeFormProps, IAnnotationTypeFormState> {
  context: Book;
  static contextType = BookContext;

  constructor(props: any) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.submit = this.submit.bind(this);
    this.createAnnotationType = this.createAnnotationType.bind(this);
    this.handleColourChange = this.handleColourChange.bind(this);
    let form = new FormState({
      id: 0,
      name: "",
      colourObj: { hex: "#FFFFFF" },
    });
    this.state = {
      form,
      edited: false,
    };
  }

  onModelUpdate() {
    if (this.props.model.current && this.props.model.current !== null) {
      const annotationType = this.props.model.current;
      this.setState({
        form: this.state.form.update({
          id: annotationType.Id,
          name: annotationType.Value,
          colourObj: { hex: annotationType.Color },
        }),
        edited: false,
      });
    } else {
      this.setState({
        form: this.state.form.reset(),
        edited: false,
      });
    }
  }
  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
    this.state.form.addValidator(
      "name",
      Validator.notNullOrWhitespace,
      this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPENAMENEEDED_PROMPT
    );
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  async submit() {
    let form = this.state.form;
    if (this.state.edited) {
      if (form.formValid()) {
        let requ = {
          Id: form.values.id,
          Colour: form.values.colourObj.hex,
          Name: form.values.name,
        };
        let result = await this.context.updateAnnotationType(requ);
        if (result.valid()) {
          Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPESAVED);
        } else {
          Messages.Notify.error(result.errors[0].Message);
        }
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
      }
    }
  }

  async createAnnotationType(): Promise<boolean> {
    let form = this.state.form;
    if (this.state.edited) {
      if (form.formValid()) {
        let requ = {
          Colour: form.values.colourObj.hex,
          Name: form.values.name,
        };
        let result = await this.context.createAnnotationType(requ);
        if (result.valid()) {
          Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPESAVED);
          return true;
        } else {
          Messages.Notify.error(result.errors[0].Message);
          return false;
        }
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
        return false;
      }
    }
    return false;
  }

  handleInput(event: any) {
    this.setState({
      form: this.state.form.change(event.target),
      edited: true,
    });
  }

  handleColourChange(event: any) {
    this.setState({
      form: this.state.form.change({ name: "colourObj", type: "colour", value: event }),
      edited: true,
    });
    this.setState({
      form: this.state.form.change({ name: "name", type: "text", value: this.state.form.values.name }),
      edited: true,
    });
  }

  async deleteAnnotationType(): Promise<boolean> {
    if (
      (await Messages.Dialog.confirm(
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPEDELETE_PROMPT,
        this.context.localization.currentLocale.AnnotationTypeView.ALERT_ANNOTATIONTYPEDELETE_HEADING
      )) === "true"
    ) {
      let form = this.state.form;
      let result = await this.context.deleteAnnotationType({
        Id: form.values.id,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEDELETED);
        return true;
      } else {
        Messages.Notify.error(this.context.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEERROR);
      }
    }
    return false;
  }
  valid(): boolean {
    return this.state.form.formValid();
  }

  render() {
    if (!this.state || !this.state.form) {
      return "";
    }
    let defaultColors = [
      "#FFFF00",
      "#FF33FF",
      "#33FF00",
      "#40E0D0",
      "#66FF00",
      "#00D084",
      "#CCFF00",
      "#0099FF",
      "#0693E3",
      "#7BDCB5",
      "#8ED1FC",
      "#ABB8C3",
      "#DA99FF",
      "#F78DA7",
      "#EB144C",
      "#FF6900",
      "#FCB900",
    ];
    let existingColors = [
      ...new Set(
        this.context.annotationTypes
          .rows()
          .sort((x1, x2) => {
            if (x1.LastUpdate > x2.LastUpdate) {
              return 1;
            }
            if (x1.LastUpdate < x2.LastUpdate) {
              return -1;
            }
            return 0;
          })
          .reverse()
          .map((item) => item.Color.toUpperCase())
      ),
    ];
    let finalColors = [...new Set(existingColors.concat(defaultColors))].slice(0, 17);
    return (
      <Form className={"annotation-view-create-form p-2 form-condensed"}>
        <FormGroup>
          <Input
            type="text"
            name="name"
            value={this.state.form.values.name}
            invalid={this.state.form.invalid("name")}
            onChange={this.handleInput}
            placeholder={"Name"}
          />
          <FormFeedback tooltip={false}>{this.state.form.getErrors("name")}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <div style={{ padding: "8px" }}>
            <span style={{ backgroundColor: this.state.form.values.colourObj.hex }}>
              {this.context.localization.currentLocale.AnnotationTypeView.LABEL_EXAMPLE_ANNOTATION}
            </span>
          </div>
        </FormGroup>
        <div className="colourPickerContainer">
          <TwitterPicker colors={finalColors} color={this.state.form.values.colourObj} width="250" onChangeComplete={this.handleColourChange} />
        </div>
        <Row>
          {this.props.showSave && (
            <Col xs="auto">
              <Button
                onClick={(e: any) => {
                  if (this.state.form.formValid()) {
                    e.preventDefault();
                    this.props.onActionClicked(AnnotationTypeFormActions.save);
                  }
                }}
              >
                {this.context.localization.currentLocale.AnnotationTypeView.LABEL_SAVE}
              </Button>
            </Col>
          )}
          {this.props.goBackClicked && (
            <Col xs="auto">
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.goBackClicked!();
                }}
              >
                {this.context.localization.currentLocale.Application.ALERT_CANCEL}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

enum AnnotationTypeFormActions {
  back,
  delete,
  save,
  create,
}

interface IAnnotationTypeFormToolbarProps extends IModelBinding<AnnotationType | undefined> {
  onActionClicked: (action: AnnotationTypeFormActions) => any;
  localization: Locale;
  helpEnabled: boolean;
}

interface IAnnotationTypeFormToolbarState {
  actionsEnabled: boolean;
}
class AnnotationTypeFormToolbar extends React.Component<IAnnotationTypeFormToolbarProps, IAnnotationTypeFormToolbarState> {
  constructor(props: any) {
    super(props);
    this.onModelUpdate = this.onModelUpdate.bind(this);
    this.state = { actionsEnabled: false };
  }

  onModelUpdate() {
    if (this.props.model.current !== null) {
      this.setState({
        actionsEnabled: true,
      });
    } else {
      this.setState({
        actionsEnabled: false,
      });
    }
  }

  componentDidMount() {
    this.props.model.on(this.onModelUpdate);
  }

  componentWillUnmount() {
    this.props.model.off(this.onModelUpdate);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-for="annotationTypeToolbar" data-tip={this.props.localization.currentLocale.Application.LABEL_BACK}>
              <NavLink className="flip-back" onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.back)}>
                <Icon src={<Image.arrowback />} /> <small>{this.props.localization.currentLocale.AnnotationTypeView.LABEL_ANNOTATIONTYPEVIEW}</small>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav navbar={true}>
          <NavItem data-for="annotationTypeToolbar" data-tip={this.props.localization.currentLocale.Application.LABEL_DELETE}>
            <ActionIcon
              src={<Image.delete_item />}
              enabled={this.state.actionsEnabled}
              onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.delete)}
            />
          </NavItem>
          <NavItem data-for="annotationTypeToolbar" data-tip={this.props.localization.currentLocale.Application.ALERT_SAVE}>
            <ActionIcon src={<Image.save />} enabled={this.state.actionsEnabled} onClick={() => this.props.onActionClicked(AnnotationTypeFormActions.save)} />
          </NavItem>
          {this.props.helpEnabled && <ReactTooltip id="annotationTypeToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
        </Nav>
      </Navbar>
    );
  }
}
