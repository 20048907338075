import * as React from 'react';
import ResizeObserver from 'react-resize-detector/build/withPolyfill';
import * as _ from 'underscore';

const Split = require("react-split").default;

export class VerticalSplitGroup extends React.Component<any, any> {
  render() {
    return (
      <div className="split-group vertical-split-group flex-fill" >
        <Split {..._.omit(this.props, "children")}>{this.props.children}</Split>
      </div>
    );
  }
}

export class SplitPanel extends React.Component<any, any> {
  render() {
    return (
      <div className={`split-panel ${String(this.props.className)}`} {..._.omit(this.props, "children", "className")}>
        {this.props.children}
      </div>
    );
  }
}

export enum MediaQuery {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

export class IFlipProps {
  children: {
    left: React.ReactNode;
    right: React.ReactNode;
  };
  className?: string;
  breakpoint: number;
  isOpen: boolean;
  panelClassName?: {
    left: string;
    right: string;
  };
}

export class IFlipState {
  compactLayout: boolean;
}

export class RightFlip extends React.Component<IFlipProps, IFlipState> {
  private flipContainer = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      compactLayout: false,
    };
    this.onResize = this.onResize.bind(this);
  }

  onResize(width: number) {
    this.setState({
      compactLayout: width < this.props.breakpoint,
    });
  }

  componentDidMount() {
    this.setState({
      compactLayout: this.flipContainer.current!.offsetWidth < this.props.breakpoint,
    });
  }
  render() {
    let openClass = this.props.isOpen ? "flip-open" : "";
    let layoutClass = this.state.compactLayout ? "flip-one-column" : "";
    let panelClassNames = this.props.panelClassName || { left: "", right: "" };
    return (
      <div ref={this.flipContainer} className={`flip flip-right ${String(this.props.className)} ${openClass} ${layoutClass}`}>
        <ResizeObserver handleWidth onResize={this.onResize} />
        <div className={`flip-panel flip-panel-left ${panelClassNames.left}`}>{this.props.children.left}</div>
        <div className={`flip-panel flip-panel-right ${panelClassNames.right}`}>{this.props.children.right}</div>
      </div>
    );
  }
}
