import * as React from 'react';
import { Book } from 'src/models/Book';
import { SearchPrintModel } from 'src/models/UserContent';
import { Gauge } from 'src/ui/foundation/Controls/Gauge/src/Gauge';

export interface ISearchPrintProps {
  model: SearchPrintModel;
  context: Book;
  underline?: boolean;
  omitNotes?: boolean;
  isCollapsed?: boolean;
}

export interface ISearchPrintState {}

export class SearchPrint extends React.PureComponent<ISearchPrintProps, ISearchPrintState> {
  constructor(props: ISearchPrintProps) {
    super(props);
  }

  render() {
    let excerpts: JSX.Element[] = [];
    this.props.model.Excerpts.map((item, index) => {
      if (item.IsHighlight) {
        excerpts.push(
          <span key={index} className="hit">
            {item.Content}
          </span>
        );
      } else {
        excerpts.push(<span key={index}>{item.Content}</span>);
      }
    });
    return (
      <div key={this.props.model.ItemId} className="searchContainer">
        <div className="topBar">
          <div className="meterCell">
            <div className="meter">
              <Gauge color={"var(--meterFillPrimaryColour)"} value={Math.round(this.props.model.Relevancy * 100.0)} width={100} height={60} min={0} label="" />
            </div>
          </div>
          <div className="title">
            <h4 className="title-text">{this.props.model.Title}</h4>
          </div>
        </div>
        <span className="path">{this.props.model.Path}</span>
        {this.props.isCollapsed === false && <div className="excerpt">{excerpts}</div>}
      </div>
    );
  }
}
