import * as React from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { LoginType } from './models/AppSession';
import { LibraryContext } from './ui/state/Contextes';
import { loginRequest } from './utilities/AuthConfig';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SSOHookMiddleWare = ({ loginNeeded }: any) => {
  const { instance, accounts } = useMsal();
  const context = React.useContext(LibraryContext)!;
  if (context.ssoLogout === false && context.ssoIdToken === "" && context.loginType === LoginType.None && useIsAuthenticated()) {
    context.ssoIdToken = "loading...";
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(
        (response) => {
          if (context.ssoIdToken === "loading...") {
            context.ssoIdToken = response.idToken;
            loginNeeded(response.account!.username, context.ssoIdToken);
            context.ssoLogout = false;
          }
        },
        (reason) => {
          context.logInfo(reason.toString());
        }
      );
  }
  if (context.ssoLogout === true && context.ssoIdToken === "") {
    context.ssoIdToken = "loading...";
    instance.logoutRedirect();
  }
  return null;
};
