export enum LibraryCellType {
  OwnedTitle,
  AvailableTitle,
  Product,
}
export enum PurchaseType {
  Subscription,
  Product,
}

export enum LibraryViewMode{
  Grid,
  List,
}

export class UnifiedProduct {
  CurrencyCode: string;
  Description: string;
  FormattedPrice: string;
  Identifier: string;
  Price: number;
  Title: string;
  PurchaseType: PurchaseType;
  SubscriptionExpirationDate: Date;
}

export class LibraryProductCell {
  ProductName: string;
  ProductDescription: string;
  MasterCode: string;
  ProductCodes: string[];
  ImageThumbnail: number[];
  ProductPlans: UnifiedProduct[];
  CellType: LibraryCellType;
  Index: number;
  IsFirst: boolean;
  IsLast: boolean;
}

export class LibraryCell {
  TitleId: number;
  TitleRef: string;
  CellType: LibraryCellType;
  CurrentVersion: LibraryVersion;
  OtherVersions: LibraryVersion[];
  Index: number;
  IsFirst: boolean;
  IsLast: boolean;
  IsConditionallyLicensed: boolean;
  IsFavourite: boolean;
}

export class LibraryVersion {
  VersionId: number;
  VersionRef: string;
  IsDownloaded: boolean;
  IsDownloading: boolean;
  VersionInfo: VersionMeta;
  MoreInfo: string;
  LastViewed: Date;
}

export class VersionMeta {
  CoverImage: number[];
  BackgroundImage: number[];
  Title: string;
  Creator: string;
  Publisher: string;
  Date: Date;
  Description: string;
  Language: string;
  Extent: string;
  Identifier: string;
  Subject: string;
  Type: string;
  Coverage: string;
  Contributor: string;
  Rights: string;
  Source: string;
  Format: string;
  URL: string;
}

export enum VersionTaskType {
  launching,
  downloading,
  syncing,
  installing,
  community,
  transition = -1,
}
export class VersionTask {
  VersionId: number;
  Progress: number;
  Type: VersionTaskType;
  IsPaused: boolean;
  IsCompleted: boolean;
  constructor(versionId: number) {
    this.VersionId = versionId;
  }
}

export enum AccessResult {
  Success = 0,
  OfflineUsageTimeExceeded = 1,
  OfflineOpensExceeded = 2,
  NoAccess = 3,
  NotDownloaded = 4,
  MigrationAvailable = 5,
  UpdateAvailable = 6,
  Bumped = 7,
  AlreadyLoaded = 8,
  OfflineSuccess = 9,
  AlreadyMigrating = 10,
  NativeShutdown = 11,
  NativeSaveLocation = 12,
  ConcurrencyAccessRevoked = 13,
  IpAccessRevoked = 14,
  ConditionalAccessFailure = 15,
  ConnectionFailed = 16,
}

export enum PurchaseResult {
  Success,
  Declined,
  ProcessingError,
  OtherError,
}

export enum Section {
  AvailableTitles,
  OwnedTitles,
  Products,
}
export enum SectionState {
  Expanded,
  Collapsed,
}
export enum LibrarySorting {
  LastViewed,
  Title,
  Publisher,
  Downloads,
}

export enum LibrayToolbarActions {
  refresh,
  multiSearch,
  multiSearchExecute,
  filteringChanged,
  changeViewMode,
}

export class MultiSearchMessage {
  VersionId: number;
  ResultCount: number;
}
