import * as React from 'react';
import { AppSession } from 'src/models/AppSession';

import { AppContext } from './Contextes';

interface IWithThemeState {
  color?: string;
  isLight?: boolean;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function withTheme<Props>(WrappedComponent: any) {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<Props, IWithThemeState> {
    static contextType = AppContext;
    context: AppSession;

    constructor(props: Props) {
      super(props);
      this.update = this.update.bind(this);
      this.state = {};
    }

    componentDidMount() {
      this.context.currentLibraryNotifier.addListener(this.update);
      this.update();
    }
    componentWillUnmount() {
      this.context.currentLibraryNotifier.removeListener(this.update);
    }

    update() {}

    render() {
      return <WrappedComponent color={this.state.color} isLight={this.state.isLight} {...this.props} />;
    }
  };
}
