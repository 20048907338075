export enum Platform {
  web = 1 << 0,
  android = 1 << 1,
  ios = 1 << 2,
  windows = 1 << 3,
  mac = 1 << 4
}
export class Config {
  static platform: Platform;
  static load() {
    switch (process.env.REACT_APP_PLATFORM) {
      case "web":
        Config.platform = Platform.web;
        break;
      case "windows":
        Config.platform = Platform.windows;
        break;
      case "android":
        Config.platform = Platform.android;
        break;
      case "mac":
        Config.platform = Platform.mac;
        break;
      case "ios":
        Config.platform = Platform.ios;
        break;
      default:
        Config.platform = Platform.web; // for npm start
    }
  }
}

Config.load();
