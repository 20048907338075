import classnames from 'classnames';
import * as React from 'react';
import * as _ from 'underscore';

export class Html {
  static unstyledList(items: any[], className?: string) {
    return (
      <ul className={classnames("list-unstyled", className)}>
        {items.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    );
  }

  static definitionList(items: any, labels: { [key: string]: string }, className?: string) {
    let listElements: Array<{ label: string; value: JSX.Element }> = new Array<{ label: string; value: JSX.Element }>();

    _.mapObject(labels, (label, key) => {
      if (items[key] !== undefined && items[key] !== null && items[key] !== "") {
        listElements.push({
          label: label,
          value: items[key],
        });
      }
    });
    return (
      <dl className={classnames(className)}>
        {listElements.map((pair, index) => (
            <React.Fragment key={index}>
              <dt>{pair.label}</dt>
              <dd>{pair.value}</dd>
            </React.Fragment>
          ))}
      </dl>
    );
  }
}
