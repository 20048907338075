import { Dictionary } from 'src/collections/Generics';

import * as EnglishLocale from './Localization_en';
import * as FrenchLocale from './Localization_fr';
import { LocalizationLocale } from './LocalizationLocale';

export class Locale {
  private defaultCulture: Languages = Languages.English;
  currentCulture: Languages;
  private locales: Dictionary<Languages, LocalizationLocale>;
  currentLocale: LocalizationLocale;

  constructor(initLocale?: Languages) {
    this.locales = new Dictionary<Languages, LocalizationLocale>();
    Object.values(Languages).map(it => {
      switch (it) {
        case Languages.English:
          this.locales.set(it, EnglishLocale.Localization);
          break;
        case Languages.French:
          this.locales.set(it, FrenchLocale.Localization);
          break;
      }
    });
    if (initLocale) {
      this.changeLanguage(initLocale);
    } else {
      this.changeLanguage(this.defaultCulture);
    }
    this.changeLanguage = this.changeLanguage.bind(this);
    this.innerCurrentLocale = this.innerCurrentLocale.bind(this);
  }
  changeLanguage(twoLetterLanguageName: Languages) {
    this.currentCulture = twoLetterLanguageName;
    this.currentLocale = this.innerCurrentLocale();
  }
  private innerCurrentLocale(): LocalizationLocale {
    return this.locales.get(this.currentCulture)!;
  }
}
export enum Languages {
  English = 1,
  French = 2
}
