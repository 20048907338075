import * as React from 'react';

import { ArrowDown } from '../foundation/ArrowDown';
import { ArrowUp } from '../foundation/ArrowUp';
import { IScrubberProps, Scrubber } from '../foundation/Scrubber';
import { ContentHandler, IContentReaderState } from '../views/ContentView';

export interface IScrollBarProps {
    onArrowUp: (value: boolean) => void;
    onArrowDown: (value: boolean) => void;
    scrubber: JSX.Element;

}
export class ScrollBar extends React.Component<IScrollBarProps, IContentReaderState, IScrubberProps> {
    private scrubber = React.createRef<Scrubber>();
    content: ContentHandler;
    constructor(props: IScrollBarProps) {
        super(props);
        this.onArrowUp = this.onArrowUp.bind(this);
        this.onArrowDown = this.onArrowDown.bind(this);
        this.openNavigationModal = this.openNavigationModal.bind(this);
        this.scrollChanged = this.scrollChanged.bind(this);
    }

    private onArrowUp() {
        this.props.onArrowUp(true);
    }
    private onArrowDown() {
        this.props.onArrowDown(true);
    }
    private openNavigationModal(value: number) {
        this.setState({
            showScrollModal: true,
            scrollSpine: value,
        });
    }
    private scrollChanged(previousValue: number, value: number) {
        this.setState({
            showScrollModal: true,
            scrollSpine: value,
        });
    }
    render() {
        return (
            <div className="scrollbar-nav">
                <ArrowUp onArrowUp={this.onArrowUp} />
                {
                    this.props.scrubber
                }
                <ArrowDown onArrowDown={this.onArrowDown} />
            </div>
        )
    }
}
