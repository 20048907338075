import 'src/assets/css/app.css';

import * as React from 'react';
import { AppSession } from 'src/models/AppSession';
import { AppContext } from 'src/ui/state/Contextes';
import { AppView } from 'src/ui/views/AppView';
import { msalConfig } from 'src/utilities/AuthConfig';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { ResourcePlatform } from './utilities/Helpers';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
let msalInstance : PublicClientApplication;
class App extends React.Component<unknown, { app: AppSession }> {
  constructor(props: any) {
    super(props);
    this.state = {
      app: AppSession.current,
    };
    if (ResourcePlatform.UsesWebSso()) {
      msalInstance = new PublicClientApplication(msalConfig);
      AppSession.current.MSALInstance = msalInstance;
    }
  }

  public render() {
    if (ResourcePlatform.UsesWebSso()) {
      return (
        <MsalProvider instance={msalInstance}>
          <AppContext.Provider value={this.state.app}>
            <AppView />
          </AppContext.Provider>
        </MsalProvider>
      );
    } else {
      return (
        <AppContext.Provider value={this.state.app}>
          <AppView />
        </AppContext.Provider>
      );
    }
  }
}

export default App;
