import * as React from 'react';

import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { TextField } from '@material-ui/core';

const TableIdWithOffset = (props: ControlProps) => {
  if (props.enabled) {
    return null;
  }
  return <TextField
    style={{ width: "100%" }}
    variant="standard"
    label={props.label}
    InputProps={{
      readOnly: true,
    }}
    placeholder={props.label.toString()}
    value={props.data}
  />;
};
export default withJsonFormsControlProps(TableIdWithOffset);
