import * as React from 'react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../../../utilities/AuthConfig';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SSOSignInButton = ({ children }: any) => {
  const { instance } = useMsal();

  const handleLogin = (ev: any) => {
    ev.preventDefault();
    
    instance.loginRedirect(loginRequest).catch((
      ex
    ) => {
      console.log(ex);
    });
  };
  return (
    <div onClick={(e: any) => handleLogin(e)} className="ssoLoginButton">
      {children}
    </div>
  );
};
