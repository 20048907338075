import * as React from 'react';
import { Book } from 'src/models/Book';
import { Annotation, Favourite } from 'src/models/UserContent';

import { BookContext } from '../state/Contextes';
import { AnnotationList } from '../views/AnnotationView';
import { FavouriteList } from '../views/FavouriteView';

interface IUserContentMultiSelectorProps {}

interface IUserContentMultiSelectorState {
  isOpen: boolean;
  currentAnnos: Annotation[] | null;
  currentFaves: Favourite[] | null;
}

export class UserContentMultiSelector extends React.Component<IUserContentMultiSelectorProps, IUserContentMultiSelectorState> {
  context: Book;
  static contextType = BookContext;
  constructor(props: IUserContentMultiSelectorProps) {
    super(props);

    this.state = { isOpen: false, currentAnnos: null, currentFaves: null };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.itemSelected = this.itemSelected.bind(this);
  }

  hide() {
    this.setState({ isOpen: false });
  }
  show(annoIDs: number[], faveIDs: number[]) {
    let annos: Annotation[] = [];
    let faves: Favourite[] = [];
    annoIDs.map((x) => {
      let anno = this.context.annotations.get(+x);
      if (anno) {
        annos.push(anno);
      }
    });
    annos.sort((x1, x2) => {
      if (x1.LastUpdate > x2.LastUpdate) {
        return -1;
      }
      if (x1.LastUpdate < x2.LastUpdate) {
        return 1;
      }
      return 0;
    });
    faveIDs.map((x) => {
      let fave = this.context.favourites.get(+x);
      if (fave) {
        faves.push(fave);
      }
    });
    faves.sort((x1, x2) => {
      if (x1.LastUpdate > x2.LastUpdate) {
        return -1;
      }
      if (x1.LastUpdate < x2.LastUpdate) {
        return 1;
      }
      return 0;
    });
    this.setState({ isOpen: true, currentAnnos: annos, currentFaves: faves });
  }

  itemSelected(item: Annotation | Favourite) {
    this.hide();
    if (item.hasOwnProperty("Note")) {
      this.context.annotationContentClicked.dispatch([item.Id], this);
    } else {
      this.context.favouriteContentClicked.dispatch([item.Id], this);
    }
  }

  render() {
    if (this.state.isOpen && (this.state.currentAnnos || this.state.currentFaves)) {
      let annoSection;
      let faveSection;
      if (this.state.currentAnnos) {
        annoSection = <AnnotationList items={this.state.currentAnnos} onItemClicked={this.itemSelected} selectedAnnotationIDs={[]} />;
      }
      if (this.state.currentFaves) {
        faveSection = <FavouriteList items={this.state.currentFaves} onItemClicked={this.itemSelected} selectedFavouriteIDs={[]} localization={this.context.localization} />;
      }

      return (
        <div>
          <div className="unbound-form-modal" onClick={this.hide} />
          <div className="unbound-form-container">
            {this.state.currentAnnos && this.state.currentAnnos.length ? <h3>Annotations</h3> : ""}
            <div className="ucContainer">{annoSection}</div>
            {this.state.currentFaves && this.state.currentFaves.length ? <h3>Favourites</h3> : ""}
            <div className="ucContainer">{faveSection}</div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
