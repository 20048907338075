import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Collapse, Nav, Navbar, NavItem } from 'reactstrap';
import { IDictionary, ImmutableDictionary } from 'src/collections/Generics';
import { Locale } from 'src/localization/Locale';
import { FontSizes } from 'src/models/AppSession';
import { Book, BookLoadingSteps, NavigationInitiator } from 'src/models/Book';
import { ExtendedHeader, FlattenTocNode, NavigationRequest, TocNode } from 'src/models/Content';
import { Books } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import { ActionIcon, Expander, Icon } from '../foundation/Controls';
import { BookContext } from '../state/Contextes';

interface ITocHighlightInfo {
  targetSpine: number;
  parentSpines: Set<number>;
}

export interface ISelectionInfo {
  selectedSegmentIds: Set<number>;
  /**
   * All of the parents that are not selected but contain selected children.
   * The nested maps are the SelectionState of all of the children of the node.
   */
  partiallySelectedIds: Map<number, Map<number, SelectionState>>;
}

export interface ITOCViewState {
  checkboxesActive: boolean;
  filteringActive: boolean;
  highlightInfo: ITocHighlightInfo;
  selectionInfo: ISelectionInfo;
  searchResults: IDictionary<string | number, ExtendedHeader>;
  tocRoot: TocNode[];
  currentFontsize: FontSizes;
}
export interface ITOCViewProps {
  isActive: boolean;
}
export class TOCView extends React.Component<ITOCViewProps, ITOCViewState> {
  context: Book;
  static contextType = BookContext;
  children: Array<React.RefObject<TOCNodeItem>>;

  private scrollContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      checkboxesActive: false,
      filteringActive: false,
      highlightInfo: {
        targetSpine: 1,
        parentSpines: new Set<number>(),
      },
      selectionInfo: {
        selectedSegmentIds: new Set<number>(),
        partiallySelectedIds: new Map<number, Map<number, SelectionState>>(),
      },
      searchResults: new ImmutableDictionary(),
      tocRoot: [],
      currentFontsize: FontSizes.Normal,
    };

    this.scrollContainerRef = React.createRef();

    this.onToolbarCollapse = this.onToolbarCollapse.bind(this);
    this.update = this.update.bind(this);
    this.searchUpdate = this.searchUpdate.bind(this);
    this.onReady = this.onReady.bind(this);
    this.navigateTOC = this.navigateTOC.bind(this);
    this.onCheckboxToggle = this.onCheckboxToggle.bind(this);
    this.onFilteringToggle = this.onFilteringToggle.bind(this);
    this.onCenterOnHighlighted = this.onCenterOnHighlighted.bind(this);
    this.onClearSelection = this.onClearSelection.bind(this);
    this.notifyParentOfSelectionChange = this.notifyParentOfSelectionChange.bind(this);
    this.updateParentSelectionState = this.updateParentSelectionState.bind(this);
    this.updateListOfPartiallySelectedNodes = this.updateListOfPartiallySelectedNodes.bind(this);
    this.removeFromPartialSelectionMap = this.removeFromPartialSelectionMap.bind(this);
    this.highlightChanged = this.highlightChanged.bind(this);
    this.setFilterState = this.setFilterState.bind(this);
    this.settingsChanged = this.settingsChanged.bind(this);
  }
  async onReady() {
    this.context.stepLoading.dispatch(BookLoadingSteps.tableOfContents, this);
    this.context.tocRoot.addListener(this.update);
    this.context.currentTocResults.addListener(this.searchUpdate);
    await this.context.initTOC();
    if (this.context.tocRoot.rows().length) {
      this.setState({ tocRoot: this.context.tocRoot.rows(), currentFontsize: this.context.appSettings.get().TOCFontSize });
      this.context.loading.setLoaded(BookLoadingSteps.tableOfContents);
    }
  }

  componentDidMount() {
    this.context.loading.stepLoading.on(BookLoadingSteps.tableOfContents, this.onReady);
    this.context.locationInBookChanged.on(this.highlightChanged);
    this.context.searchFilteringChanged.on(this.setFilterState);
    this.context.appSettings.addListener(this.settingsChanged);
  }
  componentWillUnmount() {
    this.context.appSettings.removeListener(this.settingsChanged);
    this.context.tocRoot.removeListener(this.update);
    this.context.currentTocResults.removeListener(this.searchUpdate);
    this.context.locationInBookChanged.off(this.highlightChanged);
    this.context.searchFilteringChanged.off(this.setFilterState);
  }
  shouldComponentUpdate(nextProps: any): boolean {
    return nextProps.isActive;
  }
  settingsChanged() {
    if (this.state.currentFontsize !== this.context.appSettings.get().TOCFontSize) {
      this.setState({ currentFontsize: this.context.appSettings.get().TOCFontSize });
    }
  }
  onToolbarCollapse() {
    this.children.map((item) => {
      item.current!.toolbarCollapseFired();
    });
  }
  onCheckboxToggle() {
    this.setState({ checkboxesActive: !this.state.checkboxesActive });
  }
  setFilterState(newVal: boolean) {
    this.setState({ filteringActive: newVal });
  }
  onFilteringToggle() {
    this.context.searchFilteringChanged.dispatch(!this.state.filteringActive, this);
  }
  onCenterOnHighlighted() {
    if (!this.scrollContainerRef.current) {
      return;
    }
    const nodes = this.scrollContainerRef.current.querySelectorAll("." + HighlightClass.PartialHighlighted);
    let elem = this.scrollContainerRef.current.querySelector("." + HighlightClass.Highlighted) as HTMLElement;
    let last = nodes[nodes.length - 1] as HTMLElement;
    if (
      elem &&
      last &&
      elem.getBoundingClientRect().top + elem.getBoundingClientRect().height - last.getBoundingClientRect().top >
        this.scrollContainerRef.current.getBoundingClientRect().height
    ) {
      elem.scrollIntoView();
    } else if (last) {
      last.scrollIntoView();
    } else {
      if (elem) {
        elem.scrollIntoView();
      }
    }
  }
  navigateTOC(node: TocNode) {
    this.context.contentNavigation(NavigationRequest.toHeader(node.Current.Id), NavigationInitiator.toc);
  }
  onClearSelection() {
    this.setState({
      filteringActive: false,
      selectionInfo: {
        selectedSegmentIds: new Set<number>(),
        partiallySelectedIds: new Map<number, Map<number, SelectionState>>(),
      },
    });
    this.context.currentFilter.clear();
  }

  update() {}

  searchUpdate() {
    this.setState({ searchResults: this.context.currentTocResults.pairedRows });
  }

  setChildDocs(node: TocNode, curr: Set<number>) {
    curr.add(node.Current.Id);
    node.Children.map((it) => {
      this.setChildDocs(it, curr);
    });
  }

  notifyParentOfSelectionChange(node: TocNode, newState: SelectionState) {
    // Recursively find document IDs and add or remove them depending on how the node was modified. This constitutes the filter.
    let affectedDocuments = new Set<number>();
    this.setChildDocs(node, affectedDocuments);
    let changes = [];

    // Once all child have their new selection state, remove them from the partially selected nodes.
    this.removeFromPartialSelectionMap(affectedDocuments);
    // Then, propagate the change to the parent nodes.
    this.updateParentSelectionState(node.Current, newState);
    // After that, we persist the new selection state.
    if (newState === SelectionState.Selected) {
      changes = [...this.state.selectionInfo.selectedSegmentIds, ...affectedDocuments];
    } else {
      changes = [...this.state.selectionInfo.selectedSegmentIds].filter((num) => !affectedDocuments.has(num));
    }
    this.context.currentFilter = new Set<number>(changes);
    // Finally, update the state to rerender all children. This is done even though the state already
    // been mutated in order to rerender the affected TocNodes.
    this.setState({
      selectionInfo: {
        selectedSegmentIds: this.context.currentFilter,
        partiallySelectedIds: this.state.selectionInfo.partiallySelectedIds,
      },
    });
  }

  private updateParentSelectionState(child: ExtendedHeader, childState: SelectionState) {
    // There is no need to update the parent if it does not exist.
    if (!child.Parent || !this.context.tocHeaderIdToHeader.has(child.Parent)) {
      return;
    }
    const parentNode = this.context.tocHeaderIdToHeader.get(child.Parent)!;
    // First, we update the list of partially selected nodes for the parent.
    this.updateListOfPartiallySelectedNodes(child, childState);
    // Then, we compute the new state of the parent
    const newParentSelectionState = this.computeSelectionStateOfNode(parentNode);
    // After that, we update the list of selected nodes if need be
    if (newParentSelectionState === SelectionState.Selected) {
      this.state.selectionInfo.selectedSegmentIds.add(child.Parent);
    } else {
      this.state.selectionInfo.selectedSegmentIds.delete(child.Parent);
    }
    // Finally, we propagate the changes to the parent's ancestor.
    this.updateParentSelectionState(parentNode.Current, newParentSelectionState);
  }

  private updateListOfPartiallySelectedNodes(child: ExtendedHeader, childState: SelectionState) {
    // There is no need to update the parent if it does not exist.
    if (!child.Parent || !this.context.tocHeaderIdToHeader.has(child.Parent)) {
      return;
    }
    const parentId = child.Parent;
    // We update the list of partially selected nodes to reflect the new selection
    if (this.state.selectionInfo.partiallySelectedIds.has(parentId)) {
      const childrenSelectionState = this.state.selectionInfo.partiallySelectedIds.get(parentId)!;
      if (childState === SelectionState.Unselected) {
        childrenSelectionState.delete(child.Id);
        if (childrenSelectionState.size === 0) {
          this.state.selectionInfo.partiallySelectedIds.delete(parentId);
        }
      } else {
        childrenSelectionState.set(child.Id, childState);
      }
    } else {
      // If the child has been unselected or partially selected and the parent is in the list of selected nodes,
      // add the parent to the list of partially selected nodes with the selection state of
      // all of the other children.
      if (
        (childState === SelectionState.Unselected || childState === SelectionState.PartialSelected) &&
        this.state.selectionInfo.selectedSegmentIds.has(parentId)
      ) {
        const parentNode = this.context.tocHeaderIdToHeader.get(parentId)!;
        const selectedChildren = parentNode.Children.filter((c) => c.Id !== child.Id);
        if (selectedChildren.length > 1) {
          this.state.selectionInfo.partiallySelectedIds.set(parentId, new Map(selectedChildren.map((c) => [c.Id, SelectionState.Selected])));
        }
      } else {
        this.state.selectionInfo.partiallySelectedIds.set(parentId, new Map([[child.Id, childState]]));
      }
    }
  }

  private computeSelectionStateOfNode(node: FlattenTocNode): SelectionState {
    // Unselected
    if (!this.state.selectionInfo.partiallySelectedIds.has(node.Current.Id)) {
      return SelectionState.Unselected;
    } else {
      // Check if all children are selected
      let areEveryChildrenSelected = true;
      const selectedChildren = this.state.selectionInfo.partiallySelectedIds.get(node.Current.Id)!;
      for (const selectedChild of selectedChildren.values()) {
        if (selectedChild !== SelectionState.Selected) {
          areEveryChildrenSelected = false;
          break;
        }
      }
      // Full selection
      if (selectedChildren.size === node.Children.length && areEveryChildrenSelected) {
        return SelectionState.Selected;
      }
      // Partial selection
      else {
        return SelectionState.PartialSelected;
      }
    }
  }

  /**
   * Removes the given nodes from the partially selected nodes map.
   * This method does not propagate itself to the parent nodes.
   */
  private removeFromPartialSelectionMap(nodeIds: Iterable<number>) {
    for (const nodeId of nodeIds) {
      this.state.selectionInfo.partiallySelectedIds.delete(nodeId);
    }
  }

  private highlightChanged(spineToHightlight: number) {
    // Finding the header corresponding to this spine. Spines can be headers or segments.
    const headerSpine = Books.findCorrespondingHeader(this.context.tocSortedHeaderSpineIds, spineToHightlight);
    // Only apply the highlight if the highlight does not target the currently selected header.
    if (this.state.highlightInfo.targetSpine !== headerSpine) {
      const allParentsToHighlight = new Set<number>();
      let current = this.context.tocSpineIdToHeader.get(headerSpine);

      while (current && this.context.tocHeaderToSpine.has(current.Current.Parent)) {
        allParentsToHighlight.add(this.context.tocHeaderToSpine.get(current.Current.Parent)!);
        current = this.context.tocHeaderIdToHeader.get(current.Current.Parent);
      }

      this.setState({
        highlightInfo: {
          targetSpine: headerSpine,
          parentSpines: allParentsToHighlight,
        },
      });
    }
  }

  render() {
    this.children = [];
    let currentFontSizeClass = "";
    switch (this.state.currentFontsize) {
      case FontSizes.Smallest:
        currentFontSizeClass = "font-smallest";
        break;
      case FontSizes.Smaller:
        currentFontSizeClass = "font-smaller";
        break;
      case FontSizes.Normal:
        currentFontSizeClass = "font-medium";
        break;
      case FontSizes.Larger:
        currentFontSizeClass = "font-larger";
        break;
      case FontSizes.Largest:
        currentFontSizeClass = "font-largest";
        break;
    }

    return (
      <div className="toc-view full-height d-flex flex-column position-relative">
        <TOCToolbar
          onClearSelection={this.onClearSelection}
          onLinkingToggle={this.onCenterOnHighlighted}
          filteringActive={this.state.filteringActive}
          onFilteringToggle={this.onFilteringToggle}
          onCheckboxToggle={this.onCheckboxToggle}
          checkboxesActive={this.state.checkboxesActive}
          onCollapse={this.onToolbarCollapse}
          helpEnabled={this.context.appSettings ? this.context.appSettings.get().HelpEnabled : true}
          localization={this.context.localization}
        />
        <div className="flex-fill scrollable" ref={this.scrollContainerRef}>
          {this.context.tocRoot.rows().map((node) => {
            let ref = React.createRef<TOCNodeItem>();
            this.children.push(ref);
            return (
              <TOCNodeItem
                searchResults={this.state.searchResults}
                onSelectionChanged={this.notifyParentOfSelectionChange}
                selectionInfo={this.state.selectionInfo}
                checkboxesActive={this.state.checkboxesActive}
                highlightInfo={this.state.highlightInfo}
                ref={ref}
                navigateTOC={this.navigateTOC}
                initialOpen={true}
                key={node.Current.Id}
                currentNode={node}
                fontsizeClass={currentFontSizeClass}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export interface ITOCNodeItemProps {
  currentNode: TocNode;
  initialOpen: boolean;
  selectionInfo: ISelectionInfo;
  checkboxesActive: boolean;
  highlightInfo: ITocHighlightInfo | null;
  navigateTOC: (node: TocNode) => any;
  onSelectionChanged: (node: TocNode, newState: SelectionState) => any;
  searchResults: IDictionary<string | number, ExtendedHeader>;
  fontsizeClass: string;
}
export interface ITOCNodeItemState {
  isExpanded: boolean;
  hasOpened: boolean; // State indicating that an expansion has occurred (ergo children were rendered explicitely) so collapsing and re-expanding that node doesn't unrender->rerender it.
}
export class TOCNodeItem extends React.PureComponent<ITOCNodeItemProps, ITOCNodeItemState> {
  children: Array<React.RefObject<TOCNodeItem>>;
  maxCollapseDepth = 1;
  constructor(props: any) {
    super(props);
    this.state = { isExpanded: this.props.initialOpen, hasOpened: false };
    this.expand = this.expand.bind(this);
    this.navigateTOC = this.navigateTOC.bind(this);
    this.toolbarCollapseFired = this.toolbarCollapseFired.bind(this);
    this.selectNode = this.selectNode.bind(this);
    this.expandIfHighlighted = this.expandIfHighlighted.bind(this);
    this.getHightlightState = this.getHightlightState.bind(this);
  }

  componentDidMount() {
    this.expandIfHighlighted();
  }

  componentDidUpdate(prevProps: ITOCNodeItemProps) {
    if (prevProps.highlightInfo !== this.props.highlightInfo) {
      this.expandIfHighlighted();
    }
  }

  expand() {
    this.setState({ isExpanded: !this.state.isExpanded });
    if (!this.state.hasOpened) {
      this.setState({ hasOpened: true });
    }
  }
  navigateTOC(node: TocNode) {
    this.props.navigateTOC(node);
  }
  toolbarCollapseFired() {
    if (this.props.currentNode.Depth > this.maxCollapseDepth - 1) {
      this.setState({ isExpanded: false });
    } else if (this.props.currentNode.Depth <= this.maxCollapseDepth) {
      this.children.map((item) => {
        item.current!.toolbarCollapseFired();
      });
      this.setState({ isExpanded: true });
    }
  }
  selectNode(node: TocNode, currentState: SelectionState) {
    let newState = currentState;
    if (currentState === SelectionState.Selected) {
      newState = SelectionState.Unselected;
    } else if (currentState === SelectionState.Unselected) {
      newState = SelectionState.Selected;
    } else if (currentState === SelectionState.PartialSelected) {
      newState = SelectionState.Selected;
    }

    this.props.onSelectionChanged(node, newState);
  }
  private getCurrentSelectionState(): SelectionState {
    const nodeId = this.props.currentNode.Current.Id;
    if (this.props.selectionInfo.selectedSegmentIds.has(nodeId)) {
      return SelectionState.Selected;
    } else if (this.props.selectionInfo.partiallySelectedIds.has(nodeId)) {
      return SelectionState.PartialSelected;
    } else {
      return SelectionState.Unselected;
    }
  }

  private expandIfHighlighted() {
    if (this.props.highlightInfo && this.props.highlightInfo.parentSpines.has(this.props.currentNode.Current.SpineId)) {
      if (!this.state.isExpanded) {
        this.expand();
      }
    }
  }

  private getHightlightState() {
    let highlightState: HighlightState;
    if (this.props.highlightInfo && this.props.highlightInfo.targetSpine === this.props.currentNode.Current.SpineId) {
      highlightState = HighlightState.Highlighted;
    } else if (this.props.highlightInfo && this.props.highlightInfo.parentSpines.has(this.props.currentNode.Current.SpineId)) {
      highlightState = HighlightState.PartialHighlighted;
    } else {
      highlightState = HighlightState.NotHighlighted;
    }

    return highlightState;
  }

  render() {
    this.children = [];
    const highlightState = this.getHightlightState();

    return (
      <div className="toc-node-container">
        <TOCNodeItemHeader
          searchResults={this.props.searchResults}
          onSelectionChanged={this.selectNode}
          selectionState={this.getCurrentSelectionState()}
          checkboxesActive={this.props.checkboxesActive}
          highlightState={this.getHightlightState()}
          initialOpen={this.state.isExpanded}
          expandClicked={this.expand}
          navigateTOC={this.navigateTOC}
          currentNode={this.props.currentNode}
          fontsizeClass={this.props.fontsizeClass}
        />
        {this.state.isExpanded && this.props.currentNode.Children.length > 0 && (
          <div className="toc-node-children">
            <Expander isOpen={this.state.isExpanded} className="bg-light border-top border-bottom">
              {this.props.currentNode.Children.map((node) => {
                let ref = React.createRef<TOCNodeItem>();
                this.children.push(ref);
                return (
                  <TOCNodeItem
                    searchResults={this.props.searchResults}
                    onSelectionChanged={this.props.onSelectionChanged}
                    selectionInfo={this.props.selectionInfo}
                    checkboxesActive={this.props.checkboxesActive}
                    // Don't send highlight info to child if not relevant. This check prevents unnecessary rerenders on cogniflow scroll.
                    highlightInfo={highlightState === HighlightState.PartialHighlighted ? this.props.highlightInfo : null}
                    ref={ref}
                    navigateTOC={this.navigateTOC}
                    initialOpen={false}
                    key={node.Current.Id}
                    currentNode={node}
                    fontsizeClass={this.props.fontsizeClass}
                  />
                );
              })}
            </Expander>
          </div>
        )}
      </div>
    );
  }
}
export enum SelectionState {
  Selected,
  PartialSelected,
  Unselected,
}

export enum HighlightState {
  Highlighted,
  PartialHighlighted,
  NotHighlighted,
}

export enum HighlightClass {
  Highlighted = "highlighted",
  PartialHighlighted = "child-highlighted",
}

export interface ITOCNodeItemHeaderProps {
  currentNode: TocNode;
  initialOpen: boolean;
  checkboxesActive: boolean;
  selectionState: SelectionState;
  highlightState: HighlightState;
  expandClicked: (node: TocNode) => any;
  navigateTOC: (node: TocNode) => any;
  onSelectionChanged: (node: TocNode, currentState: SelectionState) => any;
  searchResults: IDictionary<string | number, ExtendedHeader>;
  fontsizeClass: string;
}
export interface ITOCNodeItemHeaderState {}
export class TOCNodeItemHeader extends React.PureComponent<ITOCNodeItemHeaderProps, ITOCNodeItemHeaderState> {
  constructor(props: any) {
    super(props);
    this.expand = this.expand.bind(this);
    this.navigateTOC = this.navigateTOC.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  expand(event: any) {
    // Dont propagate to the Div click. We want the expand to occur but not the navigate.
    event.stopPropagation();
    this.props.expandClicked(this.props.currentNode);
  }

  onSelectionChanged(event: any) {
    // Dont propagate to the Div click. We want the expand to occur but not the navigate.
    event.stopPropagation();
    this.props.onSelectionChanged(this.props.currentNode, this.props.selectionState);
  }

  navigateTOC() {
    this.props.navigateTOC(this.props.currentNode);
  }

  render() {
    let checkbox: JSX.Element | string = "";
    if (this.props.checkboxesActive) {
      let icon: JSX.Element;
      if (this.props.selectionState === SelectionState.Selected) {
        icon = <Icon src={<Image.TOCcheckboxchecked />} />;
      } else if (this.props.selectionState === SelectionState.PartialSelected) {
        icon = <Icon src={<Image.TOCcheckboxpartial />} />;
      } else {
        icon = <Icon src={<Image.TOCcheckboxunchecked />} />;
      }
      checkbox = <span onClick={this.onSelectionChanged}>{icon}</span>;
    }
    let hits: JSX.Element | string = "";
    const tocHitResult = this.props.searchResults.get(this.props.currentNode.Current.Id);
    if (tocHitResult) {
      if (tocHitResult.HitCount > 0) {
        hits = <span className={"text-danger pl-1 " + this.props.fontsizeClass}>({tocHitResult.HitCount})</span>;
      }
    }

    let highlightedClass = "";
    if (this.props.highlightState === HighlightState.Highlighted) {
      highlightedClass = " " + HighlightClass.Highlighted;
    } else if (this.props.highlightState === HighlightState.PartialHighlighted) {
      highlightedClass = " " + HighlightClass.PartialHighlighted;
    }
    let it: any = "";
    if (this.props.currentNode.Children.length) {
      // Has Children?
      if (this.props.initialOpen) {
        // Has children and is expanded?{
        it = (
          <span className="toc-icon-container" onClick={this.expand}>
            <Icon src={<Image.TOCminus />} />
          </span>
        ); // Has children and is expanded, show minus
      } else {
        it = (
          <span className="toc-icon-container" onClick={this.expand}>
            <Icon src={<Image.TOCplus />} />
          </span>
        );
      }
    } else {
      it = (
        <span className="toc-icon-container" onClick={this.expand}>
          <Icon src={<Image.TOCdash />} />
        </span>
      );
    } // Has children and is not expanded, show plus

    return (
      <div className="d-flex" onClick={this.navigateTOC}>
        {it}

        {
          // Possible checkboxes:
          checkbox
        }

        {
          // Possible hits:
          hits
        }

        <span className={"toc-node-value " + highlightedClass + " " + this.props.fontsizeClass}>{this.props.currentNode.Current.Value}</span>
      </div>
    );
  }
}

interface ITOCToolbarState {}

interface ITOCToolbarProps {
  onCollapse: () => any;
  onCheckboxToggle: () => any;
  onFilteringToggle: () => any;
  onLinkingToggle: () => any;
  onClearSelection: () => any;
  checkboxesActive: boolean;
  filteringActive: boolean;
  helpEnabled: boolean;
  localization: Locale;
}
class TOCToolbar extends React.Component<ITOCToolbarProps, ITOCToolbarState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Navbar color="light" light={true} expand="xs">
        <Collapse isOpen={true} navbar={true}>
          <Nav navbar={true}>
            <NavItem data-for="tocToolbar" data-tip={this.props.localization.currentLocale.TableofContentsView.LABEL_TOC_COLLAPSE}>
              <ActionIcon onClick={this.props.onCollapse} src={<Image.TOCcollapse />} />
            </NavItem>
            <NavItem data-for="tocToolbar" data-tip={this.props.localization.currentLocale.TableofContentsView.LABEL_LINKING}>
              <ActionIcon onClick={this.props.onLinkingToggle} src={<Image.target />} active={false} />
            </NavItem>
            <NavItem data-for="tocToolbar" data-tip={this.props.localization.currentLocale.TableofContentsView.LABEL_TOC_TOGGLEINPUTS}>
              <ActionIcon
                onClick={this.props.onCheckboxToggle}
                src={this.props.checkboxesActive ? <Image.TOCcheckboxchecked /> : <Image.TOCcheckboxunchecked />}
                active={this.props.checkboxesActive}
              />
            </NavItem>
            {this.props.checkboxesActive && (
              <NavItem data-for="tocToolbar" data-tip={this.props.localization.currentLocale.TableofContentsView.LABEL_TOC_FILTERING}>
                <ActionIcon
                  onClick={this.props.onFilteringToggle}
                  src={this.props.filteringActive ? <Image.filteron /> : <Image.filteroff />}
                  active={this.props.filteringActive}
                />
                {this.props.helpEnabled && <ReactTooltip id="tocToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
              </NavItem>
            )}
            {this.props.checkboxesActive && (
              <NavItem data-for="tocToolbar" data-tip={this.props.localization.currentLocale.TableofContentsView.LABEL_TOC_CLEARALL}>
                <ActionIcon onClick={this.props.onClearSelection} src={<Image.TOCbroom />} />
                {this.props.helpEnabled && <ReactTooltip id="tocToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
              </NavItem>
            )}
            {this.props.helpEnabled && <ReactTooltip id="tocToolbar" place="bottom" type="info" effect="solid" className="primaryColoured" />}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
