import 'react-mde/lib/styles/css/react-mde-all.css';

import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import ReactMde, { Command } from 'react-mde';
import { CommandMap, ToolbarCommands } from 'react-mde/lib/definitions/types';
import { Book, NavigationInitiator } from 'src/models/Book';
import { NavigationRequest, NavigationRequestType } from 'src/models/Content';
import { BookContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';
import * as _ from 'underscore';

import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { Image } from '../Assets';

const Gfm = require("remark-gfm");

const routeClick = (e: MouseEvent, book: Book) => {
  let target = (e.target as HTMLElement).closest("a[href]");
  if (target) {
    onLinkClicked(target, e, book);
    return;
  }
};
const processContentLink = (href: string, book: Book) => {
  let lastPart = href.substring(href.lastIndexOf("/") + 1);
  const splitter = lastPart.indexOf("?");
  if (splitter <= -1) {
    return;
  }
  let action = lastPart.substring(0, splitter);
  let param = lastPart.substring(splitter + 1);
  let request: NavigationRequest | undefined;
  let headerId: number | undefined;
  switch (action) {
    case "NAV":
      headerId = parseInt(param);
      if (_.isFinite(headerId)) {
        request = NavigationRequest.toHeader(headerId);
      }
      break;
    case "POP":
      headerId = parseInt(param);
      if (_.isFinite(headerId)) {
        request = NavigationRequest.toHeader(headerId);
        request.Type = NavigationRequestType.popup;
      }
      break;
    case "NAVSEL":
      const selectorSplitter = param.indexOf(",");
      if (selectorSplitter > -1) {
        headerId = -1;
        if (isNaN(Number(param.substring(0, selectorSplitter)))) {
          let persistentDoc = param.substring(param.indexOf(":") + 1, selectorSplitter - 1);
          book.convertPersistentDoc(persistentDoc).then((res) => {
            let selector = param.substring(selectorSplitter + 1);
            if (res.valid()) {
              request = NavigationRequest.toSelector(res.data.HeadId, selector);
              book.contentNavigation(request, NavigationInitiator.link);
            }
          });
          return;
        } else {
          headerId = parseInt(param.substring(0, selectorSplitter));
          let selector = param.substring(selectorSplitter + 1);

          if (_.isFinite(headerId)) {
            request = NavigationRequest.toSelector(headerId, selector);
          }
        }
      }
      break;
    case "RES":
      book.requestResource(param);
      break;
  }

  if (request) {
    book.contentNavigation(request, NavigationInitiator.link);
  }
};

const onLinkClicked = (sender: any, e: MouseEvent, book: Book) => {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  if (!sender.getAttribute("href")) {
    return;
  }
  let hrefValue: string = sender.getAttribute("href")!.trim();
  if (hrefValue.startsWith("prolibro-")) {
    book.reportExternalLink({ Url: hrefValue });
  } else if (hrefValue.indexOf("http") === 0 || hrefValue.indexOf("https") === 0) {
    book.openExternalWebsite(hrefValue, hrefValue);
  } else {
    processContentLink(hrefValue, book);
  }
};

const resizeIframe = (obj: React.SyntheticEvent, book: Book, prevHeight: number) => {
  if ((obj.target as HTMLIFrameElement)!.contentWindow !== null && (obj.target as HTMLIFrameElement)!.contentWindow!.document.documentElement !== null) {
    (obj.target as HTMLIFrameElement).contentDocument!.removeEventListener("click", (e) => routeClick(e, book));
    (obj.target as HTMLIFrameElement).contentDocument!.addEventListener("click", (e) => routeClick(e, book));
  }
  setTimeout(() => {
    if ((obj.target as HTMLIFrameElement)!.contentWindow !== null && (obj.target as HTMLIFrameElement)!.contentWindow!.document.documentElement !== null) {
      let heightTarget = (obj.target as HTMLIFrameElement)!.contentWindow!.document.documentElement.children[1].scrollHeight * 1.3;
      if (prevHeight !== heightTarget) {
        (obj.target as HTMLIFrameElement).style.height = heightTarget + "px";
        (obj.target as HTMLIFrameElement).style.minHeight = "40pt";
        if (prevHeight === 0) {
          (obj.target as HTMLIFrameElement)!.closest(".innerCommunity")!.scrollTop = 0;
        }
        prevHeight = heightTarget;
      }
    }
    resizeIframe(obj, book, prevHeight);
  }, 200);
};
const markdownLinkOverride = (e: React.MouseEvent, book: Book) => {
  e.preventDefault();
  e.stopPropagation();
  let sender = e.target as HTMLAnchorElement;
  if (!sender.getAttribute("href")) {
    return;
  }
  let hrefValue: string = sender.getAttribute("href")!.trim();
  if (hrefValue === "url") {
    hrefValue = sender.innerText;
  }
  let finalLink = Convert.DeepLinkUrlToNative(hrefValue);
  if (finalLink !== null) {
    book.reportExternalLink({ Url: finalLink });
  } else if (hrefValue.indexOf("http") === 0 || hrefValue.indexOf("https") === 0) {
    book.openExternalWebsite(hrefValue, hrefValue);
  } else {
    processContentLink(hrefValue, book);
  }
};
const markdownLinkClicked = () => "webMedia";

let customToolbar: ToolbarCommands | undefined;
let customCommands: CommandMap | undefined;

const preHandleChange = (handleChange: (path: string, newObj: any) => void, path: string, content: string, fieldType: string) => {
  let newObj = { fieldType: fieldType, content: content };
  handleChange(path, newObj);
};

const RichContentControl = (props: ControlProps) => {
  // const [text, handleChange] = React.useState<string>(data.data === undefined ? "" : data.data.content);
  // const [fieldType, handleChange] = React.useState<string>(data.data === undefined ? "md" : data.data.fieldType);
  let text = props.data === undefined ? "" : props.data.content;
  let fieldType = props.data === undefined ? "md" : props.data.fieldType;
  const [markdownSelectedTab, setMarkdownSelectedTab] = React.useState<"write" | "preview" | undefined>();
  const book = React.useContext(BookContext)!;
  let tableCommand: Command = {
    // eslint-disable-next-line react/display-name
    icon: () => (
      <div title="Insert 3x3 table" className="customTableInsert">
        <Image.table />
      </div>
    ),
    execute: (opts) => {
      opts.textApi.replaceSelection(
        "| First Header  | Second Header | Third Header |\n| ------------- | ------------- | -------------|\n| Content Cell  | Content Cell | Content Cell |\n| Content Cell  | Content Cell  | Content Cell |\n"
      );
    },
  };
  let toolbar: ToolbarCommands = JSON.parse(JSON.stringify(ReactMde.defaultProps.toolbarCommands!));
  let commands: CommandMap = JSON.parse(JSON.stringify(ReactMde.defaultProps.commands!));
  toolbar.push(["insert-table"]);
  commands = { ...{ "insert-table": tableCommand } };

  customToolbar = toolbar;
  customCommands = commands;
  // The form element is allowing editing to occur.
  if (props.enabled === true) {
    let radios = (
      <RadioGroup row value={fieldType} onChange={(e) => preHandleChange(props.handleChange, props.path, text, e.target.value)} name="radio-buttons-group">
        <FormControlLabel value="md" control={<Radio />} label="Markdown" />
      </RadioGroup>
    );
    if (fieldType === "md") {
      let style: React.CSSProperties = { maxWidth: "initial" };
      if (Convert.isEmptyOrSpaces(text) && props.required === true) {
        style = { maxWidth: "initial", border: "2px solid red" };
      }
      return (
        <div style={style}>
          {radios}
          <ReactMde
            toolbarCommands={customToolbar}
            commands={customCommands}
            value={text}
            onChange={(e) => preHandleChange(props.handleChange, props.path, e, fieldType)}
            selectedTab={markdownSelectedTab}
            onTabChange={setMarkdownSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(
                <dl onClick={(e) => markdownLinkOverride(e, book)}>
                  <React.Fragment>
                    <dd className="markdownContainer">
                      <ReactMarkdown plugins={[Gfm]} linkTarget={markdownLinkClicked}>
                        {markdown}
                      </ReactMarkdown>
                    </dd>
                  </React.Fragment>
                </dl>
              )
            }
            childProps={{
              writeButton: {
                tabIndex: -1,
              },
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          {radios}
          <textarea
            name="textarea"
            id="html"
            required
            placeholder="Enter basic HTML here"
            className="htmlEditor bg-white rounded shadow w-full"
            value={text}
            onChange={(e) => preHandleChange(props.handleChange, props.path, e.target.value, fieldType)}
          />
        </div>
      );
    }
  }
  // The form element is read only. "Display mode".
  else {
    if (fieldType === "md") {
      return (
        <div>
          <dl onClick={(e) => markdownLinkOverride(e, book)}>
            <React.Fragment>
              <dd className="markdownContainer">
                <ReactMarkdown plugins={[Gfm]} linkTarget={markdownLinkClicked}>
                  {props.data?.content}
                </ReactMarkdown>
              </dd>
            </React.Fragment>
          </dl>
        </div>
      );
    } else {
      return (
        <div className="">
          <iframe
            className="formFrame"
            srcDoc={props.data.content.toString() + "<style type='text/css'>img{max-width:100% !important; height:auto;}</style>"}
            frameBorder="0"
            scrolling="no"
            onLoad={(e) => resizeIframe(e, book, 0)}
          />
        </div>
      );
    }
  }
};
export default withJsonFormsControlProps(RichContentControl);
