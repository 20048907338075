import { Log } from 'src/Logger';
import { ISearchExecuteSearch } from 'src/models/dto/ContentRequest';
import { Action } from 'src/models/UserContent';

interface IComplexSearchActionRawDataV26 {
    filter: string;
    resolution: string; // Gives which are the keys to check in the raw data.
}

enum SearchType {
    simple = 0,
    complex = 1 // For legacy search engine (version 2.6 and prior)
}

export class ActionItemUtils {
    /**
     * Converts an action to the currently supported version for the client.
     * As an example, the search engine was changed in version 2.7 and new
     * search actions have a different payload than old ones. This function
     * takes an old action and converts its RawData to the new format.
     */
    static migrateSearchAction(action: Action): ISearchExecuteSearch {
        // We got to convert the raw data to the proper format because items can come 
        // from older version of the application
        const searchCriteria = JSON.parse(action.RawData);
        let parsedSearchCriteria: ISearchExecuteSearch = {
            Filter: [],
            Query: "",
            IncludeCommunity: true
        };

        // With the old search engine (prior to ProLibro 2.7), there was two search type of search.
        // Each one of them had its own search criteria payload.
        if (action.ObjectId === SearchType.simple) {
            // First, the keys are lower cased if they come from a version prior to 2.7
            // but they are uppercased if they come from a version after 2.7.
            const filter = ActionItemUtils.getFirstMatchingAttribute<string>(searchCriteria, "Filter", "filter");
            const query = ActionItemUtils.getFirstMatchingAttribute<string>(searchCriteria, "Query", "query");
            parsedSearchCriteria.Filter = filter.trim() === "" ? [] : JSON.parse(filter);
            parsedSearchCriteria.Query = query;
        }
        else if (action.ObjectId === SearchType.complex && searchCriteria.resolution !== undefined) {
            // If the search was from complex type, it comes from a version prior to ProLibro 2.7
            // since all new action items are using the simple search engine.
            parsedSearchCriteria = this.migrateComplexSearch(searchCriteria);
        }
        else {
            Log.info("Unable to parse search action item: " + JSON.stringify(action));
        }

        return parsedSearchCriteria;
    }

    /**
     * Converts ProLibro 2.6 complex search raw data to the currently supported format.
     *
     * @param data The raw data to convert.
     */
    private static migrateComplexSearch(data: IComplexSearchActionRawDataV26): ISearchExecuteSearch {
        const keys = data.resolution.split("|"); // TODO: Confirm that only OR were supported by ProLibro 2.6 for complex Searches.
        const values = [];

        for (const key of keys) {
            if (data[key]) {
                values.push(data[key] + "]"); // Somehow, all of the values from the old version are missing 
            }
        }

        const filter = data.filter.trim() === "" ? [] : JSON.parse(data.filter);
        const query = values.join("|");
        return {
            Filter: filter,
            Query: query,
            IncludeCommunity: true
        };
    }

    /**
     * Goes through the given list of attributes and returns the first one that is not undefined.
     *
     * @param object The object that we want to get an attribute from.
     * @param attributeKeysToTry The keys to go through and test against the object.
     * @throws An error if no match can be found in the object.
     */
    private static getFirstMatchingAttribute<T>(object: any, ...attributeKeysToTry: string[]): T {
        for (const key of attributeKeysToTry) {
            if (object[key] !== undefined) {
                return object[key];
            }
        }

        throw new Error("Unable to find a matching attribute key from [" + attributeKeysToTry.join(", ") + "] in object: " + JSON.stringify(object));
    }
}