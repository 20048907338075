import { Dictionary } from 'src/collections/Generics';

import { LibraryProductCell } from './Library';

export enum ExternalLinkingError {
  NONE,
  INVALID,
  INVALID_TITLE,
  INVALID_VERSION,
  INVALID_ACTION,
  INVALID_ACTION_PARAMETERS,
  UNKNOWN_TITLE,
  UNKNOWN_VERSION,
  UNKNOWN_BRAND,
  NO_PERSISTENT_ID,
  NOT_LOGGED_IN,
  BAD_DESTINATION
}
export enum ExternalLinkingSection {
  TITLE_REF,
  VERSION_REF,
  ACTION,
  SEARCH_QUERY,
  NAV_ID,
  NAV_SELECTOR,
  BRAND,
  NAV_DOC_ID,
  TERM_START_INDEX,
  TERM_END_INDEX,
  NAV_END_DOC_ID,
  USERNAME,
  PASSWORD
}
export class ExternalLinkingPayload {
  RawURL: string;
  FormatterURI: string;
  PayloadDict: Dictionary<ExternalLinkingSection, string>; // Is immediately converted into the proper dictionary. See AppSession.tsx first usage.
  ParseError: ExternalLinkingError;
}
export enum StoreMessageType {
  ProductsLoaded,
  PurchaseCompleted,
  PurchaseError,
  PurchasesRestored
}
export class StoreMessagePayload {
  StoreMessageType: StoreMessageType;
  ProductCells: LibraryProductCell[];
  FailureMessage: string;
}
export interface IReportExternalLink {
  Url: string;
}
export interface IReportExternalLinkR { }
export interface IQueryCompanionUrl {
  TitleRef: string;
  BrandKey: string;
}
export interface IQueryCompanionUrlR {
  Url: string;
}
export interface ICreateExternalLink {
  FirstDocId: number;
  FirstOffset: number;
  SecondDocId: number;
  SecondOffset: number;
}
export interface ICreateExternalLinkR {
  Url: string;
}
