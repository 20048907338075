import * as _ from 'underscore';

export class Validator {
    private constructor() {} // force static class, hide constructor

    
    static notNullOrEmpty(value:any):boolean
    {
        return _.isString(value) && value.length > 0;  
    }
    static notNullOrWhitespace(value:any):boolean
    {
        return _.isString(value) && value.trim().length > 0; 
    } 
    
}