import classnames from 'classnames';
import * as React from 'react';

export interface ICogniflowProps {
  /**
   * A collection of settings that shape labelling for data, batch sizes and other important flow information.
   * If omitted, a series of defaults will be used.
   */
  extraSettings: ICogniflowOptionalSettings;
  /** The function that receives "flow" requests. The parent component will use the function to create append/prepend batches of content. */
  provider: (req: IRequest) => Promise<IResponse>;
  /**
   * The function that takes the INode (a base interface of the concrete class the cogniflow contains) and turns it into a JSX.Element to render.
   * For example, in the AnnotationTypes panel the INodes are AnnotationType objects. In cases where the applyDirectlyToSegment property in the settings is true
   * the attributes and key will be provided to the segments maintain the ID integrity.
   */
  builder: (node: INode, attributes?: any, key?: number) => JSX.Element;
  /** The function that will be called on the first load of the cogniflow. Also called on the reload of a cogniflow (clear->callback->initialize). */
  initialize: (initialAnchor?: number, searchQuery?: string) => Promise<{ nodes: any[]; targetSpine: number }>;
  /**
   * Function called in the scenario where navigation is requested to a secondary ID that isn't loaded into the cogniflow. This means the parent component
   * needs to figure out a batch to initialize the cogniflow with and call a reload.
   */
  secondaryIdInitializeRequested?: (secondaryId: number) => void;
  /** Function called whenever the topmost segment has changed. Will provide the main Id and the secondary, if provided at creation. */
  topMostHasChanged?: (newTopMostMainId: number, newTopMostSecondaryId?: number) => void;
  /** Occurs whenever the cogniflow is navigated to a node or an initialize occurs on an anchor such as reloadCogniflow(anchor) */
  navigationDoneCallback?: () => void;
  /**
   * Occurs whenever the loaded segments a re-rendered. Provides all the currently loaded segments and nodes in case the calling component seeks to
   * modify or style them outside what their components may do by design.
   */
  segmentsInsertedCallback?: ((segments: HTMLDivElement[], datas: INode[]) => void) | undefined;
  /**
   * Callback similar to builder that will receive a node and responds with the JSX addon element. These are appended to the cogniflow outside the scrolling div.
   * For example, contentview uses these for popups.
   */
  addonBuilder?: (node: INode) => JSX.Element;

  /**
   * Function that cogniflow will call when it begins an initialization load. Use this to handle loading animations or other presentation while cogniflow fetches data.
   */
  loadStarted?: () => void;
  /**
   * Function that cogniflow will call when it ends an initialization load. Use this to remove loading animations or other presentation as cogniflow has finished fetching data.
   */
  loadEnded?: () => void;
}

export interface ICogniflowState {
  /** State indicating if the cogniflow is awaiting a prepend operation. Will show top loading. */
  isTopLoading: boolean;
  /** State indicating if the cogniflow is awaiting an append operation. Will show bottom loading. */
  isBottomLoading: boolean;
  /** The settings consolidating the provided ones with the default ones. */
  extraSettings: ICogniflowOptionalSettings;
  /** The currently loaded nodes in the cogniflow component. */
  currentNodes: any[];
  /** The currently loaded mainIds. This will be used to prevent duplicate existence of mainIds quickly. */
  currentMainIds: Set<number>;
  /** Current target spine. Scrolling div will scroll it into view on the next render pass. Used for prepends/appends and for navigates within the cogniflow. */
  anchorMain: number;
  /** When the anchor is for an append action, the scrolling div will need to scroll the segment's top to the bottom of the view. This is useful because appending content on iOS/MacOS causes the view to scroll to the bottom. */
  scrollToBottom: boolean;
  /** An optional function that is provided in some functions and called within the navigation process of cogniflow. For example, a navigate to a node that callback a function that provides a selector to navigate to. */
  navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null;
  /** Navigation object that will be sent to navigationCallback when it is set. */
  navigation: any;
  /** Flag indicating if the targetSpine is a navigation operation. These occur when the cogniflow reader is navigating to a node. See navigationDoneCallback. */
  targetIsNav: boolean;
  /** A collection of nodes that will be sent to addonBuilder to get JSX Elements to load in the container. */
  addonElements: INode[];
  /** Internal action that is operating at the time of render. Used to know if certain actions should be taken during rerender. Also used to section off the loading removal to after the scroll occurs. */
  operatingAction: Action;
  /** The currently operating search query to be used when flowing and initializing */
  currentSearchQuery: string | undefined;
}

/**
 * This is the standalone cogniflow container. It is meant to replace the hybrid cogniflow previously existing.
 * It is also the control figuring inside the StandaloneCogniflowFrameContainer. Ideally this should be the only
 * "cogniflow" type control in the app.
 */
export class StandaloneCogniflowContainer extends React.PureComponent<ICogniflowProps, ICogniflowState> {
  // HTML items
  // Ref to the scrolling container's actual div
  scrollingRef = React.createRef<HTMLDivElement>();
  // Ref to the direct div containing the segments.
  fixedRef = React.createRef<HTMLDivElement>();
  // Ref to main div representing this component.
  rootRef = React.createRef<HTMLDivElement>();
  // React Ref to scrolling component. It is a pure component so may need to selectively trigger
  // updates to navigate without rerendering all the segments.
  scrollingComponent = React.createRef<ScrollingContainer>();
  // React Ref to the segment container. It is a pure component so it may selectively need to be
  // updated. Such as the already existing nodes in the view needing to rerender without navigating.
  segmentsComponent = React.createRef<SegmentContainer>();

  constructor(props: ICogniflowProps) {
    super(props);
    this.requestCallback = this.requestCallback.bind(this);
    this.requestAppend = this.requestAppend.bind(this);
    this.getNodeByMain = this.getNodeByMain.bind(this);
    this.getNodeBySecondary = this.getNodeBySecondary.bind(this);
    this.getMainDataId = this.getMainDataId.bind(this);
    this.getSecondaryDataId = this.getSecondaryDataId.bind(this);
    this.requestNavAppend = this.requestNavAppend.bind(this);
    this.requestAppend = this.requestAppend.bind(this);
    this.requestPrepend = this.requestPrepend.bind(this);
    this.addAddonElement = this.addAddonElement.bind(this);
    this.removeAddonElement = this.removeAddonElement.bind(this);
    this.clearAddonElements = this.clearAddonElements.bind(this);
    this.feedComplete = this.feedComplete.bind(this);
    this.state = {
      currentNodes: [],
      currentMainIds: new Set<number>(),
      extraSettings: props.extraSettings,
      isBottomLoading: false,
      isTopLoading: false,
      scrollToBottom: false,
      operatingAction: Action.insert,
      anchorMain: -1,
      targetIsNav: false,
      navigation: undefined,
      addonElements: [],
      currentSearchQuery: undefined,
    };
  }
  /**
   *
   * Will dump all content and set top/bottom loading. Then calls the provided callback,
   * then begins the reload. Use the callback to setup data before the reload occurs in
   * case order has changed. If this is something akin to a sorting change, provide the
   * sorted data through initialize. Functions operates like: ClearAll->Callback->Initialize->Feed
   *
   * @param anchor The node mainId to reload to. will scroll to it on rerender
   * @param callback Optional Function to call immediately after dumping current contents.
   * @param navigationCallback Function to call after content is loaded. Can be used to navigate to something that isn't a specific node.
   * @param navigation  The parameter to supply the navigation callback function.
   * @param searchQuery  The parameter to supply a string back to the initialize function of the caller.
   */
  reloadCogniflow(
    anchor?: number,
    callback?: () => void,
    navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null,
    navigation?: any,
    searchQuery?: string
  ) {
    // Clear current contents and state. Rerender.
    this.setState(
      {
        currentNodes: [],
        currentMainIds: new Set<number>(),
        isBottomLoading: true,
        isTopLoading: true,
        addonElements: [],
        currentSearchQuery: searchQuery,
      },
      () => {
        // Call the callback function, if provided. This gives the caller the possibility of doing work while the
        // cogniflow container is empty and loading.
        if (callback) {
          callback();
        }
        // Call the inner reload which will be initiating the initialize.
        this.innerReloadCogniflow(anchor, navigationCallback, navigation, searchQuery);
      }
    );
  }

  /**
   * Add an addon element. This is rendered into the container outside the scrolling div.
   *
   * @param newElement The new node to append.
   */
  addAddonElement(newElement: INode) {
    let newArr = this.state.addonElements.concat([newElement]);
    this.setState({ addonElements: newArr });
  }
  /**
   * Remove an addon element at the specified index.
   *
   * @param index Index to remove from the collection of addon elements.
   */
  removeAddonElement(index: number) {
    let newSet = this.state.addonElements.slice(0, index).concat(this.state.addonElements.slice(index + 1, this.state.addonElements.length - 1));
    this.setState({ addonElements: newSet });
  }
  /** clear all current addon elements. */
  clearAddonElements() {
    this.setState({ addonElements: [] });
  }
  /**
   * Replace all the addon elements with the new collection.
   *
   * @param newElements New elements that will be in the collection.
   */
  replaceAllAddonElements(newElements: INode[]) {
    this.setState({ addonElements: newElements }, this.forceUpdate);
  }
  /**
   * If provided with a valid node (e.g. a ContentSegment model) it will generate the equivalent JSX element that represents it. Doesn't append it anywhere just returns it.
   *
   * @param item The node to generate a segment of.
   */
  generateSegment(item: INode): JSX.Element | undefined {
    return this.segmentsComponent.current!.generateSegment(item);
  }
  /**
   * Function to replace nodes in place. This can occur if an existing segment changes but requires no other navs. Such as the creation of an annotation. Only replaces nodes it finds and keeps all others. To replace all, call replaceAllNodes()
   *
   * @param newNodes New nodes to search and replace.
   */
  replaceNodes(newNodes: INode[]) {
    let holder = this.state.currentNodes;
    for (let i = 0; i < newNodes.length; i++) {
      let foundIndex = holder.findIndex(
        (x) =>
          x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute] ===
          newNodes[i][this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute]
      );
      holder[foundIndex] = newNodes[i];
    }
    this.setState(
      { currentNodes: holder, currentMainIds: new Set<number>(holder.map((x) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])) },
      this.updateNodes
    );
  }
  /** Replace all nodes in the control. This can be more useful than a reload if you already have all the new content. Full replacement is regarded as a navigation and will fire navigation completion events.
   *
   * @param newNodes Represents the entire set of nodes that will replace the ones currently there.
   * @param anchorMain The MainId to navigate to after replacement completes.
   * @param navigationCallback The navigation function to callback to navigate to after replacement completes in case navigation is to something other than a node.
   * @param navigation Parameter to supply to the navigationCallback.
   */
  replaceAllNodes(newNodes: INode[], anchorMain: number, navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    this.setState({
      currentNodes: newNodes,
      currentMainIds: new Set<number>(newNodes.map((x) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])),
      anchorMain: anchorMain,
      navigationCallback: navigationCallback,
      navigation: navigation,
      targetIsNav: true,
      operatingAction: Action.insert,
      scrollToBottom: false,
    });
  }

  /** Get all the currently loaded SecondaryIDs */
  getAllSecondaryIds(): number[] {
    return this.state.currentNodes.map((x) => x[this.state.extraSettings.segmentDataDescriptor.secondaryIdNodeAttribute]);
  }
  /** Get all the currently loaded MainIDs */
  getAllMainIds(): number[] {
    return Array.from(this.state.currentMainIds);
  }

  /** Gets a currently loaded node by its primary ID */
  getNodeByMain(id: number): INode | undefined {
    let results = this.state.currentNodes.filter((item) => item[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute] === id);
    if (results.length > 0) {
      return results[0];
    }
    return undefined;
  }
  /** Gets a currently loaded node by its secondary ID */
  getNodeBySecondary(id: number): INode | undefined {
    let results = this.state.currentNodes.filter((item) => item[this.state.extraSettings.segmentDataDescriptor.secondaryIdNodeAttribute] === id);
    if (results.length > 0) {
      return results[0];
    }
    return undefined;
  }
  /** Gets the concrete div of a segment based on its main ID */
  getSegmentByMain(id: number): HTMLDivElement | null {
    return this.segmentsComponent.current!.getSegmentByMain(id);
  }
  /** Gets the MainID data attribute off a provided HTML element representing a segment.  */
  getMainDataId(concreteSegment: HTMLElement): number | null {
    return Number(concreteSegment.getAttribute(this.state.extraSettings.segmentDataDescriptor.mainIdDataAttribute));
  }
  /** Gets the Secondary data attribute off a provided HTML element representing a segment. */
  getSecondaryDataId(concreteSegment: HTMLElement): number | null {
    return Number(concreteSegment.getAttribute(this.state.extraSettings.segmentDataDescriptor.mainIdDataAttribute));
  }

  /**
   * returns if the given main ID is in the currently loaded nodes.
   */
  hasMainId(id: number) {
    return this.state.currentMainIds.has(id);
  }
  /**
   * returns if the given secondary ID is in the currently loaded nodes.
   */
  hasSecondaryId(secId: number) {
    return this.state.currentNodes.some((item) => item[this.state.extraSettings.segmentDataDescriptor.secondaryIdNodeAttribute] === secId);
  }
  /**
   * removes a node in place from the currently loaded nodes by its main Id. Will cause rerender if change is made.
   */
  removeNode(id: number) {
    let holder: any[] = [];
    this.state.currentNodes.map((item) => {
      if (item[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute] !== id) {
        holder.push(item);
      }
    });
    if (this.state.currentNodes.length !== holder.length) {
      this.setState({
        currentNodes: holder,
        currentMainIds: new Set<number>(holder.map((x) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])),
      });
    }
  }
  /**
   * removes a node in place from the currently loaded nodes by its secondary Id. Will cause rerender if change is made.
   */
  removeNodeBySecondary(id: number) {
    let holder: any[] = [];
    this.state.currentNodes.map((item) => {
      if (item[this.state.extraSettings.segmentDataDescriptor.secondaryIdNodeAttribute] !== id) {
        holder.push(item);
      }
    });
    if (this.state.currentNodes.length !== holder.length) {
      this.setState({
        currentNodes: holder,
        currentMainIds: new Set<number>(holder.map((x) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])),
      });
    }
  }
  /**
   * Forces a rerender of the loaded segments.
   */
  updateNodes() {
    this.segmentsComponent.current!.forceUpdate();
  }
  /**
   * Will navigate to the specified main ID. If it is loaded in the cogniflow and there's room it will simply scroll to it, if it's in
   * the cogniflow but there isn't enough padding room it will request an append then navigate. If the ID is not at all in the cogniflow it will
   * trigger an anchored reload requesting segments from the anchor.
   *
   * @param id MainId identifier
   * @param additionalOffset Additional offset to be added to the segments top to determine if an append needs to be called to scroll that segment to the top. Not used afterwards.
   * @param navigationCallback The navigation function to callback to navigate to after replacement completes in case navigation is to something other than a node (such as selector, numerical offset or element)
   * @param navigation Parameter to supply to the navigationCallback.
   */
  navigateToNode(id: number, additionalOffset?: number, navcallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    let holder = this.state.currentNodes.filter((x) => x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute] === id);
    if (holder.length === 0) {
      this.reloadCogniflow(id, undefined, navcallback, navigation);
      return;
    }
    this.innerNavigate(holder[0], additionalOffset, navcallback, navigation);
  }
  /**
   * Specifically navigate using a function. Will call the nav function after rerender.
   *
   * @param navigationCallback The navigation function to callback to navigate to after replacement completes in case navigation is to something other than a node (such as selector, numerical offset or element)
   * @param navigation Parameter to supply to the navigationCallback.
   */
  navigateFunction(navCallaback: (navigation: any) => ICogniflowNavigationResponse | null, navigation: any) {
    this.setState({ navigationCallback: navCallaback, navigation: navigation, targetIsNav: true });
  }
  /**
   * Similar to *navigateToNode(id: number)* but if the secondary ID is not in the cogniflow at all it will trigger the *secondaryIdInitializeRequested?: (secondaryId: number) => void* function. This is to notify the caller that
   * they should reload the cogniflow with the propriate mainId anchor because the cogniflow itself can't resolve the appropriate action for the nav request.
   *
   * @param id secondary identifier
   */
  navigateToNodeBySecondaryId(id: number) {
    let holder = this.state.currentNodes.filter((x) => x[this.state.extraSettings.segmentDataDescriptor.secondaryIdNodeAttribute] === id);
    if (holder.length === 0) {
      if (this.props.secondaryIdInitializeRequested) {
        this.props.secondaryIdInitializeRequested(id);
      }
      return;
    }
    let node = holder[0];
    this.innerNavigate(node);
  }
  /**
   * Returns true if the node offset + the additionalOffset would require addition content to scroll to the top. Returns undefined if the node is not in the container at all.
   *
   * @param mainId The node mainId.
   * @param additionalOffset  An additional offset. Otherwise just the top of the segment.
   */
  navigateWillRequireAppend(mainId: number, additionalOffset?: number): boolean | undefined {
    let node: INode | undefined;
    node = this.getNodeByMain(mainId);
    if (!node) {
      return node;
    }
    return this.segmentsComponent.current!.willNavigateRequireAppend(node, additionalOffset);
  }
  /**
   * Internal function that will decide if the node requires padding to navigate to or if it can directly scroll to it.
   *
   * @param node Node item the cogniflow will nav to.
   * @param navigationCallback The navigation function to callback to navigate to after replacement completes in case navigation is to something other than a node (such as selector, numerical offset or element)
   * @param additionalOffset  An additional offset. Otherwise just the top of the segment.
   * @param navigation Parameter to supply to the navigationCallback.
   */
  private innerNavigate(node: INode, additionalOffset?: number, navcallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    let mainId = node[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute];
    // Compute if there's enough viewport height after the item to scroll it to the top of the current scrollable container.
    if (!this.segmentsComponent.current!.willNavigateRequireAppend(node, additionalOffset)) {
      if (!navcallback && !navigation && mainId === this.state.anchorMain) {
        // If no change is made just update for rescroll.
        this.scrollingComponent.current!.forceUpdate();
      }
      // If no padding is required, set the targetspine and Nav properties.
      // Set the target and nav->true so it scrolls to it and fires navigationDoneCallback?: () => void;
      this.setState({
        anchorMain: mainId,
        navigationCallback: navcallback,
        navigation: navigation,
        targetIsNav: true,
        operatingAction: Action.insert,
        scrollToBottom: false,
      });
    } else {
      // If padding is required, a special NavAppend request is created. This will cause an append to occur followed by a
      // nav state setting. When the render executes it will scroll to the target (which will have enough padding now) and
      // fire the navigation callback.
      this.requestCallback(this.buildNavAppendRequest(mainId), true, navcallback, navigation);
    }
  }
  /**
   * Inner function that will do the initialize as part of a reload.
   *
   * @param anchor The anchor MainId to request from and scroll to as part of the render pass.
   * @param additionalOffset  An additional offset. Otherwise just the top of the segment.
   * @param navigation Parameter to supply to the navigationCallback.
   */
  private async innerReloadCogniflow(
    anchor?: number,
    navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null,
    navigation?: any,
    searchQuery?: string
  ) {
    if (this.state.currentNodes.length > 0) {
      throw new Error("You must clear the cogniflow container and adjust data before calling reload.");
    }
    let result = null;
    let batch = null;
    // Initialization with an anchor will cause navigates. Else top.
    if (anchor) {
      result = await this.innerInitialize(anchor, searchQuery);
      batch = this.createBatch(Action.insert, result.targetSpine, anchor, result.nodes.length);
    } else {
      result = await this.innerInitialize(undefined, searchQuery);
      batch = this.createBatch(Action.insert, result.targetSpine, result.targetSpine, result.nodes.length);
    }
    batch.Nodes = result.nodes;
    let resp = { Batches: [batch] } as IResponse;
    this.feed(resp, anchor !== undefined, navigationCallback, navigation);
  }

  async componentDidMount() {
    // Fill the provided settings with the defaults for those not set.
    let sets = this.props.extraSettings;
    if (this.props.extraSettings.containerClasses === undefined) {
      sets.containerClasses = "d-flex flex-column";
    }
    if (!this.props.extraSettings.scrollingClasses === undefined) {
      sets.scrollingClasses = "";
    }
    if (!this.props.extraSettings.segmentContainerClasses === undefined) {
      sets.segmentContainerClasses = "";
    }
    if (!this.props.extraSettings.maxBatches) {
      sets.maxBatches = 4;
    }

    // Call the initialize the cogniflow instance.
    let result = await this.innerInitialize();
    // Set settings, initial nodes and targetSpine.
    this.setState(
      {
        extraSettings: sets,
        currentNodes: result.nodes,
        currentMainIds: new Set<number>(result.nodes.map((x: INode) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])),
        anchorMain: result.targetSpine,
      },
      () => {
        // Call load end now that feed is complete and was initialize insert.
        if (this.props.loadEnded) {
          this.props.loadEnded();
        }
      }
    );
  }

  /**
   * Function with the task of manipulating the current nodes when appending or prepending content.
   *
   * @param response The response containing the node batches to feed into cogniflow.
   * @param isNavigate Optional bool indicating if the feed is stemming from a navigation which will require a callback.
   */
  private feed(response: IResponse, isNavigate?: boolean, navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    let holder = this.state.currentNodes;

    // We need the scrolling ref for this function to work. If it is no longer set the control may be unmounting.
    if (!this.scrollingRef.current) {
      return;
    }
    // Max nodes to have in the congflow in its entirety.
    let maxNodes = this.state.extraSettings.batchSize * this.state.extraSettings.maxBatches!;
    // Also check the viewport. Some situations can cause segments to be so small that a batch doesnt
    // take up the whole height. Which can break the panel. Prevent this by ensuring the content is bigger than the
    // scrolling view.
    let minViewPort = this.scrollingRef.current.getBoundingClientRect().height * 3;
    // Target Id to scroll to. -1 does nothing such as during appending.
    let targetId = -1;
    let scrollToBottom = false;
    let operatingAction = Action.insert;
    // For each response batch, execute appropriate changes to the nodes.
    for (const batch of response.Batches) {
      operatingAction = batch.Action;
      if (
        batch.Action !== Action.insert &&
        new Set(
          [...this.state.currentMainIds].filter((x) =>
            new Set<number>(batch.Nodes.map((y) => +y[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])).has(x)
          )
        ).size > 0
      ) {
        // drop a batch with duplicate keys...
        continue;
      }
      switch (batch.Action) {
        case Action.prepend:
          holder = batch.Nodes.concat(holder);
          targetId = batch.AnchorMainId;
          // If the new set of nodes prepended surpasses the total limit, remove an equal amount from the end.
          if (holder.length > maxNodes && this.fixedRef.current!.getBoundingClientRect().height >= minViewPort) {
            holder = holder.slice(0, holder.length - batch.Nodes.length);
          }
          break;
        case Action.append:
          if (batch.Nodes.length === 0) {
            holder[holder.length - 1].IsLast = true;
          }
          holder = holder.concat(batch.Nodes);
          targetId = batch.AnchorMainId;
          scrollToBottom = true;
          // If the new set of nodes appended surpasses the total limit, remove an equal amount from the start.
          if (holder.length > maxNodes && this.fixedRef.current!.getBoundingClientRect().height >= minViewPort) {
            holder = holder.slice(batch.Nodes.length);
          }
          break;
        case Action.navAppend:
          // targetId = batch.TargetMainId;
          // scrollToBottom = true;
          // navAppend is similar to append except it sets the target for scrolling.
          holder = holder.concat(batch.Nodes);
          targetId = batch.AnchorMainId;
          break;
        case Action.insert:
          // Inserts generally only occur on initialize. It's the feed that occurs when content is initially put into cogniflow and doesn't
          // count as an append or a prepend.
          holder = batch.Nodes;
          targetId = batch.TargetMainId;
          break;
      }
    }
    // Set the new nodes, the target and wether it's a nav here.
    this.setState(
      {
        currentNodes: holder,
        currentMainIds: new Set<number>(holder.map((x) => +x[this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute])),
        anchorMain: targetId,
        scrollToBottom: scrollToBottom,
        targetIsNav: isNavigate === true,
        navigationCallback: navigationCallback,
        navigation: navigation,
        operatingAction: operatingAction,
      },
      () => {
        // Call load end now that feed is complete and was initialize insert.
        if (this.props.loadEnded && response.Batches[0].Action === Action.insert) {
          this.props.loadEnded();
        }
      }
    );
  }

  /**
   * Internal function that is used to remove loading bars when navigation has completed.
   *
   * @param operatingAction Action that completed.
   */
  private feedComplete(operatingAction: Action) {
    // Remove loadings
    this.setLoading(operatingAction, false);
  }

  /**
   * Set the load state based on the action.
   *
   * @param action Action that is being affected.
   * @param newValue The new loading state to be applied
   * @param callback A callback function to be executed after loading bars have rendered to the cogniflow.
   */
  private setLoading(action: Action, newValue: boolean, callback?: () => void) {
    switch (action) {
      case Action.prepend:
        this.setState({ isTopLoading: newValue }, callback);
        break;
      case Action.append:
      case Action.navAppend:
        this.setState({ isBottomLoading: newValue }, callback);
        break;
      case Action.insert:
        this.setState({ isTopLoading: newValue, isBottomLoading: newValue }, callback);
        break;
    }
  }

  /**
   * Builds the request for a prepend based on the first node loaded's main ID. This way main IDs don't need to be numerically contiguous such as an index.
   * The calling component gets to define the logic governing what content is prepended. It simply sends the MainId to the caller and it decides what fills the batch.
   */
  private buildPrependRequest(): IRequest {
    let req: IBatch[] = [];
    let spine = this.state.currentNodes[0][this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute];
    let anchor = spine;
    if (this.state.extraSettings.isIndexBased && spine !== 0) {
      spine = spine - 1;
    }
    req.push(this.createBatch(Action.prepend, spine, anchor, this.state.extraSettings.batchSize));
    this.setState({ isTopLoading: true });
    return { Batches: req } as IRequest;
  }
  /**
   * Builds the request for an append based on the last node's main ID. This way main IDs don't need to be numerically contiguous such as an index.
   * The calling component gets to define the logic governing what content is appended. It simply sends the MainId to the caller and it decides what fills the batch.
   */
  private buildAppendRequest(): IRequest {
    let req: IBatch[] = [];
    let spine = this.state.currentNodes[this.state.currentNodes.length - 1][this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute];
    let anchor = spine;
    if (this.state.extraSettings.isIndexBased) {
      spine = spine + 1;
    }
    req.push(this.createBatch(Action.append, spine, anchor, this.state.extraSettings.batchSize));
    this.setState({ isBottomLoading: true });
    return { Batches: req } as IRequest;
  }
  /**
   * Builds the special request for navigation appending. This is used when a nav requires some buffer content. The main IDs don't need to be numerically contiguous such as an index.
   * The calling component gets to define the logic governing what content is appended. It simply sends the MainId to the caller and it decides what fills the batch.
   */
  private buildNavAppendRequest(anchorId: number): IRequest {
    let req: IBatch[] = [];
    let spine = this.state.currentNodes[this.state.currentNodes.length - 1][this.state.extraSettings.segmentDataDescriptor.mainIdNodeAttribute];
    req.push(this.createBatch(Action.navAppend, spine, anchorId, this.state.extraSettings.batchSize));
    this.setState({ isBottomLoading: true });
    return { Batches: req } as IRequest;
  }

  /**
   * Creates the concrete batch that will be requested from the calling component.
   *
   * @param action Action to create a batch for.
   * @param targetSpine Target spine of the batch. On append it's the last MainID in cogniflow and on prepend it's the first.
   * @param anchorSpine Anchor spine (currently only used within Cogniflow)
   * @param batchSize The size of the batch. This is generally set through the settings at creation.
   */
  private createBatch(action: Action, targetSpine: number, anchorSpine: number, batchSize: number): IBatch {
    return {
      Action: action,
      TargetMainId: targetSpine,
      AnchorMainId: anchorSpine,
      BatchSize: batchSize,
    } as IBatch;
  }
  /**
   * Actual request function for flow.
   *
   * @param request request that will be sent to the flow provider.
   * @param isNav internal nav bool that will tell cogniflow to use the nav callback if necessary.
   */
  private async requestCallback(request: IRequest, isNav?: boolean, navcallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    request.Batches.forEach((element) => {
      element.Query = this.state.currentSearchQuery;
    });
    this.feed(await this.props.provider(request).catch(() => ({ Batches: [] } as IResponse)), isNav, navcallback, navigation);
  }
  /**
   * The inner initialization function. Abstracted so that future dev relating to init doesn't need to be copy pasted everywhere the props initialization is called.
   *
   * @param anchor optional anchor mainId.
   * @param anchor optional search query that will be supplied to the initializer.
   */
  private async innerInitialize(anchor?: number, searchQuery?: string): Promise<any> {
    // Fire loading for reload
    if (this.props.loadStarted) {
      this.props.loadStarted();
    }
    return await this.props.initialize(anchor, searchQuery).catch(() => ({ nodes: [], targetSpine: 0 }));
  }
  /**
   * The append request function used by the scrolling container when it wants to append content.
   * Will drop requests made while bottom is loading.
   */
  private requestAppend() {
    // Drop requests while a request is occurring.
    if (this.state.isBottomLoading) {
      return;
    }
    this.setLoading(Action.append, true, () => {
      this.requestCallback(this.buildAppendRequest());
    });
  }
  /**
   * The append request function used by the scrolling container when it wants to append content.
   * Will drop requests made while bottom is loading.
   *
   * @param anchorMainId The anchor to be maintained after appending more.
   */
  private requestNavAppend(anchorMainId: number, navcallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any) {
    this.setLoading(Action.append, true, () => {
      this.requestCallback(this.buildNavAppendRequest(anchorMainId), this.state.targetIsNav, navcallback, navigation);
    });
  }
  /**
   * The append request function used by the scrolling container when it wants to append content.
   * Will drop requests made while bottom is loading.
   */
  private requestPrepend() {
    // Drop requests while a request is occurring.
    if (this.state.isTopLoading) {
      return;
    }
    this.setLoading(Action.prepend, true, () => {
      this.requestCallback(this.buildPrependRequest());
    });
  }
  render() {
    let addons: JSX.Element[] = [];
    if (this.props.addonBuilder !== undefined) {
      this.state.addonElements.map((x) => {
        addons.push(this.props.addonBuilder!(x));
      });
    }
    return (
      <div className={classnames("cogniflow-container", this.state.extraSettings.containerClasses)} style={{ position: "relative" }} ref={this.rootRef}>
        {this.state.isTopLoading && (
          <div className="cogniflow-loading cogniflow-loading-top">
            <div />
          </div>
        )}
        <ScrollingContainer
          scrollingDivClassName={this.props.extraSettings.scrollingClasses}
          fixedDivClassName={this.props.extraSettings.segmentContainerClasses}
          fixedRef={this.fixedRef}
          scrollingRef={this.scrollingRef}
          currentNodes={this.state.currentNodes}
          builder={this.props.builder}
          settings={this.state.extraSettings}
          anchorMain={this.state.anchorMain}
          navigation={this.state.navigation}
          isNavigate={this.state.targetIsNav}
          topMostHasChanged={this.props.topMostHasChanged}
          navigationCallback={this.state.navigationCallback}
          navigationDoneCallback={this.props.navigationDoneCallback}
          segmentsInsertedCallback={this.props.segmentsInsertedCallback}
          requestAppend={this.requestAppend}
          requestPrepend={this.requestPrepend}
          segmentsComponentRef={this.segmentsComponent}
          ref={this.scrollingComponent}
          getNodeByMain={this.getNodeByMain}
          requestNavAppend={this.requestNavAppend}
          scrollToBottom={this.state.scrollToBottom}
          feedComplete={this.feedComplete}
          operatingAction={this.state.operatingAction}
        />
        {addons}
        {this.state.isBottomLoading && (
          <div className="cogniflow-loading cogniflow-loading-bottom">
            <div />
          </div>
        )}
      </div>
    );
  }
}
interface IScrollingContainerProps {
  /** CSS classes for the scrolling div */
  scrollingDivClassName: string | undefined;
  /** CSS classes for the segment container */
  fixedDivClassName: string | undefined;
  /** Ref to the segment container */
  fixedRef: React.RefObject<HTMLDivElement>;
  /** Ref to the scrolling container */
  scrollingRef: React.RefObject<HTMLDivElement>;
  /** Ref to the segment container component. */
  segmentsComponentRef: React.RefObject<SegmentContainer>;
  /** Currently loaded nodes. */
  currentNodes: INode[];
  /** Current cogniflow settings. */
  settings: ICogniflowOptionalSettings;
  /** Prop to request appending */
  requestAppend: () => void;
  /** Prop to request prepending */
  requestPrepend: () => void;
  /** Current target spine. Scrolling div will scroll it into view on the next render pass. Used for prepends and for navigates within the cogniflow. */
  anchorMain: number;
  /** Navigation object that will be sent with anchorMain when it is a function */
  navigation: any;
  /** Flag indicating if the scroll is part of a navigation and should fire the callback. */
  isNavigate: boolean;
  /** The function to call when building segments. */
  builder: ((node: INode, attributes?: any, key?: number) => any) | undefined;
  /** Callback function that is triggered when the topmost segment changes */
  topMostHasChanged: ((newTopMostMainId: number, newTopMostSecondaryId?: number) => void) | undefined;
  /** Navigation callback that is fired when navigation completes. If defined. */
  navigationDoneCallback: (() => void) | undefined;
  /** Called when segments are re-rendered (when the currentNodes change) */
  segmentsInsertedCallback: ((segments: HTMLDivElement[], datas: INode[]) => void) | undefined;
  /** Get nodes by mainId for possible recursive navigations. */
  getNodeByMain(id: number): INode | undefined;
  /** request additional content when there's not enough space even after a nav first append. */
  requestNavAppend(anchorMainId: number, navcallback?: (navigation: any) => ICogniflowNavigationResponse | null, navigation?: any): void;
  /** Function to call after all immediate work completes */
  navigationCallback?: (navigation: any) => ICogniflowNavigationResponse | null;
  /** flag indicating if the scroll should move the segment to the top or the bottom of the viewport */
  scrollToBottom: boolean;
  /** callback when feed has completed and scrolled. removes loadings */
  feedComplete: (operatingAction: Action) => void;
  /** Operation that has most recently been executed. Supplied to feedComplete. */
  operatingAction: Action;
}

interface IScrollingContainerState {}
/**
 * The scrolling container. This is the component that scrolls with overflow. Will make requests to prepend/append, scroll to the anchor and call navigation callbacks.
 * This component also emits when the topmost segment changes.
 */
class ScrollingContainer extends React.PureComponent<IScrollingContainerProps, IScrollingContainerState> {
  currentTopMostMainId: number;
  constructor(props: IScrollingContainerProps) {
    super(props);
    this.state = { currentTopMostMainId: 0, currentTopMostSecondaryId: 0 };
    this.containerScrolled = this.containerScrolled.bind(this);
  }

  componentDidUpdate() {
    if (this.props.anchorMain > -1) {
      // Get the loaded segments that corresponds to the anchor.
      let segment = this.props.fixedRef.current!.querySelector(
        "div[" + this.props.settings.segmentDataDescriptor.mainIdDataAttribute + "='" + this.props.anchorMain + "']"
      );
      if (segment !== null && this.props.scrollingRef.current) {
        let node = this.props.getNodeByMain(this.props.anchorMain);
        let willNeedMore = false;
        if (node) {
          willNeedMore = this.props.segmentsComponentRef.current!.willNavigateRequireAppend(node);
        }
        // If we need more AND the last loaded node is not the absolute last.
        if (willNeedMore && !this.props.currentNodes[this.props.currentNodes.length - 1][this.props.settings.segmentDataDescriptor.isLastAttribute]) {
          let prevAction = this.props.operatingAction;
          setTimeout(() => {
            // When timeout completes, callback feedcomplete.
            this.props.feedComplete(prevAction);
          }, 50);
          this.props.requestNavAppend(this.props.anchorMain, this.props.navigationCallback, this.props.navigation);
          // Set a timeout to ensure all scrolling has completed.

          return;
        }
        // If found, scroll it into view. Scrollbottom if set, or a navappend that wasn't a navigate.
        if (this.props.scrollToBottom || (!this.props.isNavigate && this.props.operatingAction === Action.navAppend)) {
          this.props.scrollingRef.current.scrollTop =
            (segment as HTMLDivElement).offsetTop -
            (this.props.scrollingRef.current.getBoundingClientRect().height - (segment as HTMLDivElement).getBoundingClientRect().height);
        } else {
          // Add 1 to the offset. Ensures that the "topmost" will be accurate.
          this.props.scrollingRef.current.scrollTop = (segment as HTMLDivElement).offsetTop + 1;
        }
        // Call nav callback if it's a navigate, is set and the additional callback is not set.
        if (this.props.navigationDoneCallback && this.props.isNavigate && this.props.navigationCallback === undefined) {
          // If this is a nav and the callback is set, fire it.
          this.props.navigationDoneCallback();
        }
      } else if (this.props.scrollingRef.current) {
        // If an anchor was set but no node found, scroll to the top.
        this.props.scrollingRef.current.scrollTop = 0;
      }
    }
    // If the navigation callback has been defined, execute it now.
    if (this.props.navigationCallback !== undefined) {
      this.executeSpecialNav();
    }
    // Set a timeout to ensure all scrolling has completed.
    setTimeout(() => {
      // When timeout completes, callback feedcomplete.
      this.props.feedComplete(this.props.operatingAction);
      // Also trigger a container scrolled. If the user is speed scrolling content it will ensure another append/prepend occurs instead of locking the frame.
      this.containerScrolled();
    }, 50);
  }

  /** This is the function executing the special navigation that may have been provided as a prop. It handles results scrolling to:
   * 1. An additional offset amount on top of the main segment's top.
   * 2. A selector it will scroll to the first it can find after the segment's top.
   * 3. A specific element it will scroll into view if possible.
   */
  executeSpecialNav() {
    if (!this.props.scrollingRef.current) {
      return;
    }
    if (typeof this.props.navigationCallback === "function") {
      let result = this.props.navigationCallback(this.props.navigation);
      // If no result, call the Done callback.
      if (!result) {
        if (this.props.navigationDoneCallback && this.props.isNavigate) {
          // If this is a nav and the callback is set, fire it.
          this.props.navigationDoneCallback();
        }
        return;
      }
      // Handle additional offset case.
      if (result.offset !== undefined && this.props.fixedRef.current) {
        let segment = this.props.fixedRef.current.querySelector(
          "div[" + this.props.settings.segmentDataDescriptor.mainIdDataAttribute + "='" + result.mainId + "']"
        );
        if (!segment) {
          return;
        }

        this.props.scrollingRef.current.scrollTop = (segment as HTMLDivElement).offsetTop + result.offset;
      }
      // Handle element case.
      else if (result.element) {
        let segment = this.props.fixedRef.current!.querySelector(
          "div[" + this.props.settings.segmentDataDescriptor.mainIdDataAttribute + "='" + result.mainId + "']"
        );
        if (!segment) {
          return;
        }
        let additionalOffset = this.calcOffset(result.element);
        this.props.scrollingRef.current.scrollTop = (segment as HTMLElement).offsetTop + additionalOffset;
      }
      // Handle selector case.
      else if (result.selector) {
        let element = null;
        try {
          element = this.props.fixedRef.current!.querySelector(result.selector.replace(/^\#+/, ""));
          if (element === null) {
            element = this.props.fixedRef.current!.ownerDocument.getElementById(result.selector.replace(/^\#+/, ""));
          }
        } catch (e) {
          try {
            element = this.props.fixedRef.current!.ownerDocument.getElementById(result.selector.replace(/^\#+/, ""));
          } catch (ex) {
            element = null;
          }
        }
        let segment = this.props.fixedRef.current!.querySelector(
          "div[" + this.props.settings.segmentDataDescriptor.mainIdDataAttribute + "='" + result.mainId + "']"
        );
        if (element && segment) {
          let additionalOffset = this.calcOffset(element as HTMLElement);
          this.props.scrollingRef.current.scrollTop = (segment as HTMLDivElement).offsetTop + additionalOffset;
        } else if (segment) {
          this.props.scrollingRef.current.scrollTop = (segment as HTMLDivElement).offsetTop;
        }
      }
      if (this.props.navigationDoneCallback && this.props.isNavigate) {
        // If this is a nav and the callback is set, fire it.
        this.props.navigationDoneCallback();
      }
    }
  }
  /**
   * Calculate the offsettop of an element within a content segment.
   *
   * @param element child element of segment to get local offset of.
   */
  private calcOffset(element: HTMLElement): number {
    let offset = element.getBoundingClientRect().top;
    return offset;
  }

  containerScrolled() {
    // Const providing a buffer for when appended content will be triggered.
    // This is generally because "scrollIntoView" doesn't always set top 0
    // and thus not always call prepend/append when it should.
    const PREPEND_APPEND_RANGE = 10;
    // Protect against empty element collection.
    if (!this.props.currentNodes || this.props.currentNodes.length === 0 || this.props.scrollingRef.current === null) {
      return;
    }
    let comparer = Math.floor(
      Math.abs(this.props.scrollingRef.current.scrollHeight - this.props.scrollingRef.current.scrollTop - this.props.scrollingRef.current.clientHeight)
    );
    if (comparer === 0 && this.props.scrollingRef.current.scrollHeight > 0) {
      // Append check
      if (this.props.scrollingRef.current) {
        // Don't append if the bottom is last...
        if (!this.props.currentNodes[this.props.currentNodes.length - 1][this.props.settings.segmentDataDescriptor.isLastAttribute]) {
          this.props.requestAppend();
        }
      }
    } else if (
      this.props.scrollingRef.current.scrollTop >= 0 &&
      this.props.scrollingRef.current &&
      this.props.scrollingRef.current.scrollTop <= PREPEND_APPEND_RANGE
    ) {
      // Prepend check
      // Don't prepend if the top is first...
      if (!this.props.currentNodes[0][this.props.settings.segmentDataDescriptor.isFirstAttribute]) {
        this.props.requestPrepend();
      }
    } else {
    }
    // Handle topmost
    this.topmostDisplayedSegment();
  }
  /**
   * Function dedicated to finding the topmost segments and, if it has changed, emit the change. currentTopMostMainId Is not in the state as changes to this should not cause a rerender.
   */
  private topmostDisplayedSegment() {
    // Don't compute if there's nobody listening.
    if (!this.props.topMostHasChanged) {
      return;
    }
    // Get all the currently loaded segments
    const segments = this.props.fixedRef.current!.children;
    for (let i = 0; i < segments.length - 1; i++) {
      const segment = segments[i] as HTMLDivElement;
      if (segment === undefined) {
        continue;
      }
      // For each segment, get the dimentions
      let segmentDimensions = segment.getBoundingClientRect();
      // The first bounding rect that has a positive top is the one that is at the top without having passed it.
      if (segmentDimensions.top + segmentDimensions.height > 0) {
        // Get the mainId of the top segments
        let mainId = Number(segment.getAttribute(this.props.settings.segmentDataDescriptor.mainIdDataAttribute)!);
        let secondaryId;
        // If defined, get the secondary Id.
        if (segment.hasAttribute(this.props.settings.segmentDataDescriptor.secondaryIdDataAttribute)) {
          secondaryId = Number(segment.getAttribute(this.props.settings.segmentDataDescriptor.secondaryIdDataAttribute));
        }
        // If the topmost has actually changes, update the variable and trigger the callback. If not, skip.
        if (this.currentTopMostMainId !== mainId) {
          this.currentTopMostMainId = mainId;
          this.props.topMostHasChanged(mainId, secondaryId);
        }
        break;
      }
    }
  }

  render() {
    return (
      <div className={classnames("flex-fill scrollable", this.props.scrollingDivClassName)} ref={this.props.scrollingRef} onScroll={this.containerScrolled}>
        <SegmentContainer
          ref={this.props.segmentsComponentRef}
          fixedDivClassName={this.props.fixedDivClassName}
          fixedRef={this.props.fixedRef}
          currentNodes={this.props.currentNodes}
          builder={this.props.builder}
          settings={this.props.settings}
          scrollingRef={this.props.scrollingRef}
          segmentsInsertedCallback={this.props.segmentsInsertedCallback}
        />
      </div>
    );
  }
}
interface ISegmentContainerProps {
  /** CSS classes for the segment container  */
  fixedDivClassName: string | undefined;
  /** Ref to the segment container */
  fixedRef: React.RefObject<HTMLDivElement>;
  /** Ref to the scrolling container. */
  scrollingRef: React.RefObject<HTMLDivElement>;
  /** The nodes currently in the cogniflow */
  currentNodes: INode[];
  /** The current settings */
  settings: ICogniflowOptionalSettings;
  /** Function to build the nodes into JSX.Elements */
  builder: ((node: INode, attributes?: any, key?: number) => any | undefined) | undefined;
  /** Segments rerender callback. */
  segmentsInsertedCallback: ((segments: HTMLDivElement[], datas: INode[]) => void) | undefined;
}
/**
 * The container that will holds and manage all the segments directly. Handles segment generation and insert callbacks.
 */
class SegmentContainer extends React.PureComponent<ISegmentContainerProps, unknown> {
  SEGMENT = "cogniflow--segment"; // Class for a segment. All segments have this.
  SEGMENT_ABSOLUTE_FIRST: string = this.SEGMENT + "--first"; // Addendum when this is the first segment of the entire collection.
  SEGMENT_ABSOLUTE_LAST: string = this.SEGMENT + "--last"; // Addendum when this is the last segment of the entire collection.
  constructor(props: ISegmentContainerProps) {
    super(props);
    this.willNavigateRequireAppend = this.willNavigateRequireAppend.bind(this);
    this.getSegmentByMain = this.getSegmentByMain.bind(this);
  }
  /**
   * Function that checks, then calls the builder. The creating component handles creating the item that is loaded. E.g and AnnotationItem.
   *
   * @param data The INode itself. For examplt an Annotation.
   * @param attribute If the component is applying attributes, communicate the attributes.
   * @param key If the component is applying attributes, communicate the key to use on the component.
   */
  private extendedGenerateSegment(data: INode, attributes?: any, key?: number): JSX.Element | undefined {
    if (this.props.builder !== undefined) {
      return this.props.builder(data, attributes, key);
    }
    return <span />;
  }
  /**
   * Creates a concrete segment with wrapper and proper classes to be inserted into the
   *
   * @param item The data INode for this segment
   */
  generateSegment(item: INode): JSX.Element | undefined {
    let final: JSX.Element | undefined;
    // If provided, call the builder.
    if (this.props.builder && !this.props.settings.segmentDataDescriptor.applyDirectlyToSegment) {
      final = this.extendedGenerateSegment(item);
    }
    let attrs = {};
    attrs["className"] = "";
    attrs["className"] += " " + this.SEGMENT; // Set the MainId of the segment
    attrs[this.props.settings.segmentDataDescriptor.mainIdDataAttribute] = item[this.props.settings.segmentDataDescriptor.mainIdNodeAttribute];
    // If there was a provided secondary property (say, local anno ID or a HeadId) add that too
    if (item.hasOwnProperty(this.props.settings.segmentDataDescriptor.secondaryIdNodeAttribute)) {
      attrs[this.props.settings.segmentDataDescriptor.secondaryIdDataAttribute] = item[this.props.settings.segmentDataDescriptor.secondaryIdNodeAttribute];
    }
    // If the item is the last item of all data, add that class.
    if (item[this.props.settings.segmentDataDescriptor.isLastAttribute]) {
      attrs["className"] += " " + this.SEGMENT_ABSOLUTE_LAST;
    }
    // If the item is the absolute first item of all data, add that class.
    if (item[this.props.settings.segmentDataDescriptor.isFirstAttribute]) {
      attrs["className"] += " " + this.SEGMENT_ABSOLUTE_FIRST;
    }
    // If there are classes that were defined for each content segment, apply them here.
    if (this.props.settings.segmentDataDescriptor.contentAttribute !== "" && this.props.settings.segmentDataDescriptor.contentAttribute !== undefined) {
      attrs["className"] += " " + this.props.settings.segmentDataDescriptor.contentAttribute;
    }
    let segment;
    if (this.props.settings.segmentDataDescriptor.applyDirectlyToSegment) {
      final = this.extendedGenerateSegment(item, attrs, item[this.props.settings.segmentDataDescriptor.mainIdNodeAttribute]);
      segment = final;
    } else {
      // All these classes and data attributes are applied and the generate segment is put within the segment div.
      segment = (
        <div {...attrs} key={item[this.props.settings.segmentDataDescriptor.mainIdNodeAttribute]}>
          {final}
        </div>
      );
    }
    return segment;
  }

  /**
   * A utility function that will calculate wether navigating to the provided node will allow that not to scroll all the way to the top of the viewport.
   * Returns true is an append call is needed (there's not enough room under the node) and returns false if no additional work is needed.
   *
   * @param node The target node to compute from.
   */
  willNavigateRequireAppend(node: INode, additionalOffset?: number): boolean {
    // Get the segments.
    let segments = Array.from(this.props.fixedRef.current!.children);

    // Set segment
    let segment = null;

    // Go through segments to find the correct concrete Div.
    for (let i = 0; i < segments.length; i++) {
      if (
        Number(segments[i].getAttribute(this.props.settings.segmentDataDescriptor.mainIdDataAttribute)) ===
        node[this.props.settings.segmentDataDescriptor.mainIdNodeAttribute]
      ) {
        segment = segments[i] as HTMLDivElement;
        break;
      }
    }

    if (segment === null) {
      return false;
    }

    // Calculate if the top of the segment has enough space under it to surpass the height of the container. If not, require an append.
    let containerRect = this.props.scrollingRef.current!.getBoundingClientRect();
    let fixedRect = this.props.fixedRef.current!.getBoundingClientRect();
    return fixedRect.height - (segment.offsetTop + (additionalOffset ? additionalOffset : 0)) < containerRect.height;
  }

  getSegmentByMain(id: number) {
    let segment = this.props.fixedRef.current!.querySelector("div[" + this.props.settings.segmentDataDescriptor.mainIdDataAttribute + "='" + id + "']");
    if (!segment) {
      return null;
    }
    return segment as HTMLDivElement;
  }

  componentDidUpdate() {
    // When a render occurs on this pure component it is because the nodes have changes.
    if (this.props.segmentsInsertedCallback && this.props.currentNodes.length > 0) {
      const segments = this.props.fixedRef.current!.children;
      this.props.segmentsInsertedCallback(Array.from(segments) as HTMLDivElement[], this.props.currentNodes);
    }
  }

  render() {
    let elems: JSX.Element[] = [];
    let isLast = false;
    // For all the current nodes, create JSX segments. Assuming there are any.
    if (this.props.currentNodes && this.props.currentNodes.length > 0) {
      this.props.currentNodes.map((x) => {
        let el = this.generateSegment(x);
        if (!el) {
        } else if (Array.isArray(el)) {
          elems = elems.concat(el);
        } else {
          elems.push(el);
        }
        if (x[this.props.settings.segmentDataDescriptor.isLastAttribute]) {
          isLast = true;
        }
      });
    }
    return (
      <React.Fragment>
        <div className={classnames("cogniflow-segments", this.props.fixedDivClassName)} ref={this.props.fixedRef}>
          {elems}
        </div>
        {isLast && this.props.settings.applyFooterSpace && (
          <div style={{ height: this.props.scrollingRef.current!.getBoundingClientRect().height, width: "100%" }} className="contentview-header" />
        )}
      </React.Fragment>
    );
  }
}
/**
 * Settings for cogniflow.
 */
export interface ICogniflowOptionalSettings {
  /** The object representing all data attributes and classes to apply to a segment. */
  segmentDataDescriptor: // Object for segment descriptors
  {
    /** The attribute the INodes have representing their MainId. For example an index or a spine. */
    mainIdNodeAttribute: string;
    /** The attribute the concrete Segments will have as a data attribute representing the MainId. */
    mainIdDataAttribute: string;
    /** The attribute the INodes have representing their SecondaryId. Such as an annotationId or a headId. */
    secondaryIdNodeAttribute: string;
    /** The attribute the concrete Segments will have as a data attribute representing the SecondaryId. */
    secondaryIdDataAttribute: string;
    /** The attribute the INode representing the very start of the dataset is the first item. Compared internally as a boolean. Something such as IsFirst: boolean. Provided as "IsFirst". */
    isFirstAttribute: string;
    /** The attribute the INode representing the very end of the dataset is the first item. Compared internally as a boolean. Something such as IsLast : boolean. Provided as "IsLast". */
    isLastAttribute: string;
    /** CSS classes to add to the segments. Can have various uses. Each segment will have this/these class(es) appended to them. */
    contentAttribute: string;
    /** Should classes be directly applied to the segment or to the containing div? */
    applyDirectlyToSegment: boolean;
  };
  /** The size of the batch (number of segments) to query for during append/prepend operations. *As a rule of thumb, have 1 batch equate to ~the height of the tallest supported viewport* */
  batchSize: number;
  /** The maximum number of batches to allow within the entire frame. Default is 4. If batchSize is 20 and maxBatches is 4 there won't be any more than 80 segments in the control (unless initialize starts off with >maxBatches in which case it will not surpass the initial load.) */
  maxBatches?: number;
  /** Classes to apply to the container. E.g. BookView */
  containerClasses?: string;
  /** The class name(s) to be appended to the scrolling container. The overflow-y container that scrolls. */
  scrollingClasses?: string;
  /** The class name(s) to be appended to the fixed div. Last container holding the segments. */
  segmentContainerClasses?: string;
  /** Bool indicating if an additional footer space should be appended to the absolute end of content. Generally just used in the ContentView */
  applyFooterSpace?: boolean;
  /** This boolean flag tells the cogniflow handler that the segments in this container are keyed by incrementing index. It will make flow requests based on this. Append requests will be targeted to the next ID and Prepend to the previous > 0 index. */
  isIndexBased?: boolean;
}

/**
 * Enum representing the actions that are used for segment management.
 */
export enum Action {
  /** Action indicating the cogniflow needs to append content to the bottom of the control. */
  append,
  /** Action indicating the cogniflow needs to prepend content to the top of the control. */
  prepend,
  /** Action used when the control is loading the initial content. Isn't an append or a prepend. Causes both top and bottom loading. */
  insert,
  /** Special action used internally when the control needs to go through an append procedure before executing a navigation to a node contained in the control already. */
  navAppend,
}

/**
 * Request interface for cogniflow->calling component comms. Property is the batches.
 */
export interface IRequest {
  Batches: IBatch[];
}

/**
 * The batch information passed through a request.
 */
export interface IBatch {
  /** The action cogniflow is requesting for. See enum "Action". */
  Action: Action;
  /** The target MainId the action should be applied for. On append it will be the last loaded node MainId. Prepend the opposite. */
  TargetMainId: number;
  /** The anchor that will be scrolled to as part of a navigation. Usually used internally in combination with "navAppend" Action. */
  AnchorMainId: number;
  /** The size of the batch that is requested. Generally the same as the batchSize. Should be used by calling components to resolve append/prepend requests. */
  BatchSize: number;
  /** The nodes that should be filled by the calling component during flow requests and initialization. */
  Nodes: INode[];
  /** The filtering search query active for the batch being requested */
  Query?: string;
}
/**
 * Response interface for calling component comms->cogniflow. Property is the batches.
 */
export interface IResponse {
  Batches: IBatch[];
}
/**
 * The INode data definition. This is a string key object definition to allow any models to be put as nodes in CogniFlow. Could be Annotation/Favourites/ContentSegments/etc
 */
export interface INode {
  [key: string]: any;
}

/**
 * Response object for navigation callbacks.
 */
export interface ICogniflowNavigationResponse {
  /** MainId acting as a nav anchor. Must be set. */
  mainId: number;
  /** Additional px offset to add */
  offset?: number;
  /** Element to scroll to */
  element?: HTMLElement;
  /** Selector to find and scroll to. */
  selector?: string;
}
